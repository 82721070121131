














































































































































































































































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { FormFunctions } from '@/mixins/form-functions';
import { DBCollections, FirebaseFunctions, firebaseWrapper as fb } from '@/firebase-wrapper';
import firebase, { FirebaseError } from 'firebase/app';
import { IVenue, IDataRequest, DataRequestStatus, ICreateDataRequestResponse } from '@einfachgast/shared';
import { Venue } from '@/models/venues/venue';
import DataRequestProgressBar from '@/components/data-request-progress-bar.vue';
import { DataRequest } from '@/models/data-request';
import { plainToClass } from 'class-transformer';
import { httpsCallable } from 'firebase/functions';
import { DocumentData, QuerySnapshot, collection, getDocs, query, where } from 'firebase/firestore';

@Component({
  name: 'DataRequestForm',
  components: {
    DataRequestProgressBar,
  },
})
export default class DataRequestForm extends mixins(FormFunctions) {
  loading = false;
  venueLoading = false;
  dataRequestsLoading = false;
  dataRequest: IDataRequest = null;
  venues: IVenue[] = [];
  dataRequests: IDataRequest[] = [];

  get slimDataRequest () {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, createdAt, ...data } = this.dataRequest;
    return data;
  }

  async created () {
    await this.fetchVenueData();
    this.initEmptyRequestFormData();
    await this.fetchDataRequests();
  }

  getVenueLabelById (venueId: string) {
    const catchedVenue = this.venues.find((x) => x.id === venueId);
    if (!catchedVenue) {
      return 'Standort existiert nicht mehr';
    }
    return this.venues.find((x) => x.id === venueId).label;
  }

  initEmptyRequestFormData () {
    this.dataRequest = plainToClass(DataRequest, {} as IDataRequest);
    this.dataRequest.status = DataRequestStatus.WaitingForApproval;
    if (this.venues.length === 1) {
      this.dataRequest.venueId = this.venues[0].id;
      this.dataRequest.ownerId = this.venues[0].ownerId;
    }
  }

  setOwnerId (venueId: string) {
    const venue = this.venues.find((x) => x.id === venueId);
    if (venue) {
      this.dataRequest.ownerId = venue.ownerId;
    }
  }

  async send () {
    if (!(await this.validate(this.dataRequest))) {
      return;
    }
    this.loading = true;
    try {
      const createDataRequest = httpsCallable<Partial<IDataRequest>, ICreateDataRequestResponse>(
        fb.functions,
        FirebaseFunctions.CreateDataRequest,
      );
      const result: { data: ICreateDataRequestResponse } = await createDataRequest(this.slimDataRequest);
      if (result.data.success) {
        this.$buefy.dialog.alert({
          title: 'Ihre Anfrage wurde übermittelt',
          message:
            `<p>Wir setzen uns direkt mit dem Gesundheitsamt in Verbindung.</p><br/><p>Bitte lassen Sie Ihrem Ansprechpartner beim Gesundheitsamt noch den folgenden PIN zukommen: <b>${result.data.secret}</b>.</p><br/><p>Dieser wird benötigt um die Besucherdaten abzurufen.</p>`,
          type: 'is-success',
          hasIcon: true,
          icon: 'check-circle',
          ariaRole: 'alertdialog',
          ariaModal: true,
        });
      } else {
        this.$buefy.dialog.alert({
          title: 'Ihre Anfrage wurde nicht übermittelt',
          message:
            `<p>Ihre Anfrage wurde aus folgendem Grund nicht an das Gesundheitsamt übermittelt:</p><br/><p><b>${this.$i18n.t(result.data.text)}</b>.</p>`,
          type: 'is-warning',
          hasIcon: true,
          icon: 'alert-circle',
          ariaRole: 'alertdialog',
          ariaModal: true,
        });
      }
      await this.fetchDataRequests();
      // empty form
      this.initEmptyRequestFormData();
    } catch (e) {
      this.$buefy.toast.open({
        duration: 5000,
        message: (e as FirebaseError).message,
        position: 'is-top',
        type: 'is-danger',
      });
    }
    this.loading = false;
  }

  async fetchVenueData () {
    if (!this.$auth.venueOwner?.userId) {
      return;
    }
    this.venueLoading = true;
    const q = query(collection(fb.db, DBCollections.Venues), where('ownerId', '==', this.$auth.venueOwner.userId), where('isUserVisit', '==', false));
    const querySnapshot = await getDocs(q);
    this.venues = [];
    querySnapshot.forEach((doc: DocumentData) => {
      const venueData = doc.data() as IVenue;
      venueData.id = doc.id;
      this.venues.push(plainToClass(Venue, venueData));
    });
    this.venueLoading = false;
  }

  async fetchDataRequests () {
    if (!this.$auth.venueOwner?.userId) {
      return;
    }
    this.dataRequestsLoading = true;
    try {
      const q = query(collection(fb.db, DBCollections.DataRequests), where('ownerId', '==', this.$auth.venueOwner.userId));
      const querySnapshot = await getDocs(q);
      this.dataRequests = [];
      querySnapshot.forEach((doc: DocumentData) => {
        const dataRequest = doc.data() as IDataRequest;
        dataRequest.id = doc.id;
        this.dataRequests.push(plainToClass(DataRequest, dataRequest));
      });
    } catch (e) {
      this.dataRequestsLoading = false;
    }
    this.dataRequestsLoading = false;
  }

  async copyShaToClipboard (secret: string) {
    if (!navigator.clipboard) {
      this.$buefy.toast.open({
        message: 'Your browser does not support copy to clipboard feature!',
        type: 'is-danger',
      });
      return;
    }
    await navigator.clipboard.writeText(secret);
    this.$buefy.toast.open('Copied to clipboard');
  }
}
