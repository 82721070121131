



















import Vue from 'vue';
import Component from 'vue-class-component';
import EinfachGastLogo from '@/components/einfach-gast-logo.vue';
import Sidebar from '@/components/sidebar.vue';
import DeletedAccountHint from '@/components/hints/deleted-account-hint.vue';
import DeveloperHint from '@/components/hints/developer-hint.vue';
import RemainingVisitsHint from '@/components/hints/remaining-visits-hint.vue';
import PlanExpirationHint from '@/components/hints/plan-expiration-hint.vue';
import SetupHint from '@/components/hints/setup-hint.vue';
import TrialVersionHint from '@/components/hints/trial-version-hint.vue';
import { PackageScope } from './models/stripe/package-scope';
import { DBCollections, firebaseWrapper as fb } from '@/firebase-wrapper';
import firebase from 'firebase/app';
import { IVenueOwner } from '@einfachgast/shared';
import { VenueOwner } from './models/venue-owner';
import { plainToClass } from 'class-transformer';
import { IPackageScope } from './interfaces';
import { DocumentReference, collection, doc, getDoc, onSnapshot } from 'firebase/firestore';
import { User } from 'firebase/auth';

@Component({
  name: 'App',
  components: {
    DeveloperHint,
    PlanExpirationHint,
    DeletedAccountHint,
    EinfachGastLogo,
    RemainingVisitsHint,
    SetupHint,
    Sidebar,
    TrialVersionHint,
  },
})
export default class App extends Vue {
  loading = true;
  licenseExpiry: Date = null;
  unsubscribeVenueOwnerListener: () => void = null;
  unsubscribeSubscriptionsListener: () => void = null;

  get isHideSidebar () {
    return this.$route.meta?.hideSidebar;
  }

  async mounted () {
    fb.auth.onAuthStateChanged((user: User) => {
      if (!user) {
        // on logout
        this.unsubscribeSnapshotListener();
        this.$auth.unsubscribeVenueSnapshot();
      }
    });
    // Muss hier so gemacht werden, da die App.vue gerendert wird, obwohl initApp (und somit initVenues) zu diesem Zeitpunkt noch nicht durch ist.
    // – OBWOHL (laut main.ts) ERST gerendert wird, wenn initApp fertig ist.
    // Dadruch sind am Anfang die Venues immer kurz leer und dadurch würde sonst immer der SetupHint für kurze Zeit angezeigt werden.
    if (this.$auth.venueOwner && this.$auth.isRegistrationComplete) {
      await this.initPackageScope();
    }
  }

  async initPackageScope () {
    if (!this.$auth.venueOwner?.userId || this.$auth.permissions.isChildUser) {
      return;
    }

    // when venueOwner or subscription of venueOwner change => update the packageScope
    const packageScopeResult = await this.$stripe.getPackageScope();
    const docRef = doc(collection(fb.db, DBCollections.VenueOwners), this.$auth.venueOwner.userId);

    this.unsubscribeVenueOwnerListener = onSnapshot(docRef, (snap) => {
      this.$stripe.setPackageScope(plainToClass<PackageScope, IPackageScope>(PackageScope, packageScopeResult));
      this.$auth.setVenueOwner(plainToClass<VenueOwner, IVenueOwner>(VenueOwner, snap.data() as IVenueOwner));
    });

    this.unsubscribeSubscriptionsListener = onSnapshot(collection(fb.db, `${DBCollections.VenueOwners}/${this.$auth.venueOwner.userId}/${DBCollections.Subscriptions}`), () => {
      this.$stripe.setPackageScope(plainToClass<PackageScope, IPackageScope>(PackageScope, packageScopeResult));
    });
  }

  unsubscribeSnapshotListener () {
    if (this.unsubscribeVenueOwnerListener && this.unsubscribeSubscriptionsListener) {
      this.unsubscribeVenueOwnerListener();
      this.unsubscribeSubscriptionsListener();
      this.unsubscribeVenueOwnerListener = null;
      this.unsubscribeSubscriptionsListener = null;
    }
  }

  get showNav () {
    // No logged in User
    if (!this.$auth.user || !this.$auth.user.emailVerified) {
      return false;
    }

    return true;
  }
}
