import { IVenue } from '@einfachgast/shared';

export class DashboardChartHelper {
  /**
   * Get average count per day from first visit to now
   * @param venue
   * @returns number
   */
  public getVisitorAvgPerDay (venue: IVenue, startDate: Date) {
    const flattedHistory = this.flattenObj(venue.history.data.visitors.history);
    if (!Object.keys(flattedHistory)[0]) {
      return 0;
    }

    const currentStartDate = this.dateKeyToDate(Object.keys(flattedHistory)[0]);
    const differenceInTime = currentStartDate.getTime() - startDate.getTime();
    const differenceInDays = (differenceInTime / (1000 * 3600 * 24) * -1) + 1; // +1 because this day counts too
    const visitorsCount = this.countHistoryFromTo(flattedHistory, currentStartDate, startDate);
    if (differenceInDays === 0) {
      return 0;
    }
    return Math.round((visitorsCount / differenceInDays) * 100) / 100;
  }

  /**
   * Compare visitorsCount in days-period (ex. 7 Days) against the previous days-period
   * @param venue
   * @param days
   * @returns
   */
  public getAverageLastXDaysWihtPercent (venue: IVenue, days: number, startDate: Date) {
    const firstDate = new Date(startDate);
    firstDate.setDate(firstDate.getDate() - (days - 1));

    const flattedHistory = this.flattenObj(venue.history.data.visitors.history);
    if (Object.keys(flattedHistory).length === 0) {
      return {
        avg: 0,
        percentAgainstPrevPeriod: 0,
      };
    }

    const firstPeriodCount = this.countHistoryFromTo(flattedHistory, firstDate, startDate);

    const prevPeriodEnd = new Date(startDate.getTime() - (days * 24 * 60 * 60 * 1000));
    const prevPeriodStart = new Date(startDate.getTime() - (days - 1 + days) * 24 * 60 * 60 * 1000);
    const prevPeriodCount = this.countHistoryFromTo(flattedHistory, prevPeriodStart, prevPeriodEnd);

    if (firstPeriodCount === 0 && prevPeriodCount === 0) {
      // cannot be divided by zero; all perdios have zero visitors
      return {
        avg: firstPeriodCount,
        percentAgainstPrevPeriod: 0,
      };
    } else if (prevPeriodCount === 0) {
      // cannot be divided by zero
      return {
        avg: firstPeriodCount,
        percentAgainstPrevPeriod: 100,
      };
    }
    const firstPeriodCountAvg = firstPeriodCount / days;
    const prevPeriodCountAvg = prevPeriodCount / days;

    const percentAgainstPrevPeriod = Math.round(((100 / prevPeriodCountAvg) * firstPeriodCountAvg) - 100);
    return {
      avg: firstPeriodCount,
      percentAgainstPrevPeriod: percentAgainstPrevPeriod,
    };
  }

  /**
   * Sum visitors in specific dateRange (start, end)
   * @param venue
   * @param start
   * @param end
   * @returns number
   */
  public getSumFilteredVisitorByStartAndEnd (venue: IVenue, start: Date, end: Date, groupBy: 'day' | 'month') {
    const visitorsCountInDate = this.getFilteredVisitorByStartAndEndGrouped(venue, start, end, groupBy);
    return Object.values(visitorsCountInDate).reduce((a, b) => a + b, 0);
  }

  /**
   * Get history count grouped by day | month
   * Iterate from start to end. Either increment one day or one month in every iterate-step depends on groupdBy
   * @param venue
   * @param start
   * @param end
   * @param groupBy
   * @returns
   */
  public getFilteredVisitorByStartAndEndGrouped (venue: IVenue, start: Date, end: Date, groupBy: 'day' | 'month') {
    const filteredHistoryByDate = this.flattenObj(venue.history.data.visitors.history);
    const visitorsCountInDate: {[key: string]: number} = {};
    let currentDate = new Date(start);
    while (currentDate <= end) {
      if (groupBy === 'day') {
        this.sumVisitorsOnDate(visitorsCountInDate, filteredHistoryByDate, currentDate, 'day');
        currentDate.setDate(currentDate.getDate() + 1);
      } else if (groupBy === 'month') {
        this.sumVisitorsOnDate(visitorsCountInDate, filteredHistoryByDate, currentDate, 'month');
        currentDate.setMonth(currentDate.getMonth() + 1);
      }
      currentDate = new Date(currentDate); // set here because of lint error: currentDate not modified in while-loop
    }
    return visitorsCountInDate;
  }

  /**
   * Count historyData on Date and set it in the visitorSumOnDate-referenz-object
   * @param visitorSumOnDate
   * @param historyData
   * @param date
   * @param groupBy
   */
  private sumVisitorsOnDate (visitorSumOnDate: {[key: string]: number}, historyData: {[key: string]: number}, date: Date, groupBy: 'day' | 'month') {
    if (groupBy === 'day') {
      const key = `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()}`;
      const displayDateKey = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
      visitorSumOnDate[displayDateKey] || (visitorSumOnDate[displayDateKey] = 0);
      visitorSumOnDate[displayDateKey] += historyData[key] || 0;
    } else if (groupBy === 'month') {
      const key = `${date.getFullYear()}.${date.getMonth() + 1}`;
      const displayDateKey = `${date.getMonth() + 1}.${date.getFullYear()}`;
      const groupedHistoryByMonth = this.groupHistoryByMonth(historyData);
      visitorSumOnDate[displayDateKey] || (visitorSumOnDate[displayDateKey] = 0);
      visitorSumOnDate[displayDateKey] += groupedHistoryByMonth[key] || 0;
    }
  }

  /**
   * count/sum visitors in specific period (start - end)
   * @param history
   * @param start
   * @param end
   * @returns visitorsVound
   */
  private countHistoryFromTo (history: {[key: string]: number}, start: Date, end: Date) {
    let visitorsCount = 0;
    let startDateCopy = new Date(start);
    while (startDateCopy <= end) {
      const key = `${startDateCopy.getFullYear()}.${startDateCopy.getMonth() + 1}.${startDateCopy.getDate()}`;
      if (history[key]) {
        visitorsCount += history[key];
      }
      startDateCopy.setDate(startDateCopy.getDate() + 1);
      startDateCopy = new Date(startDateCopy); // set here because of lint error: currentStartDate not modified in while-loop
    }
    return visitorsCount;
  }

  /**
   * Group the flattedHistory object by month
   * groupby month = {'2021.3': 120}
   * @param history
   * @param groupBy
   * @returns
   */
  private groupHistoryByMonth (history: {[key: string]: number}) {
    const filteredHistoryByDate: {[key: string]: number} = {};
    Object.keys(history).map((key) => {
      const d = this.dateKeyToDate(key);
      const monthKey = `${d.getFullYear()}.${d.getMonth() + 1}`;
      filteredHistoryByDate[monthKey] || (filteredHistoryByDate[monthKey] = 0);
      filteredHistoryByDate[monthKey] += history[key];
    });
    return filteredHistoryByDate;
  }

  private dateKeyToDate (dateKey: string) {
    const dateKeySplitted = dateKey.split('.');
    return new Date(parseInt(dateKeySplitted[0]), parseInt(dateKeySplitted[1]) - 1, parseInt(dateKeySplitted[2]));
  }

  /**
   *
   * @param obj Flats the nested history object to {
   *  '2021.3.4': 99
   * }
   * @param parent
   * @param res
   * @returns flatted history object
   */
  private flattenObj (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    obj: { [key: string]: any },
    parent: string = undefined,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    res: { [key: string]: any } = {},
  ) {
    if (!obj) {
      return {};
    }
    for (const key in obj) {
      const propName = parent ? parent + '.' + key : key;
      if (typeof obj[key] === 'object') {
        this.flattenObj(obj[key], propName, res);
      } else {
        res[propName] = obj[key];
      }
    }
    return res;
  }
}
