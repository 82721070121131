import { registerDecorator, ValidationOptions, ValidationArguments } from 'class-validator';

export function DateIsAfter (property: string, validationOptions?: ValidationOptions) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (object: Record<string, any>, propertyName: string) {
    registerDecorator({
      name: 'dateIsAfter',
      target: object.constructor,
      propertyName: propertyName,
      constraints: [property],
      options: validationOptions,
      validator: {
        validate (value: Date, args: ValidationArguments) {
          const [otherPropertyName] = args.constraints;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const otherValue = (args.object as any)[otherPropertyName];
          if (!value || !otherValue) {
            return true;
          }
          return value > otherValue;
        },
      },
    });
  };
}
