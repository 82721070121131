































































































import { Component, Prop } from 'vue-property-decorator';
import { IArea, IVenue, CheckInModes } from '@einfachgast/shared';
import { FormFunctions } from '@/mixins/form-functions';
import { mixins } from 'vue-class-component';
import { QrCodePrintTypes } from '@/models/qr-code-print-types';
import { IQrCode } from '@/interfaces/i-qr-code';

@Component({
  name: 'VenuesAreaItem',
})
export default class VenuesAreaItem extends mixins(FormFunctions) {
  loading = false;
  hasChanged = false;

  qrCode: IQrCode = null;

  @Prop()
  qrCodes: IQrCode[];

  @Prop()
  index: number;

  @Prop()
  area: IArea;

  @Prop()
  venue: IVenue;

  // Create the "fromVenue"-Error function because venue will complete validate (than the error-object have a other strcuture) or validate only the area-model
  getFieldColorFromVenue (fieldName: string): string {
    const fieldErrors = this.getFieldErrorsAsStringFromVenue(fieldName);
    if (fieldErrors && fieldErrors) {
      return 'is-danger';
    }
    return '';
  }

  getFieldErrorsAsStringFromVenue (fieldName: string) {
    const fieldError = this.allErrors.find(error => error.property === 'areas');
    if (!fieldError) {
      return;
    }

    const childErrors = fieldError.children.filter(a => a.value === this.area);
    if (!childErrors.length) {
      return;
    }
    const fieldErrors = childErrors[0].children.filter(error => error.property === fieldName);
    if (!fieldErrors.length) {
      return;
    }
    // Make sure error messages always have dot at the end
    return `${fieldErrors.map(e => Object.values(e.constraints || {}).map(x => x.replace(/[,.!]+$/, '')).join('. '))}.`;
  }

  get isAreaDeactivated () {
    return this.area.isDeactivated;
  }

  get venueCheckInModes () {
    return CheckInModes;
  }

  mounted () {
    this.loading = true;
    try {
      this.qrCode = this.qrCodes.find(x => x.venueId === this.venue.id && x.areaId === this.area.id);
      this.loading = false;
    } catch (e) {
      console.error(e);
      this.loading = false;
    }
  }

  changeLimitationSwitch () {
    if (!this.area.isLimitActive) {
      this.area.limit = null;
    }
  }

  nameChanged () {
    this.clearErrors('name');
    this.hasChanged = true;
  }

  deleteArea () {
    this.$emit('deleteArea', this.area.id);
  }

  async createQrCodeForPrint () {
    try {
      if (this.isAreaDeactivated || !await this.validate(this.area, ['areas'])) {
        return;
      }
      await this.createQrCode();
      this.$emit('createQrCode', QrCodePrintTypes.Single, [this.area]);
    } catch {
      throw new Error('Error on QR-Code printing');
    }
  }

  async createQrCodeForSvg () {
    try {
      if (this.isAreaDeactivated || !await this.validate(this.area)) {
        return;
      }
      await this.createQrCode();
      this.$emit('createSvgQrCode', this.area);
    } catch {
      throw new Error('Error on QR-Code download');
    }
  }

  async createQrCode () {
    try {
      this.loading = true;
      if (this.hasChanged) {
        // on click qrCodePrint save the area (only when changed)
        await this.$venues.updateVenue(this.venue);
        this.$buefy.toast.open({
          duration: 5000,
          message: `Der Standortbereich <b>${this.area.name}</b> wurde gespeichert.`,
          position: 'is-top',
          type: 'is-success',
        });
      }
      this.$emit('areaSaved', this.area);
      this.hasChanged = false;
      this.loading = false;
    } catch (e) {
      this.loading = false;
      this.$buefy.toast.open({
        duration: 5000,
        message: e.message,
        position: 'is-top',
        type: 'is-danger',
      });
    }
  }
}
