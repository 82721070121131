export const AvailableLanguages = [
  {
    key: 'de',
    label: 'Deutsch',
  },
  {
    key: 'en',
    label: 'English',
  },
  /* comes later {
    key: 'es',
    label: 'Spanisch'
  },
  {
    key: 'fr',
    label: 'Französisch'
  },
  {
    key: 'ru',
    label: 'Russisch'
  },
  {
    key: 'tr',
    label: 'Türkisch'
  } */
] as const;
