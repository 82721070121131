























import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { RouteNames, routes } from '@/router/index';
import MenuItem from '@/components/menu/menu-item.vue';
import { RouteConfig } from 'vue-router';
import { extractMenuItems } from '@/helpers/menu-helper';

@Component({
  name: 'Menu',
  components: {
    MenuItem,
  },
})
export default class Menu extends Vue {
  @Prop()
  plain!: boolean;

  get routes () {
    return extractMenuItems(routes);
  }

  isActive (route: RouteConfig) {
    return this.$route.path === route.path;
  }

  async logout () {
    await this.$auth.logout();
    void this.$router.push({ name: RouteNames.Login });
  }
}
