export enum RouteNames {
  Dashboard = 'Dashboard',
  MyData = 'MyData',
  Venues = 'Venues',
  Registration = 'Registration',
  Login = 'Login',
  PasswordReset = 'PasswordReset',
  Verification = 'Verification',
  ChangeMail = 'ChangeMail',
  DataRequestForm = 'DataRequestForm',
  Abonnement = 'Abonnement',
  NewVenue = 'newVenue',
  NotFound = 'notFound',
  EditVenue = 'editVenue',
  LiveView = 'liveView',
  SuperAdmin = 'superAdmin',
  VisitorDataDownload = 'visitorDataDownload',
  StripeCustomerPortal = 'stripeCustomerPortal',
  Settings = 'settings',
  VenueEditTabAddress = 'venueEditTabAddress',
  VenueEditTabAdditionalFeature = 'venueEditTabAdditionalFeatures',
  VenueEditTabQrCode = 'venueEditTabQrCode',
  VenueEditTabOnlineForm = 'venueEditTabOnlineForm',
  VenueEditTabOptions = 'venueEditTabOptions',
  VenueEditTabAreas = 'venueEditTabAreas',
  VenueEditTabSafetyFeature = 'venueEditTabSafetyFeature',
  VenueEditTabDataSecurity = 'venueEditTabDataSecurity',
  VenueCreateTabAddress = 'venueCreateTabAddress',
  VenueCreateTabDataSecurity = 'venueCreateTabDataSecurity',
  VenueCreateTabStart = 'venueCreateTabStart',
  VenueEditTabDesign = 'VenueEditTabDesign',
  Management = 'Management',
  ManagementMain = 'ManagementMain',
  ChildUsers = 'ChildUsers',
  SignInComplete = 'SignInComplete',
  venueEditTabSignaturePadFeature = 'venueEditTabSignaturePadFeature',
}