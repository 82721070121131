
import Vue from 'vue';
import Vuex from 'vuex';
import { IAuthStore } from './interfaces/i-auth-store';
import { IStripeStore } from './interfaces/i-stripe-store';
import { IVenueModule } from './interfaces/i-venue-module';
import { AuthModule } from './modules/auth';
import { StripeModule } from './modules/stripe';
import { VenueModule } from './modules/venue-module';

Vue.use(Vuex);

export interface IStore {
  auth: IAuthStore;
  stripe: IStripeStore;
  venues: IVenueModule;
}

// store.vuex.ts
const store = new Vuex.Store<IStore>({
  modules: {
    auth: AuthModule,
    stripe: StripeModule,
    venues: VenueModule,
  },
});

export { store };
