



















































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IVenue } from '@einfachgast/shared';
import { IQrCode } from '@/interfaces/i-qr-code';
import ClickOutside from 'vue-click-outside';
import { QrCode } from '@/models/qr-code';
import { createQrCodeImage } from '@/helpers/qr-code-creation-helper';
import VenueInputTooltipWrapper from '@/components/venues/venue-input-tooltip-wrapper.vue';
import { isFileSizeExceeded, MAX_FILE_SIZE_IN_MB } from '@/helpers/file-helper';
import ColorPickerFieldWrapper from '@/components/venues/venues-design/color-picker-field-wrapper.vue';
import { QrCodePrintTypes } from '@/models/qr-code-print-types';
import TranslateableTextarea from '@/components/common/translateable-textarea.vue';
import { plainToClass } from 'class-transformer';

@Component({
  name: 'QrCodeTab',
  components: {
    VenueInputTooltipWrapper,
    ColorPickerFieldWrapper,
    TranslateableTextarea,
  },
  directives: {
    ClickOutside,
  },
})
export default class QrCodeTab extends Vue {
  qrCode: IQrCode = null;
  dropFileQrCode: File = null;

  @Prop()
  venue: IVenue;

  get TooltipText () {
    return 'In Ihrem aktuellen Plan sind keine Design-Anpassungen enthalten. Vorhandene Einstellungen und Logos werden wiederhergestellt, sobald Sie in einen entsprechenden Plan wechseln.';
  }

  get hasDesignPackage () {
    return this.$stripe.packageScope.hasDesignPackage;
  }

  get logoFilenameQrCode () {
    if (!this.dropFileQrCode) {
      return this.venue.qrCodelogoUrl?.substring(this.venue.qrCodelogoUrl?.lastIndexOf('/') + 1);
    }
    return this.dropFileQrCode.name;
  }

  get presetColors () {
    return [
      '#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321',
      '#417505', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2',
      '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF',
    ];
  }

  mounted () {
    this.qrCode = plainToClass<QrCode, IQrCode>(QrCode, { id: 'Bereichsname', venueId: '', areaId: '', isAdminCode: false });
    this.qrCode.qrCodeImage = createQrCodeImage(this.qrCode, this.venue, QrCodePrintTypes.Single, this.$stripe.packageScope.hasDesignPackage);
    this.$emit('onQrCodeCreated', this.qrCode);
    this.$watch(() => [this.venue.fontColor, this.venue.backgroundColor], () => {
      this.qrCode.qrCodeImage = createQrCodeImage(this.qrCode, this.venue, QrCodePrintTypes.Single, this.$stripe.packageScope.hasDesignPackage);
      this.$emit('onQrCodeCreated', this.qrCode);
    });
  }

  async onDropFileQrCode (droppedFileQrCode: File) {
    if (isFileSizeExceeded(droppedFileQrCode)) {
      this.$buefy.toast.open({
        duration: 5000,
        message: `Maximale Dateigröße von ${MAX_FILE_SIZE_IN_MB}MB überschritten.`,
        position: 'is-top',
        type: 'is-danger',
      });
      this.dropFileQrCode = null;
    } else {
      this.$emit('onDropFileQrCode', droppedFileQrCode);
      try {
        await this.$venues.uploadImage({
          origVenue: this.venue,
          venue: this.venue,
          isQrCodeImage: true,
          image: droppedFileQrCode,
          oldLogoUrl: '',
        });
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Fehler beim Dateiupload.',
          position: 'is-top',
          type: 'is-danger',
        });
      }

      this.venue.qrCodelogoUrl = droppedFileQrCode.name;
    }
  }

  removeFile () {
    this.dropFileQrCode = null;
    this.venue.qrCodelogoUrl = '';
    this.$emit('onDropFileQrCode', null);
  }
}
