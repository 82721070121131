import { IArea } from '@einfachgast/shared';
import { IsInt, IsNotEmpty, ValidateIf } from 'class-validator';
import { Expose, Transform } from 'class-transformer';

export class Area implements IArea {
  @Expose()
  public id: string;

  @Expose()
  @Transform(value => value || '', { toClassOnly: true })
  @IsNotEmpty({ message: 'Bitte geben Sie einen Namen an', groups: ['areas'], context: { group: 'areas' } })
  public name: string;

  @Expose()
  @Transform(value => value || '', { toClassOnly: true })
  public label: string;

  @Expose()
  @Transform(value => value || false, { toClassOnly: true })
  public isDeactivated: boolean;

  @Expose()
  @Transform(value => value || false, { toClassOnly: true })
  public isLimitActive: boolean;

  @Expose()
  @Transform(value => value || null, { toClassOnly: true })
  @IsInt()
  @ValidateIf(o => o.isLimitActive, { groups: ['areas'], context: { group: 'areas' } })
  @IsNotEmpty({ message: 'Bitte geben Sie die maximale gleichzeitige Anzahl an Gästen am Bereich an.', groups: ['options'], context: { group: 'areas' } })
  public limit: number;
}
