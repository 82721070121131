































import Component from 'vue-class-component';
import Vue from 'vue';
import EinfachGastLogo from '@/components/einfach-gast-logo.vue';
import Menu from '@/components/menu.vue';
import SmallMenu from '@/components/small-menu.vue';
import { RouteRecord } from 'vue-router';

@Component({
  name: 'SetupHint',
  components: {
    EinfachGastLogo,
    Menu,
    SmallMenu,
  },
})
export default class SetupHint extends Vue {
  get canShowSidebar () {
    return this.$auth.isRegistrationComplete
      && !this.$route.meta?.hideSidebar;
  }

  async logout () {
    await this.$auth.logout();
  }

  isActive (route: RouteRecord) {
    return this.$route.path === route.path;
  }
}
