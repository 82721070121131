import { IQrCode } from '@/interfaces/i-qr-code';

export class QrCode implements IQrCode {
  public id: string;
  public venueId: string;
  public areaId: string;
  public qrCodeImage = '';
  public isAdminCode: boolean;
  public env?: string;

  constructor (env?: string) {
    this.env = env;
  }

  public get Environment () {
    // Fallback to VUE_APP_ENVIRONMET and then to NODE_ENV when this.env has not been specified
    return (this.env || process.env?.VUE_APP_ENVIRONMENT || process.env?.NODE_ENV).toLowerCase();
  }

  /**
   * The "ShortUrl" is displayed in plaintext for people who cannot
   * scan the QR code
   */
  public get shortUrl () {
    if (!this.id) {
      return '';
    }

    return `https://${this.subdomain}gast.im/qr/${this.id}`;
  }

  /**
   * This url will be encoded inside the QR code
   */
  public get url () {
    if (!this.id || this.id === '') {
      return '';
    }

    return `https://app.${this.subdomain}einfachgast.de/qr/${this.id}`;
  }

  private get subdomain () {
    switch (this.Environment) {
      case 'test':
      case 'testing':
      case 'development':
        return 'test.';

      case 'stag':
      case 'stage':
      case 'staging':
        return 'staging.';
    }

    return '';
  }
}
