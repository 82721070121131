import { Transform, Expose } from 'class-transformer';
import { ICovidOptions } from '@/interfaces';
export class CovidOptions implements ICovidOptions {
  @Expose()
  @Transform(value => value !== undefined && value !== null ? value : false, { toClassOnly: true })
  public showNoteText: boolean;

  @Expose()
  @Transform(value => value !== undefined && value !== null ? value : false, { toClassOnly: true })
  public showNegativeCheckbox: boolean;
  
}
