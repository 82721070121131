


























import { RouteNames } from '@/router';
import { CheckInModes } from '@einfachgast/shared';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'LiveVisitorEmptyStateMessage',
})
export default class LiveVisitorEmptyStateMessage extends Vue {

  get newVenueRoute () {
    return {
      name: RouteNames.NewVenue,
    };
  }

  get managementRoute () {
    return {
      name: RouteNames.ManagementMain,
    };
  }

  get sortedVenues () {
    return this.$auth.ownedVenues
      .filter(x => x.isDeactivated === false && x.isDeleted === false)
      .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);
  }

  get checkInCheckOutVenues () {
    return this.sortedVenues.filter(x => x.checkinMode === CheckInModes.CheckInCheckOut);
  }

  get hasVenues () {
    return this.sortedVenues.length > 0;
  }

  get hasCheckInCheckOutVenues () {
    return this.checkInCheckOutVenues.length > 0;
  }
}
