







































import { Component, Prop } from 'vue-property-decorator';
import LoginHeaderToggle from '@/components/login/login-header-toggle.vue';
import LoggedOutWrapper from '@/components/logged-out-wrapper.vue';
import { mixins } from 'vue-class-component';
import { FormFunctions } from '@/mixins/form-functions';
import { isEmail } from 'class-validator';
import { RouteNames } from '@/router';
import firebase from 'firebase/app';

@Component({
  name: 'PasswordReset',
  components: {
    LoggedOutWrapper,
    LoginHeaderToggle,
  },
})
export default class PasswordReset extends mixins(FormFunctions) {
  loading = false;
  success = false;
  email: string = null;

  @Prop()
  redirectUrl: string;

  @Prop()
  verified: string

  async beforeCreate () {
    if (this.$auth.user) {
      await this.$router.push({ path: '/' });
      return;
    }
    this.loading = false;
  }

  async cancel () {
    await this.$router.push({ name: RouteNames.Login });
  }

  async resetPassword () {
    this.errors = [];
    if (!this.email || !isEmail(this.email)) {
      this.addError('Bitte geben sie eine Gültige E-Mail-Adresse an', 'email');
      return;
    }

    try {
      await this.$auth.sendResetPasswordMail(this.email);
      this.success = true;
      this.loading = false;
    } catch (e) {
      this.$buefy.toast.open({
        duration: 5000,
        message: this.translateErrors(e),
        position: 'is-top',
        type: 'is-danger',
      });
    }
  }

  translateErrors (e: firebase.FirebaseError) {
    if (e.message === 'INVALID_PASSWORD') {
      return 'Ungültiges Passwort';
    }
    if (e.message === 'Only unverified email addresses may be changed') {
      return 'Diese E-Mail-Adresse ist bereits verifiziert und kann nicht mehr geändert werden.';
    }
    return e.message;
  }
}
