











































import { Component } from 'vue-property-decorator';
import LoggedOutWrapper from '@/components/logged-out-wrapper.vue';
import { FormFunctions } from '@/mixins/form-functions';
import { mixins } from 'vue-class-component';
import { UpdateUnverifiedEmailRequest } from '../models/update-unverified-email-request';
import { IUpdateUnverifiedEmailRequest } from '@/interfaces/i-update-unverified-email-request';
import { RouteNames } from '@/router';

@Component({
  name: 'ChangeMail',
  components: {
    LoggedOutWrapper,
  },
})
export default class ChangeMail extends mixins(FormFunctions) {
  updateEmailRequest: IUpdateUnverifiedEmailRequest = new UpdateUnverifiedEmailRequest();
  loading = false;

  get currentUser () {
    return this.$auth.user;
  }

  async beforeCreate () {
    if (this.$auth.user && this.$auth.user.emailVerified) {
      await this.$router.push({ name: RouteNames.Dashboard });
    }
  }

  async submit () {
    if (!await this.validate(this.updateEmailRequest)) {
      return;
    }

    this.loading = true;
    try {
      await this.$auth.updateUnverifiedUsersMailaddress(this.updateEmailRequest);
      await this.$router.replace({ name: RouteNames.Verification });
    } catch (e) {
      this.$buefy.toast.open({
        duration: 5000,
        message: e.message,
        position: 'is-top',
        type: 'is-danger',
      });
    }
    this.loading = false;
  }

  async cancel () {
    await this.$router.push({ name: RouteNames.Verification });
  }
}
