import { Container } from 'inversify';
import { IFeatureStatic, IFeature } from '@/interfaces';
import { VueRouter } from 'vue-router/types/router';
import { AuthModule } from '@/store/modules/auth';
import VueI18n from 'vue-i18n';

export class DiContainerFeatureFactory {
  private features: Array<IFeature> = [];
  public container = new Container();

  public constructor (features: IFeatureStatic[],
    private router: VueRouter,
    private auth: AuthModule,
    private i18n: VueI18n,
  ) {
    for (const feature of features) {
      this.addFeature(feature);
    }
  }

  public addFeature (FeatureConstructor: IFeatureStatic) {
    if (FeatureConstructor.canUseFeature(this.auth)) {
      this.features.push(new FeatureConstructor(this.router, this.i18n));
    }
    return this;
  }

  public start () {
    this.features
      .filter((featureProto) => typeof featureProto.dependencyRegistrations === 'function')
      .forEach((featureProto) => featureProto.dependencyRegistrations(this.container));
  }
}
