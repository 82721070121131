import { AreaQrCodeCssHelper } from '@/helpers/area-qr-code-css-helper';
import { createQrCodeForArea, createQrCodeImage } from '@/helpers/qr-code-creation-helper';
import { getVenueLogoUrl } from '@/helpers/venue-logo-helper';
import { IArea, IVenue } from '@einfachgast/shared';
import { QrCodePrintTypes } from './qr-code-print-types';
import { PackageScope } from './stripe/package-scope';
import VenueQrAdminPrint from '@/components/venues/venue-qr-admin-print.vue';
import VenueQrPrint from '@/components/venues/venue-qr-print.vue';
import { IQrCode } from '@/interfaces/i-qr-code';
import Vue from 'vue';

export class QrCodePrintService {
  public loading = false;

  private _venue: IVenue = null;
  private _packageScope: PackageScope = null;
  private _qrCodes: IQrCode[] = null;
  private _vue: Vue = null;

  public constructor (venue: IVenue, packageScope: PackageScope, qrCodes: IQrCode[], vue: Vue) {
    this._venue = venue;
    this._packageScope = packageScope;
    this._qrCodes = qrCodes;
    this._vue = vue;
  }

  get qrCodes () {
    return this._qrCodes;
  }

  /**
   * Creates/prepare the HTML (by vue-components) for printing
   *
   * @param areas areas to print
   * @param printType
   * @param lang
   * @returns
   */
  public async prepareQrCodeHtmlAndPrint (areas: IArea[], printType: QrCodePrintTypes, lang: string) {
    if (areas.length === 0 || this._venue.id === '') {
      return;
    }
    this.loading = true;

    const fronts: Array<{ element: HTMLElement; position: number }> = [];

    await Promise.all(areas.map(async (area, index) => {
      let foundQrCode = this._qrCodes.find(x => x.areaId === area.id && x.venueId === this._venue.id);
      let renderedFront: HTMLElement = null;
      // when no qrCode exists => create one
      if (!foundQrCode) {
        const newQrCode = await createQrCodeForArea(this._venue, area, printType);
        if (!newQrCode) {
          this.loading = false;
          return;
        }
        this._qrCodes.push(newQrCode);
        foundQrCode = newQrCode;
      }

      // create qrCodeImage and set it to qrCode
      if (foundQrCode) {
        foundQrCode.qrCodeImage = createQrCodeImage(foundQrCode, this._venue, printType, this._packageScope.hasDesignPackage);
      }
      // when renderAdminQrCode == true => render adminPrint-Template else normal qrPrint-Template
      if (printType === QrCodePrintTypes.Admin) {
        renderedFront = new VenueQrAdminPrint({
          propsData: {
            displayLanguage: lang,
            qrCode: foundQrCode,
            venue: this._venue,
            packageScope: this._packageScope,
          },
          data: {
            i18n: this._vue.$i18n,
          },
        }).$mount().$el as HTMLElement;
      } else {
        const fullLogoUrl = await getVenueLogoUrl(this._venue, undefined, true);
        renderedFront = new VenueQrPrint({
          propsData: {
            area: area,
            qrCode: foundQrCode,
            venue: this._venue,
            packageScope: this._packageScope,
            displayLanguage: lang,
            fullLogoUrl: fullLogoUrl,
          },
          data: {
            i18n: this._vue.$i18n,
          },
        }).$mount().$el as HTMLElement;
      }
      // collect frontSides and backSides of print qrCode
      fronts.push({ element: renderedFront.querySelector('.print-templ-wrapper'), position: index });
    }));
    await this.printMe(fronts);
  }

  /**
   * Print all the created/prepared frontElement
   * @param frontElements
   */
  private async printMe (frontElements: Array<{ element: HTMLElement; position: number }>) {
    let html = `<html>
        <head>
        <title>${window.document.title}</title>
        <style type="text/css">
            ${AreaQrCodeCssHelper.getPrintCss(this._venue, this._packageScope.hasDesignPackage)}
        </style>
        </head>
        <body>
    `;
    for (const frontEl of frontElements.sort((a, b) => a.position - b.position)) {
      html += frontEl.element.innerHTML;
    }
    html += '</body></html>';

    // create iFrame with custom printHTML and print this iframe
    const iframe = document.createElement('iframe') ;
    document.body.appendChild(iframe);
    iframe.name = 'print-qr-code-iframe';
    iframe.contentWindow.document.open();
    iframe.contentWindow.document.write(html);
    // can display the print-preview when ALL images loaded
    await Promise.all(Array.from(iframe.contentWindow.document.images).map(img => {
      if (img.complete) {
        return Promise.resolve(img.naturalHeight !== 0);
      }
      return new Promise(resolve => {
        img.addEventListener('load', () => resolve(true));
        img.addEventListener('error', () => resolve(false));
      });
    })).then(results => {
      if (results.every(res => res)) {
        this.loading = false;
        iframe.contentWindow.focus();
        iframe.contentWindow.print();

        window.setTimeout(() => {
          iframe.contentWindow.close();
          document.body.removeChild(iframe);
        }, 1000);
      } else {
        this.loading = false;
      }
    });
  }
}
