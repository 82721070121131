import { ISafetyInstructions, ISafetyInstructionItem } from '@einfachgast/shared';
import { Expose, plainToClass, Transform } from 'class-transformer';
import { SafetyInstructionItem } from '@/models/venues/safety-instruction-item';

export class SafetyInstructions implements ISafetyInstructions {
  @Expose()
  @Transform(value => (value === null || value === undefined) ? false : value, { toClassOnly: true })
  public active: boolean;

  @Expose()
  @Transform(value => (value === null || value === undefined) ? true : value, { toClassOnly: true })
  public required: boolean;

  @Expose()
  @Transform(value => value || { de: '', en: '' }, { toClassOnly: true })
  public introText: {[key: string]: string};

  @Expose()
  @Transform(value => value?.map((x: ISafetyInstructionItem) =>
    plainToClass<SafetyInstructionItem, ISafetyInstructionItem>(SafetyInstructionItem, x)) || [],
  { toClassOnly: true })
  public instructionItems: ISafetyInstructionItem[];
}
