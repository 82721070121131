import visibility from 'vue-visibility-change';
import { DBCollections, firebaseWrapper as fb } from '@/firebase-wrapper';
import firebase from 'firebase/app';
import { store } from '@/store';
import { AuthModule } from '@/store/modules/auth';
import { getModule } from 'vuex-module-decorators';
import { getDoc, doc, updateDoc, Timestamp, collection } from 'firebase/firestore';
/**
 * Code for this Vue plugin was shamelessly stolen from
 * https://github.com/vv13/vue-authplugin/blob/master/src/index.ts
 */
const plugin = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  install (Vue: any) {
    Vue.use(visibility);

    // Update lastSeenAt field every time the page visibility changes
    visibility.change(() => {
      if (fb.auth.currentUser) {
        // this changes the last seen timestamp in the background,
        const authStore = getModule(AuthModule, store);
        let collectionPath = DBCollections.VenueOwners;
        if (authStore.permissions.isChildUser) {
          collectionPath = DBCollections.ChildUsers;
        }
        const docRef = doc(fb.db, `${collectionPath}/${fb.auth.currentUser.uid}`);
        void getDoc(docRef).then(async () => {
          await updateDoc(docRef, { lastSeenAt: Timestamp.now() });
        });
      }
    });
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
if (typeof window !== 'undefined' && (window as any).Vue) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).Vue.use(plugin);
}

export { plugin as InteractionPlugin };
