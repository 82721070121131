import { render, staticRenderFns } from "./venues-data.vue?vue&type=template&id=09ca1ac1&"
import script from "./venues-data.vue?vue&type=script&lang=ts&"
export * from "./venues-data.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports