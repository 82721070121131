import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { FirebaseFunctions, firebaseWrapper as fb } from '@/firebase-wrapper';
import Subscription from '@/models/stripe/subscription';
import { Product } from '@/models/stripe/product';
import { loadStripe } from '@stripe/stripe-js';
import { StartSessionPayload } from '@/models/stripe/start-session-payload';
import { SubscribeProductPayload } from '@/models/stripe/susbcribe-product-payload';
import { stripeConfiguration } from '@/stripe';
import { IStripeStore } from '../interfaces/i-stripe-store';
import { SubscribeProductResponse } from '@/models/stripe/subscribe-product-response';
import { PackageScope } from '@/models/stripe/package-scope';
import { IVenueOwner, SubscriptionStatus } from '@einfachgast/shared';
import { plainToClass } from 'class-transformer';
import { httpsCallable } from 'firebase/functions';

@Module({ namespaced: true, name: 'stripe' })
export class StripeModule extends VuexModule implements IStripeStore {
  private _packageScope: PackageScope = new PackageScope();
  private _packageScopeLoading = false;

  @Mutation
  setPackageScope (packageScope: PackageScope) {
    this._packageScope = packageScope;
  }

  get packageScope () {
    return this._packageScope;
  }

  @Mutation
  setPackageScopeLoading (state: boolean) {
    this._packageScopeLoading = state;
  }

  get packageScopeLoading () {
    return this._packageScopeLoading;
  }

  get hasSubscription () {
    return this.packageScope?.Status !== SubscriptionStatus.None;
  }

  get isSubscribedCustomProduct () {
    const user = this.context.rootGetters['auth/venueOwner'] as IVenueOwner;
    if (!this.packageScope?.visibility) {
      return false;
    }
    return this.packageScope.visibility.indexOf(user?.userId) !== -1;
  }

  @Action
  async startStripeSession (payload: StartSessionPayload): Promise<string> {
    const stripeSessionCall = httpsCallable<StartSessionPayload, string>(fb.functions, FirebaseFunctions.StripeSession);
    const stripeSession = await stripeSessionCall(payload);
    return stripeSession.data;
  }

  @Action
  async getSubscription (): Promise<Subscription> {
    const stripeSubscriptionInfoCall = httpsCallable<void, Subscription>(fb.functions, FirebaseFunctions.GetSubscriptionInfos);
    const subscriptionInfos = await stripeSubscriptionInfoCall();
    return subscriptionInfos.data;
  }

  @Action
  async getAvailableProducts (): Promise<Product[]> {
    const stripeAvailableProducts = await httpsCallable(fb.functions, FirebaseFunctions.GetAvailableProducts)();
    let products = stripeAvailableProducts.data as Product[];
    products = products.sort((a, b) => a.prices[0].unit_amount - b.prices[0].unit_amount); // sort by price

    const stripeDeactivatedProductInSub = await httpsCallable<void, Product>(fb.functions, FirebaseFunctions.GetDeactivatedProductOfSubscription)();
    if (stripeDeactivatedProductInSub.data && products && products.length > 0) {
      products.unshift(stripeDeactivatedProductInSub.data);
    }
    return products;
  }

  @Action
  async subscribeProduct (payload: SubscribeProductPayload): Promise<SubscribeProductResponse> {
    const subscribeProductResponse = await httpsCallable<SubscribeProductPayload, string>(fb.functions, FirebaseFunctions.SubscribeProduct)(payload);
    const stripeInstance = await loadStripe(stripeConfiguration.StripeApiKey);
    const subscriptionResponse = await stripeInstance.redirectToCheckout({
      sessionId: subscribeProductResponse.data,
    });
    return subscriptionResponse;
  }

  @Action
  async changeSubscribedProduct (payload: SubscribeProductPayload): Promise<SubscribeProductResponse> {
    const changeSubscribedProductCall = httpsCallable<SubscribeProductPayload, string>(fb.functions, FirebaseFunctions.ChangeSubscribedProduct);
    const subscribeProductResponse = await changeSubscribedProductCall(payload);
    const stripeInstance = await loadStripe(stripeConfiguration.StripeApiKey);
    const subscriptionResponse = await stripeInstance.redirectToCheckout({
      sessionId: subscribeProductResponse.data,
    });
    return subscriptionResponse;
  }

  @Action
  async getPackageScope (): Promise<PackageScope> {
    this.context.commit('setPackageScopeLoading', true);
    const getPackageScopeCall = httpsCallable(fb.functions, FirebaseFunctions.GetPackageScope);
    const getPackageScopeResponse = await getPackageScopeCall();
    const packageScope = plainToClass(PackageScope, getPackageScopeResponse.data);
    if (packageScope) {
      packageScope.TrialStart = new Date(packageScope.TrialStart);
      packageScope.TrialEnd = new Date(packageScope.TrialEnd);
    }
    this.context.commit('setPackageScope', packageScope);
    this.context.commit('setPackageScopeLoading', false);
    return packageScope;
  }
}
