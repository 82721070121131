


















































































import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { IArea, IVenue } from '@einfachgast/shared';

import firebase from 'firebase/app';
import LiveVisitorTimer from '@/components/live-visitors/live-visitor-timer.vue';
import { FirebaseFunctions, firebaseWrapper as fb } from '@/firebase-wrapper';
import LiveVisitorAreaItemCheckoutBtn from '@/components/live-visitors/live-visitor-area-item-checkout-btn.vue';

import * as Sentry from '@sentry/vue';
import { httpsCallable } from 'firebase/functions';
import { Timestamp } from 'firebase/firestore';

export type BuefyTableColumn = {
  label: string,
  customKey: string | number
  field: string,
  width: [number, string],
  numeric: boolean,
  centered: boolean,
  searchable: boolean,
  sortable: boolean,
  visible: {
      type: boolean,
      default: true
  },
  subheading: [string, number],
  sticky: boolean,
  headerSelectable: boolean,
  headerClass: string,
  cellClass: string,
  renderer?: string;
};

@Component({
  name: 'LiveVisitorAreaItem',
  components: {
    LiveVisitorTimer,
    LiveVisitorAreaItemCheckoutBtn,
  },
})
export default class LiveVisitorAreaItem extends Vue {
  currentPage = 1;
  entriesPerPage = 20;
  checkoutAllLoading = false;

  columns: Partial<BuefyTableColumn>[] = [
    {
      searchable: true,
      label: 'Name',
      field: 'name',
      sortable: true,
    },
    {
      searchable: false,
      label: 'Personen',
      field: 'visitorsCount',
      sortable: true,
      centered: true,
    },
    {
      searchable: false,
      label: 'Aufenthaltsdauer',
      field: 'visitStartDate',
      sortable: true,
      centered: true,
      renderer: 'LiveVisitorTimer',
    },
    {
      searchable: false,
      label: '',
      field: '',
      sortable: false,
      centered: false,
      renderer: 'LiveVisitorAreaItemCheckoutBtn',
    },
  ];

  @Prop()
  venue: IVenue;

  @Prop()
  area: IArea;

  @Prop()
  index: number;

  @Prop()
  areaCount: number;

  get isEven () {
    return (this.index + 1) % 2 === 0;
  }

  get isLastItem () {
    return this.index + 1 === this.areaCount;
  }

  get applicableCustomFields () {
    return (this.venue.customFields || []).filter(x => x.showInLiveView);
  }

  get preparedColumns () {
    const columns = [...this.columns];
    const insertIndex = columns.findIndex(x => x.field === 'visitStartDate');
    this.applicableCustomFields.forEach(field => {
      columns.splice(insertIndex, 0, {
          label: field.label[this.$i18n.locale],
          field: field.name,
          searchable: true,
          sortable: true,
      });
    });
    return columns;
  }

  get pendingVisitsByArea () {
    return this.venue.pendingVisits
      .filter(x => x.areaId === this.area.id)
      // unwrap customFields because this makes utilizing the buefy frontend search way easier...
      .map(x => {
        return {
          ...x,
          ...x.customFields,
        };
      });
  }

  get currentLimitAmount () {
    return this.pendingVisitsByArea.reduce((a, b) => a + b.visitorsCount, 0);
  }

  getVisistDurationInMin (visistStartDate: Timestamp) {
    const now = new Date();
    let diff = (now.getTime() - visistStartDate.toDate().getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }

  checkoutAllVisitors () {
    this.$buefy.dialog.confirm({
      message: 'Möchten Sie wirklich alle Gäste abmelden?',
      cancelText: 'abbrechen',
      onConfirm: async () => {
        if (!this.pendingVisitsByArea || this.pendingVisitsByArea.length === 0) {
          return;
        }
        try {
          this.checkoutAllLoading = true;

          const checkout = httpsCallable(fb.functions, FirebaseFunctions.CheckoutAdmin);
          const chunkedPendingVisitIds = this.chunkVisitIds(this.pendingVisitsByArea.map(x => x.id), 200);

          await Promise.all(chunkedPendingVisitIds.map(x => checkout(x)));
        } catch (e) {
          Sentry.captureException(e);
        }
        this.checkoutAllLoading = false;
      },
    });
  }

  chunkVisitIds (allPendingVisitIds: string[], size: number) {
    const results = [];
    while (allPendingVisitIds.length) {
      results.push(allPendingVisitIds.splice(0, size));
    }
    return results;
  }
}
