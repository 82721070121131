import { ChildUserStatus, IChildUser } from '@einfachgast/shared';
import { IsEmail } from 'class-validator';

export class ChildUser implements IChildUser {
  @IsEmail(undefined, { message: 'Bitte geben Sie eine gültige E-Mail-Adresse an.'})
  email: string;

  status: ChildUserStatus;
  parentUserId: string;
  id: string;
}
