import { IAdvancedFieldInfo } from '@/helpers/visits-download-helper';
import { IVisit } from '@einfachgast/shared';
import { ICustomFieldDefinitionWithVenueId, ICustomFieldResolver } from '../interfaces';
import { AuthModule } from '@/store/modules/auth';
import { getModule } from 'vuex-module-decorators';
import { store } from '@/store';
import { interfaces } from 'inversify';

export class CustomFieldResolver implements ICustomFieldResolver {
  // eslint-disable-next-line no-useless-constructor
  constructor (private container: interfaces.Container) {}

  get authStore () {
    return getModule(AuthModule, store);
  }

  get venues () {
    return this.authStore?.ownedVenues;
  }

  get allCustomFields () {
    const customFields: ICustomFieldDefinitionWithVenueId[] = [];
    this.venues.filter(venue => venue.customFields)
      .forEach((venue) => {
        venue.customFields.forEach((customField) => {
          const customFieldVenueId = Object.assign({ venueId: venue.id }, customField);
          customFields.push(customFieldVenueId);
        });
      });
    return customFields;
  }

  registerCustomFieldsForExport () {
    this.allCustomFields.forEach((x) => {
      this.registerFieldInfosForCustomField(x);
    });
  }

  registerFieldInfosForCustomField (customField: ICustomFieldDefinitionWithVenueId) {
    this.container
      .bind<IAdvancedFieldInfo<IVisit>>('advancedFieldInfos')
      .toConstantValue({
        label: customField.label.de, // @Todo use translation here
        value: (visit: IVisit) => visit.customFields?.[customField.name] || '',
        venueId: customField.venueId,
        includeInCsvExport: customField.includeInCsvExport,
      });
  }
}
