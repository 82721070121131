






































import Component from 'vue-class-component';
import MenuRendererAbstract from './abstract';

@Component({
  name: 'MyPlanMenuItemRenderer',
})
export default class LiveMenuItemRenderer extends MenuRendererAbstract {
  get badgeType () {
    return 'is-success';
  }

  get hasVenues () {
    return (this.$auth.ownedVenues || []).length > 0;
  }
 
  get visitorsCount () {
    return (this.$auth.ownedVenues || []).reduce((acc, venue) => {
      return acc + venue.pendingVisits.reduce((a, b) => a + b.visitorsCount, 0);
    }, 0);
  }
}
