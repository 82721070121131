import { registerDecorator, ValidationOptions, ValidationArguments } from 'class-validator';

export function ArrayWhitelist (whitelist: string[], validationOptions?: ValidationOptions) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (object: Record<string, any>, propertyName: string) {
    registerDecorator({
      name: 'arrayWhitelist',
      target: object.constructor,
      propertyName: propertyName,
      constraints: [],
      options: validationOptions,
      validator: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
        validate (value: any, args: ValidationArguments) {
          return Array.isArray(value) && !value.some(v => whitelist.indexOf(v) === -1);
        },
      },
    });
  };
}
