export default {
  step: 'Schritt',
  myData: 'Meine Daten',
  registration: 'ANMELDUNG',
  howItWorks: 'SO GEHT\'S',
  scanQrCode: 'QR-Code scannen',
  enterContactDetails: 'Kontaktdaten eintragen',
  ready: 'Fertig',
  browser: 'Browser',
  encryptedDataTransmissionInfo: 'Verschlüsselte Datenübertragung &amp; Speicherung<br/>DSGVO-konform, Serverstandort in Deutschland',
  qrCodeIsNoIntendedForCustomers: 'Scannen Sie den QR-Code mit einem Tablet oder geben Sie die angezeigte URL in einem Browser auf dem Tablet ein. Ein so eingerichtetes Tablet können Sie dann im Kiosk-Modus nutzen, um Besucher selbstständig, auch ohne Smartphone einchecken zu lassen.',
  deLang: 'Deutsch',
  enLang: 'English',
  esLang: 'Español',
  ruLang: 'русский',
  frLang: 'Français',
  trLang: 'Türk',
  VenueEditTabDesign: 'Design',
  'VenueEditTabDesign.QrCode': 'QR-Code',
  'VenueEditTabDesign.Form': 'Formular',
  'VenueEditTabDesign.Settings': 'Funktionsweise',
  'VenueEditTabDesign.Covid': 'COVID-19 Optionen',
  'VenueEditTabDesign.Privacy': 'Datenschutz',
  'VenueEditTabDesign.Address': 'Adresse',
  'additionalConfirmation': 'Zusätzliche Bestätigung',
  'additionalConfirmation.settings': 'Einstellungen',
  'additionalConfirmation.enabledLabel': 'Soll der Besucher den Checkin zusätzlich bestätigten?',
  'additionalConfirmation.disable': 'aktiviert',
  'additionalConfirmation.enable': 'deaktiviert',
  'additionalConfirmation.whatMethodUseConfirmCheckIn': 'Mit welcher Methode soll der Besucher den Checkin bestätigen?',
  signaturePad: 'Digitale Unterschrift',
  checkbox: 'Checkbox',
  'additionalConfirmation.checkboxLcheckinConfirmationSettingsabel': 'Beschriftung der Checkbox',
  'additionalConfirmation.signaturePadLabel': 'Beschriftung des Unterschriftsfeldes',
  venueEditTabAddress: 'Adresse',
  venueEditTabAdditionalFeatures: 'Dokumente & Links',
  venueEditTabQrCode: 'QR-Code',
  venueEditTabOnlineForm: 'Online-Formular',
  venueEditTabOptions: 'Einstellungen',
  venueEditTabAreas: 'Bereiche',
  venueEditTabDataSecurity: 'Datenschutz',
  venueCreateTabAddress: 'Adresse',
  venueCreateTabDataSecurity: 'Datenschutz',
  venueCreateTabStart: 'Starten',
  NoDataAvailableInPeriod: 'Für den gewählten Zeitraum sind keine Daten vorhanden',
  passwordMissmatchError: 'Die Passwörter stimmen nicht überein',
  emptyPasswordError: 'Das Passwort darf nicht leer sein',
  tooShortPasswordMessage: 'Das Passwort muss mindestens 8 Zeichen lang sein',
  passwordConfirmationEmptyMessage: 'Bitte bestätigen Sie das Passwort',
  invalidPasswordMessage: 'Bitte prüfen Sie Ihre Eingabe',
  passwordChangeCompleteNotification: 'Sie werden in wenigen Sekunden auf die Login-Seite umgeleitet. Dort können Sie sich mit Ihrer Email und Ihrem Passwort anmelden.',
  toLogin: 'Zum Login',
};
