






import { Component } from 'vue-property-decorator';
import Venues from '@/views/Venues.vue';
import ManagementTopMenu from '@/components/management/management-top-menu.vue';
import Vue from 'vue';

@Component({
  name: 'Management',
  components: {
    Venues,
    ManagementTopMenu,
  },
})
export default class Management extends Vue {}
