







import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'FormDivider',
})
export default class FormDivider extends Vue {
  @Prop({ default: '#DDDDDD' })
  color: string;

  @Prop()
  label: string;
}
