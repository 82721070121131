
























































import { Component, Inject, Vue } from 'vue-property-decorator';
import LiveVisitorAreaItem from '@/components/live-visitors/live-visitor-area-item.vue';
import LiveVisitorEmptyStateMessage from '@/components/live-visitors/live-visitor-empty-state-message.vue';
import { CheckInModes, IArea, IPendingVisit, IVenue, IVisit } from '@einfachgast/shared';
import { VisitDownloader } from '@/helpers/visits-download-helper';
import { FirebaseFunctions, firebaseWrapper as fb } from '@/firebase-wrapper';
import { httpsCallable } from 'firebase/functions';

@Component({
  name: 'LiveVisitors',
  components: {
    LiveVisitorAreaItem,
    LiveVisitorEmptyStateMessage,
  },
})
export default class LiveVisitors extends Vue {
  // i am adding this comment to test the git push
  selectedVenueId: string = null;
  loading = false;

  get limit () {
    return this.selectedVenue?.limit;
  }

  get selectedVenue () {
    return this.venuesSorted.find(x => x.id === this.selectedVenueId);
  }

  get filteredAreas () {
    return this.selectedVenue?.areas.filter((x: IArea) => x.isDeactivated === false);
  }

  get venuesSorted () {
    return this.$auth.ownedVenues.filter(x => x.isDeactivated === false && x.isDeleted === false && x.checkinMode === CheckInModes.CheckInCheckOut)
      .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);
  }

  get currentVisits () {
    return this.selectedVenue?.pendingVisits.reduce((a: number, b: IPendingVisit) => a + b.visitorsCount, 0);
  }

  get liveVisitsCount () {
    return (this.limit)
      ? `${this.currentVisits}/${this.limit}`
      : this.currentVisits;
  }

  @Inject('visitorsDownloadHelper')
  public downloadHelperConstructor: (visits: IVisit[], _venue: IVenue) => VisitDownloader;

  created () {
    this.selectedVenueId = this.venuesSorted[0]?.id;
  }

  async download () {
    this.loading = true;
    try {
      const downloadPendingVisitsDataFunc = httpsCallable<{ venueId: string }, IVisit[]>(fb.functions, FirebaseFunctions.DownloadPendingVisitsData);
      const downloadVisitorData = await downloadPendingVisitsDataFunc({
        venueId: this.selectedVenueId,
      });
      if (downloadVisitorData.data.length === 0) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Aktuell sind keine Besucher in diesem Standort eingechecked',
          position: 'is-top',
          type: 'is-info',
        });
        this.loading = false;
        return;
      }
      const downloader = this.downloadHelperConstructor(downloadVisitorData.data, this.selectedVenue);
      await downloader.download('excel');
      this.loading = false;
    } catch (e) {
      this.$buefy.toast.open({
        duration: 5000,
        message: `Fehler beim Herunterladen der Besucherdaten aufgetreten. Bitte versuchen Sie es später noch einmal. ${e}`,
        position: 'is-top',
        type: 'is-danger',
      });
      this.loading = false;
    }
  }
}
