
















import Component from 'vue-class-component';
import Vue from 'vue';
import { RouteNames } from '@/router';
import { SubscriptionStatus } from '@einfachgast/shared';

@Component({
  name: 'PlanExpirationHint',
})
export default class PlanExpirationHint extends Vue {
  get IsVisible () {
    return this.$stripe.packageScope?.Status === SubscriptionStatus.None
      && this.IsRegistrationCompleted
      && this.$auth.venueOwner
      && this.isMyDataPage;
  }

  get isMyDataPage () {
    return this.$route.name === RouteNames.MyData;
  }

  get routerTarget () {
    return { name: RouteNames.Abonnement };
  }

  get IsRegistrationCompleted () {
    return this.$auth.isRegistrationComplete;
  }
}
