





























import Component from 'vue-class-component';
import Vue from 'vue';
import { routes } from '@/router/index';
import { RouteRecord } from 'vue-router';

@Component({
  name: 'SmallMenu',
})
export default class SmallMenu extends Vue {
  get routes () {
    return routes
      .filter(x => x.meta && (!!x.meta.menuTitle || !!x.meta.menuItemRenderer))
      .filter(x => (x.meta.hasPermission && typeof x.meta.hasPermission === 'function')
        ? x.meta.hasPermission()
        : true,
      )
      .filter(x => {
        if (x.meta.canShow  && typeof x.meta.canShow === 'function') {
          return x.meta.canShow(this);
        }
        return true;
      })
      .sort((a, b) => a.meta?.menuSortOrder - b.meta?.menuSortOrder);
  }

  isActive (route: RouteRecord) {
    return this.$route.path === route.path;
  }

  async logout () {
    await this.$auth.logout();
  }
}
