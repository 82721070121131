import { IPackageScope } from '@/interfaces';
import { IPrice } from '@/store/interfaces/i-price';
import { Duration } from './duration';

enum ScopeKeys {
  DesignPackage = 'stripe_metadata_package_scope_customizable_design',
  MaxAreaTrialCount = 'stripe_metadata_package_scope_MaxArea_trial',
  MaxVenueCount = 'stripe_metadata_package_scope_MaxVenues',
  MaxAreaCount = 'stripe_metadata_package_scope_MaxArea',
  MaxGuestAmount = 'stripe_metadata_max_guest_amount',
  Menu = 'stripe_metadata_package_scope_menu',
  MenuHint = 'stripe_metadata_package_scope_menu_hint',
  Visibility = 'stripe_metadata_visibility',
  Facebook = 'stripe_metadata_package_scope_facebook',
  FacebookHint = 'stripe_metadata_package_scope_facebook_hint',
  GoogleReview = 'stripe_metadata_package_scope_googleReview',
  GoogelReviewHint = 'stripe_metadata_package_scope_googleReview_hint',
}

class PackageScope implements IPackageScope {
    public Scopes: {[key: string]: string}[]
    public Status: string
    public TrialStart: Date
    public TrialEnd: Date
    public StartDate: Date
    public ProductName: string
    public Price: IPrice
    public ProductId: string

    private has (key: ScopeKeys): boolean {
      const strval = this.get(key);

      // Quick and cheap check for falsy values
      if (!strval) {
        return false;
      }

      // Return true in any of these cases
      switch (strval) {
        case '1':
        case 'yes':
        case 'true':
          return true;
      }

      return false;
    }

    private get (key: ScopeKeys): string {
      const scope = this.Scopes?.find(e => e.key === key);
      return scope?.value;
    }

    private num (key: ScopeKeys): number {
      const numval = parseInt(this.get(key), 10);

      return !isNaN(numval) ? numval : 0;
    }

    public get hasMenu () {
      return this.has(ScopeKeys.Menu);
    }

    public get menuHint () {
      return this.get(ScopeKeys.MenuHint);
    }

    public get hasGoogleReview () {
      return this.has(ScopeKeys.GoogleReview);
    }

    public get visibility () {
      return this.get(ScopeKeys.Visibility);
    }

    public get googleReviewHint () {
      return this.get(ScopeKeys.GoogelReviewHint);
    }

    public get hasFacebook () {
      return this.has(ScopeKeys.Facebook);
    }

    public get facebookHint () {
      return this.get(ScopeKeys.FacebookHint);
    }

    public get maxVenueCount (): number {
      return this.num(ScopeKeys.MaxVenueCount);
    }

    public get maxGuestAmount (): number {
      return this.num(ScopeKeys.MaxGuestAmount);
    }

    public get maxAreaCount (): number {
      return this.num(ScopeKeys.MaxAreaCount);
    }

    public get maxAreaTrialCount (): number {
      return this.num(ScopeKeys.MaxAreaTrialCount);
    }

    public get hasDesignPackage () {
      return this.has(ScopeKeys.DesignPackage);
    }

    public get trialDaysLeft () {
      // in isTrial '-0 > 0 = true' because rounded days direct after trialEnd is -0
      // eslint can't no-compare-neg-zero => therefore you can't use a "normal" if-conditon (roundedDays === -0)
      if (Object.is(this.trialDurationLeft.roundedDays, -0)) {
        return -1;
      }
      return this.trialDurationLeft.roundedDays;
    }

    public get trialDurationLeft (): Duration {
      const now = new Date();
      const diffInMs = this.TrialEnd?.getTime() - now.getTime();
      const ret = Duration.fromMs(diffInMs);
      return ret;
    }

    public get isTrial () {
      return this.trialDurationLeft.days >= 0;
    }
}
export { PackageScope, ScopeKeys };
