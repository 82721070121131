










import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import WebTab from '@/components/venues/venues-design/web-tab.vue';
import VenueWebFormPreview from '@/components/venues/venue-web-form-preview.vue';
import { IVenue } from '@einfachgast/shared';
import { ValidationError } from 'class-validator';

@Component({
  name: 'VenueWebFormStep',
  components: {
    WebTab,
    VenueWebFormPreview,
  },
})
export default class VenueWebFormStep extends Vue {
  droppedFile: File = null;
  displayLanguage: string = null;

  @Prop()
  venue: IVenue;

  @Prop({ default: (): ValidationError[] => [] })
  errors: ValidationError[];

  get webDroppedFile () {
    return (this.droppedFile)
      ? this.droppedFile
      : null;
  }

  created () {
    this.displayLanguage = this.$auth.venueOwner?.settings?.defaultLanguage;
  }

  setLanguage (lang: string) {
    this.displayLanguage = lang;
  }

  onDropFile (droppedFile: File) {
    this.droppedFile = droppedFile;
    this.$emit('onDropFile', droppedFile);
  }
}
