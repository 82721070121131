

































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Sketch } from 'vue-color';
import ClickOutside from 'vue-click-outside';

@Component({
  name: 'ColorPickerFieldWrapper',
  components: {
    Sketch,
  },
  directives: {
    ClickOutside,
  },
})
export default class ColorPickerFieldWrapper extends Vue {
  showColorPicker = false;

  @Prop()
  label: string;

  @Prop()
  value: string;

  get presetColors () {
    return [
      '#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321',
      '#417505', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2',
      '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF',
    ];
  }

  get hasDesignPackage () {
    return this.$stripe.packageScope.hasDesignPackage;
  }

  toggleColorPicker () {
    if (this.hasDesignPackage) {
      this.showColorPicker = !this.showColorPicker;
    }
  }

  updateColor (color: { hex: string }) {
    this.$emit('input', color.hex);
  }
}
