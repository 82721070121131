































































import { Component, Vue } from 'vue-property-decorator';
import firebase from 'firebase/app';
import VenueTableRow from '@/components/dashboard/venue-table-row.vue';
import DashboardPieChart from '@/components/dashboard/dashboard-pie-chart.vue';
import DashboardLineChart from '@/components/dashboard/dashboard-line-chart.vue';
import { Timestamp } from 'firebase/firestore';

@Component({
  name: 'Dashboard',
  components: {
    VenueTableRow,
    DashboardPieChart,
    DashboardLineChart,
  },
  filters: {
    formatNumber (number: number) {
      return new Intl.NumberFormat([], {}).format(number);
    },
  },
})
export default class Dashboard extends Vue {
  get venues () {
    return [...(this.$auth.ownedVenues || [])]
      .filter(x => x.isDeactivated === false && x.isDeleted === false)
      .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);
  }

  get visitorsToday () {
    return this.venues.map(x => x.history?.todaysVisitors || 0).reduce((a, b) => a + b, 0);
  }

  get visitorsTotal () {
    return this.venues.map(x => x.history?.allVisitors || 0).reduce((a, b) => a + b, 0);
  }

  get liveVisitors () {
    return this.$auth.ownedVenues
      .reduce((a, b) =>
        a + b.pendingVisits.reduce((c, d) => c + d.visitorsCount, 0)
      , 0);
  }

  getFormattedVisitDate (visitDate: Timestamp) {
    return visitDate
      ? visitDate.toDate().toLocaleTimeString([], {
        year: 'numeric', month: 'numeric', day: '2-digit', hour: '2-digit', minute: '2-digit',
      })
      : '-';
  }
}
