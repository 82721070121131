
















import Component from 'vue-class-component';
import Vue from 'vue';
import VenueLanguageSettings from '@/components/settings/venue-language-settings.vue';
import { IVenueOwner, IVenueOwnerSettings } from '@einfachgast/shared';
import { FormFunctions } from '@/mixins/form-functions';
import { Route } from 'vue-router';

@Component({
  name: 'Settings',
  components: {
    VenueLanguageSettings,
  },
})
export default class Settings extends Vue {
  loading = false;
  isDirty = false;
  initialSettings: IVenueOwnerSettings = null;

  get venueOwner (): IVenueOwner {
    return this.$auth.venueOwner;
  }

  mounted () {
    this.initialSettings = Object.assign({}, this.$auth?.venueOwner?.settings);
  }

  setIsDirty (isDirty: boolean) {
    this.isDirty = isDirty;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  beforeRouteLeave (to: Route, from: Route, next: Function) {
    if (this.isDirty) {
      this.$buefy.dialog.confirm({
        message: 'Es gibt ungespeicherte Änderungen auf dieser Seite.',
        cancelText: 'Seite verlassen',
        confirmText: 'Auf Seite bleiben',
        type: 'is-success',
        onConfirm: () => next(false),
        onCancel: () => {
          if (this.$auth?.venueOwner?.settings) {
            this.$auth.venueOwner.settings = Object.assign({}, this.initialSettings);
          }
          next();
        },
      });
    } else {
      next();
    }
  }

  async saveSettings () {
    if (!await this.validate()) {
      return false;
    }
    this.loading = true;
    try {
      await this.$auth.updateVenueOwner(this.venueOwner);
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Ihre Einstellungen wurde erfolgreich aktualisiert.',
        position: 'is-top',
        type: 'is-success',
      });
      this.isDirty = false;
    } catch (e) {
      this.$buefy.toast.open({
        duration: 5000,
        message: e.message,
        position: 'is-top',
        type: 'is-danger',
      });
    }
    this.loading = false;
  }

  async validate () {
    return await (this.$refs.languageSettings as FormFunctions).validate(this.venueOwner?.settings);
  }
}
