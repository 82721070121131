


















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IVenue } from '@einfachgast/shared';

@Component({
  name: 'VenuesOptionsStandardContent',
})
export default class VenuesOptionsStandardContent extends Vue {
  @Prop()
  venue: IVenue;
}
