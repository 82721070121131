





















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'TranslateableTextarea',
})
export default class TranslateableTextarea extends Vue {
  selectedLang: string = null;

  @Prop({ default: 5 })
  maxRows: number;

  @Prop({ default: 300 })
  maxLength: number;

  @Prop()
  value: {[key: string]: string};

  limitTextareaLine (e: KeyboardEvent) {
    const value = (e.target as HTMLTextAreaElement).value;
    if ((e.key === 'Enter' || e.keyCode === 13) && value.split('\n').length >= this.maxRows) {
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
  }

  get availableLanguages () {
    return this.$auth.venueOwner
      ?.settings
      ?.activeLanguages
      ?.map(langKey => ({
        key: langKey,
        label: this.$t(`${langKey}Lang`).toString() + ((this.defaultLanguage === langKey)
          ? ' (Standard)'
          : ''),
      })) || [];
  }

  get defaultLanguage () {
    return this.$auth.venueOwner?.settings?.defaultLanguage;
  }

  created () {
    if (typeof this.value !== 'object') {
      // workaround for OLD string fields
      const inputObject: {[key: string]: string} = {};
      inputObject[this.defaultLanguage] = (this.value as string).replace(/<br \/>/g, '\n');
      this.$emit('input', inputObject);
    } else {
      this.replaceLineBreaks();
    }
    this.selectedLang = this.defaultLanguage;
  }

  replaceLineBreaks () {
    for (const lang of this.availableLanguages) {
      this.value[lang.key] = this.value?.[lang.key]?.replace(/<br \/>/g, '\n');
    }
  }
}
