





























































import Component from 'vue-class-component';
import ManagementMenuBox from '@/components/management/management-menu-box.vue';
import ManagementMenu from '@/components/management/management-menu.vue';
import Vue from 'vue';
import { RouteNames } from '@/router';
import { SubscriptionStatus } from '@einfachgast/shared';

@Component({
  name: 'ManagementTopMenu',
  components: {
    ManagementMenuBox,
    ManagementMenu,
  },
})
export default class ManagementTopMenu extends Vue {
  get planName () {
    if (this.$stripe.packageScope?.Status === SubscriptionStatus.Trialing) {
      return 'Testphase';
    }
    
    if (this.$stripe.packageScope?.Status === SubscriptionStatus.None) {
      return 'Abgelaufen';
    }

    return this.$stripe.packageScope?.ProductName;
  }

  get stripeStatus () {
    return this.$stripe.packageScope?.Status;
  }

  get isInTrial () {
    return this.$stripe.packageScope?.Status === SubscriptionStatus.Trialing;
  }

  get hasSubscription () {
    return this.$stripe.hasSubscription;
  }

  get canDoStuff () {
    return this.isInTrial || this.hasSubscription;
  }

  get isOwnerDeleted () {
    return this.$auth.isVenueOwnerDeleted;
  }

  get hasActivePlan () {
    return this.$stripe.packageScope?.Status === SubscriptionStatus.Active;
  }

  get availableVenues () {
    return this.$auth.ownedVenues.filter(venue => !venue.isDeleted && !venue.isDeactivated);
  }

  get canAddNewVenue () {
    if (this.isOwnerDeleted || this.$stripe.packageScope?.maxVenueCount === 0) {
      return false;
    }
    if (this.$stripe.packageScope?.maxVenueCount === -1) {
      return true;
    }

    return (this.availableVenues || []).length < this.$stripe.packageScope?.maxVenueCount;
  }

  get addVenueTooltipLabel () {
    if (this.$stripe.packageScope?.isTrial) {
      return 'In der Testphase können keine weiteren Standorte mehr angelegt werden.';
    }
    return 'Mit Ihrem aktuellen Plan können keine weiteren Standorte mehr angelegt werden.';
  }

  goToMyData () {
    void this.$router.push({ name: RouteNames.MyData });
  }

  goToMyPlan () {
    void this.$router.push({ name: RouteNames.Abonnement });
  }

  goToSettings () {
    void this.$router.push({ name: RouteNames.Settings });
  }

  createVenue () {
    if (!this.canAddNewVenue) {
      this.$buefy.dialog.alert(this.addVenueTooltipLabel);
      return;
    }
    void this.$router.push({ name: RouteNames.NewVenue });
  }
  
}
