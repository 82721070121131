import { FirebaseConfiguration } from './classes/firebase-configuration';
import { VueEnvHelper } from './helpers/vue-envvar-helper';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Analytics, getAnalytics } from 'firebase/analytics';
import { Auth, browserLocalPersistence, connectAuthEmulator, getAuth, setPersistence } from 'firebase/auth';
import { Firestore, collection, connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { Functions, connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { FirebaseStorage, getStorage, connectStorageEmulator } from 'firebase/storage';

export enum FirebaseFunctions {
  AddQrCode='addQrCode',
  Checkout='checkout',
  CheckoutAdmin='checkoutAdmin',
  Checkin='checkin',
  CleanupHttp='cleanupHttp',
  CreateChildUser='createChildUser',
  ClearOnVenueDelete='clearOnVenueDelete',
  ClearUserDataAfterDeletion='clearUserDataAfterDeletion',
  CreateDataRequest='createDataRequest',
  CreateTrialSubscriptionOnCreate='createTrialSubscriptionOnCreate',
  AddCustomerAddressToStripeUpdate='addCustomerAddressToStripeUpdate',
  CreateVenue='createVenue',
  GetOwnerInformation='getOwnerInformation',
  GetVenue='getVenue',
  DeleteVenue='deleteVenue',
  DisableUserAccount='disableUserAccount',
  EnableUserAccount='enableUserAccount',
  GetAvailableProducts='getAvailableProducts',
  GetPackageScope='getPackageScope',
  GetSubscriptionInfos='getSubscriptionInfos',
  LoadPendingVisit='loadPendingVisit',
  RegisterVenueOwner='registerVenueOwner',
  RetrieveVisitConditions='retrieveVisitConditions',
  StoreVisit='storeVisit',
  StripeSession='stripeSession',
  SubscribeProduct='subscribeProduct',
  UpdateUserAccount='updateUserAccount',
  UpdateUnverifiedEmail='updateUnverifiedEmail',
  UpdateVenue='updateVenue',
  UpdateVenuesAggregates='updateVenuesAggregates',
  GetMaxGuestAmountForVenueOwner='getMaxGuestAmountForVenueOwner',
  ChangeSubscribedProduct='changeSubscribedProduct',
  StripeWebhook='stripeWebhook',
  SentryInit='sentryInit',
  TaskRunner='taskRunner',
  VenuesAutoCheckout='venuesAutoCheckout',
  VenuesMaxStayDurationCheckout='venuesMaxStayDurationCheckout',
  AddIsAdminUsers='addIsAdminUsers',
  DownloadVisitorData='downloadVisitorData',
  GetDeactivatedProductOfSubscription='getDeactivatedProductOfSubscription',
  DownloadPendingVisitsData='downloadPendingVisitsData',
  CompleteSignIn='completeSignIn',
  ReInviteChildUser='reInviteChildUser',
  DisableChildUser='disableChildUser',
  EnableChildUser='enableChildUser',
  VerifySignInToken='verifySignInToken'
}

export enum DBCollections {
  Venues = 'venues',
  VenueOwners = 'venueOwners',
  QrCodes = 'qrCodes',
  Visits = 'visits',
  PendingVisits = 'pendingVisits',
  Products = 'products',
  Prices = 'prices',
  DataRequestLogs = 'dataRequestLogs',
  DataRequests = 'dataRequests',
  TaskRunners = 'taskRunners',
  Mails = 'mails',
  InvalidTries = 'invalidTries',
  ChildUsers = 'childUsers',
  Subscriptions = 'subscriptions',
}

class FirebaseWrapper {
  private _auth: Auth = null
  private _firestore: Firestore = null
  private _functions: Functions = null
  private _perf: Performance = null
  private _analytics: Analytics = null;
  private fbConfig: FirebaseConfiguration
  private _authEmulatorConnected = false;
  private _storageEmulatorConnected = false;
  private _firestoreEmulatorConnected = false;
  private _functionsEmulatorConnected = false;
  private _envHelper: VueEnvHelper = null;
  app!: FirebaseApp;

  constructor () {
    this.fbConfig = new FirebaseConfiguration();
    this._envHelper = new VueEnvHelper('_FIREBASE');
    this.app = initializeApp(this.fbConfig);
  }

  analytics () {
    if (this.fbConfig.measurementId !== '') {
      this._analytics = getAnalytics(this.app);
    }
  }

  get auth (): Auth {
    const auth = getAuth(this.app);
    if (this.authEmulatorEnabled && !this._authEmulatorConnected) {
      connectAuthEmulator(auth, this.authUrl.toString());
      this._authEmulatorConnected = true;
    }
    void setPersistence(auth, browserLocalPersistence);
    return getAuth(this.app);
  }

  get functions (): Functions {
    const region = this._envHelper.get('FUNCTIONS_REGION');
    const functions = getFunctions(this.app, region);
    if (this.functionsEmulatorEnabled && !this._functionsEmulatorConnected) {
      connectFunctionsEmulator(
        functions,
        this._envHelper.get('EMULATOR_FUNCTIONS_HOST'),
        parseInt(this._envHelper.get('EMULATOR_FUNCTIONS_PORT')),
      );
      this._functionsEmulatorConnected = true;
    }
    return functions;
  }

  get db (): Firestore {
    const fireStore = getFirestore(this.app);
    if (this.firestoreEmulatorEnabled && !this._firestoreEmulatorConnected) {
      connectFirestoreEmulator(
        fireStore,
        this._envHelper.get('EMULATOR_FIRESTORE_HOST'),
        parseInt(this._envHelper.get('EMULATOR_FIRESTORE_PORT')),
      );
      this._firestoreEmulatorConnected = true;
    }
    return fireStore;
  }

  get storage (): FirebaseStorage {
    const storage = getStorage(this.app);
    if (this.storageEmulatorEnabled && !this._storageEmulatorConnected) {
      connectStorageEmulator(
        storage,
        this._envHelper.get('EMULATOR_STORAGE_HOST'),
        parseInt(this._envHelper.get('EMULATOR_STORAGE_PORT')),
      );
      this._storageEmulatorConnected = true;
    }
    return storage;
  }

  private get authUrl () {
      const authSchema = this._envHelper.get('EMULATOR_AUTH_SCHEMA', 'http');
      const authHost = this._envHelper.get('EMULATOR_AUTH_HOST', 'localhost');
      const authPort = parseInt(this._envHelper.get('EMULATOR_AUTH_PORT', '9099'), 10);
      const authPath = this._envHelper.get('EMULATOR_AUTH_PATH', '/');
      const authUrl = new URL(`${authSchema}://${authHost}:${authPort}${authPath}`);
      return authUrl;
  }

  private get authEmulatorEnabled () {
    return this._envHelper.get('EMULATOR_AUTH_ENABLED');
  }

  private get functionsEmulatorEnabled () {
    return this._envHelper.get('EMULATOR_FUNCTIONS_ENABLED');
  }

  private get firestoreEmulatorEnabled () {
    return this._envHelper.get('EMULATOR_FIRESTORE_ENABLED');
  }

  private get storageEmulatorEnabled () {
    return this._envHelper.get('EMULATOR_STORAGE_ENABLED');
  }

  get perf () {
    return this._perf;
  }

  get usersCollection () {
    return collection(this.db, DBCollections.VenueOwners);
  }

  get postsCollection () {
    return collection(this.db, 'posts');
  }

  get commentsCollection () {
    return collection(this.db, 'comments');
  }

  get likesCollection () {
    return collection(this.db, 'likes');
  }
}

const firebaseWrapper = new FirebaseWrapper();

export { firebaseWrapper };
