




































































































import { Component, Prop } from 'vue-property-decorator';
import VenuesOptions from '@/components/venues/venues-options.vue';
import VenueEditSubSteps from '@/components/venues/venue-steps/venue-edit-sub-steps.vue';
import VenuesCovidOptions from '@/components/venues/venues-options/venues-covid-options.vue';
import VenuesData from '@/components/venues/venues-data.vue';
import VenuesCheckinConfirmationSettings from '@/components/venues/venues-checkin-confirmation/venues-checkin-confirmation.vue';
import VenuesUserVisitContent from '@/components/venues/venues-options/venues-user-visit-content.vue';
import Vue from 'vue';
import { IVenue } from '@einfachgast/shared';
import { ValidationError } from 'class-validator';

@Component({
  name: 'VenuesOptionsStep',
  components: {
    VenuesOptions,
    VenueEditSubSteps,
    VenuesUserVisitContent,
    VenuesData,
    VenuesCovidOptions,
    VenuesCheckinConfirmationSettings,
  },
})
export default class VenuesOptionsStep extends Vue {
  @Prop()
  venue: IVenue;

  @Prop()
  activeTab: string;

  @Prop({ default: (): ValidationError[] => [] })
  errors: ValidationError[];

}
