import { IFeature, IFeatureStatic } from '@/interfaces';
import { AuthModule } from '@/store/modules/auth';
import { staticImplements } from '@/decorators/static-implements';
import { VueRouter } from 'vue-router/types/router';
import VueI18n from 'vue-i18n';
import * as deTranslation from '@/features/custom-fields/translation/de';
import * as enTranslation from '@/features/custom-fields/translation/en';
import { interfaces } from 'inversify';
import { IAdvancedFieldInfo, VisitDownloader } from '@/helpers/visits-download-helper';
import { CustomFieldResolver } from './custom-field-resolver/custom-field-resolver';
import { IVenue, IVisit } from '@einfachgast/shared';
import { getModule } from 'vuex-module-decorators';
import { store } from '@/store';

@staticImplements<IFeatureStatic>()
export class CustomFields implements IFeature {
  // @Todo: this should be resolved dynamically as soon as we have a owner view for it
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static canUseFeature (auth: AuthModule): boolean {
    return true;
    // return auth.permissions.hasFeature(Features.CustomFields);
  }

  public constructor (router: VueRouter, i18n: VueI18n) {
    // add rountes and languages

    i18n.mergeLocaleMessage('de', deTranslation.default);
    i18n.mergeLocaleMessage('en', enTranslation.default);
  }

  get authStore () {
    return getModule(AuthModule, store);
  }

  get venues () {
    return this.authStore?.ownedVenues;
  }

  public dependencyRegistrations (container: interfaces.Container) {
    new CustomFieldResolver(container).registerCustomFieldsForExport();

    // @Todo: this should be registered somewhere else as it is part of the core.
    // or maybe part of a CSV feature in a perfect world
    container.bind<(visits: IVisit[], _venue: IVenue) => VisitDownloader>('visitorsDownloadHelper')
      .toFactory<VisitDownloader>((context: interfaces.Context) => {
        return (visits: IVisit[], _venue: IVenue) => {
          const injectableFields = context.container.isBound('advancedFieldInfos')
            ? context.container.getAll<IAdvancedFieldInfo<IVisit>>('advancedFieldInfos')
            : [];
          return new VisitDownloader(visits, _venue, injectableFields.filter(x => x.includeInCsvExport === true));
        };
    });

    this.venues.filter(venue => venue.displaySafetyHints === true)
      .forEach((venue) => {
        container
          .bind<IAdvancedFieldInfo<IVisit>>('advancedFieldInfos')
          .toConstantValue({
            label: 'Warnhinweise akzeptiert',
            value: (visit: IVisit) => visit.safetyHintsAccepted ? 'Ja' : 'Nein',
            venueId: venue.id,
            includeInCsvExport: true,
          });
      });
  }
}
