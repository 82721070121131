

























































































import { Component, Prop } from 'vue-property-decorator';
import { IVenue, CheckinConfirmationSettingType } from '@einfachgast/shared';
import { FormFunctions } from '@/mixins/form-functions';
import { mixins } from 'vue-class-component';
import VenuesOptionsConfirmationModeItem from './venues-options-confirmation-mode-item.vue';
import TranslateableInput from '@/components/common/translateable-input.vue';

@Component({
  name: 'VenuesCheckinConfirmationSettings',
  components: {
    VenuesOptionsConfirmationModeItem,
    TranslateableInput,
  },
})
export default class VenuesCheckinConfirmationSettings extends mixins(FormFunctions) {
  @Prop()
  venue: IVenue;

  get isActive () {
    return this.venue.checkinConfirmationSettings?.active;
  }

  get CheckinConfirmationSettingType () {
    return CheckinConfirmationSettingType;
  }

  get isActiveLabel () {
    if (this.isActive) {
      return this.$t('additionalConfirmation.disable');
    }
    return this.$t('additionalConfirmation.enable');
  }
}
