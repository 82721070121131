

















































































import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { Product } from '@/models/stripe/product';
import { ToastProgrammatic as Toast } from 'buefy';
import { SubscribeProductResponse } from '@/models/stripe/subscribe-product-response';
import { IAboFeature, IAboMaxCountItem } from '@/interfaces';
import { SubscriptionStatus } from '@einfachgast/shared';

@Component({
  name: 'Product',
  filters: {
    formatNumber (number: number) {
      return new Intl.NumberFormat([], {}).format(number);
    },
  },
})
export default class ProductView extends Vue {
  loading = false;
  aboFeaturesKeys: { [key: string]: string } = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    stripe_metadata_package_scope_customizable_design: 'Individuelles Design',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    stripe_metadata_package_scope_menu: 'Dokumente hinterlegen',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    stripe_metadata_package_scope_facebook: 'Besuche teilen auf Facebook',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    stripe_metadata_package_scope_googleReview: 'Mehr bewertungen bei Google erhalten',
  };

  @Prop()
  product: Product;

  @Prop()
  intervalCount: number;

  get isActiveSubscription () {
    return this.$stripe.hasSubscription;
  }

  get maxGuestAmount () {
    return this.$stripe.packageScope.maxGuestAmount;
  }

  get selectedPrice () {
    return this.product.prices.find(p => p.interval_count === this.intervalCount);
  }

  get visitorsTotal () {
    return this.maxGuestAmount - (this.$auth.venueOwner?.remainingVisits || 0);
  }

  get guestCountPercent () {
    return 1 * (this.visitorsTotal / (this.maxGuestAmount / 100));
  }

  get displayPrice () {
    if (!this.selectedPrice) {
      return '';
    }
    if (this.selectedPrice.unit_amount === 0) {
      return this.product.name;
    }
    return `${new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: this.selectedPrice?.currency,
    }).format((this.selectedPrice.unit_amount / 100) / this.intervalCount)}`.replace(',00', ''); // when ,00 no display needed
  }

  get displayInterval () {
    if (!this.selectedPrice) {
      return '';
    }
    return 'pro Monat*';
  }

  get aboFeatures () {
    const features: IAboFeature[] = [];
    const product = this.product as unknown as { [key: string]: string };
    for (const featureKey in this.aboFeaturesKeys) {
      if (product[featureKey] && product[featureKey] === 'true') {
        features.push({
          name: this.aboFeaturesKeys[featureKey],
          hint: product[`${featureKey}_hint`],
        });
      }
    }
    return features;
  }

  get maxCountList () {
    const maxVenues = parseInt(this.product.stripe_metadata_package_scope_MaxVenues);
    const maxAreaAndQrCode = parseInt(this.product.stripe_metadata_package_scope_MaxArea);
    return [
      {
        name: 'Standorte',
        count: (maxVenues === -1) ? 'unbegrenzt' : maxVenues,
      },
      {
        name: 'Bereiche',
        count: (maxAreaAndQrCode === -1) ? 'unbegrenzt' : maxAreaAndQrCode,
      },
      {
        name: 'QR-Codes',
        count: (maxAreaAndQrCode === -1) ? 'unbegrenzt' : maxAreaAndQrCode,
      },
    ] as IAboMaxCountItem[];
  }

  get isTrialSubscription () {
    // check if trial sub
    return (this.$stripe.packageScope && this.$stripe.packageScope.Status === SubscriptionStatus.Trialing);
  }

  get leftTrialMessage () {
    if (isNaN(this.$stripe.packageScope.trialDaysLeft) || this.$stripe.packageScope.trialDaysLeft < 0) {
      return 'Ihr Plan';
    }
    // No full day left
    if (this.$stripe.packageScope.trialDurationLeft.days < 1) {
      return 'Testzeitraum endet in weniger als 24h!';
    }
    return `Testzeitraum endet in ${this.$stripe.packageScope.trialDaysLeft} Tag(en)`;
  }

  get isSubscribed (): boolean {
    if (!this.$stripe.packageScope) {
      return false;
    }
    if (this.$stripe.packageScope && this.product) {
      return this.product.id === this.$stripe.packageScope?.ProductId
        && this.selectedPrice.id === this.$stripe.packageScope?.Price.id;
    }
    return false;
  }

  get productTitleColor () {
    return {
      background: this.product.stripe_metadata_color
        ? this.product.stripe_metadata_color
        : 'transparent radial-gradient(closest-side at 50% 50%, #2080C5 0%, #115B8F 83%, #10588B 100%) 0% 0% no-repeat padding-box;',
    };
  }

  async getStripeSession () {
    this.loading = true;
    try {
      const stripeSessionUrl = await this.$stripe.startStripeSession({ returnUrl: window.location.href });
      window.location.assign(stripeSessionUrl);
    } catch (e) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Fehler beim Anzeigen Ihrer Bezahldaten',
        position: 'is-top',
        type: 'is-danger',
      });
    }
    this.loading = false;
  }

  async changeOrSubscribeProduct (): Promise<SubscribeProductResponse> {
    return await this.$stripe.subscribeProduct(
      {
        priceId: this.selectedPrice.id,
        sucessUrl: window.location.href,
        cancelUrl: window.location.href,
      });
  }

  async subscribeProduct (): Promise<SubscribeProductResponse> {
    if (!this.selectedPrice) {
      return;
    }
    try {
      this.loading = true;
      const subscriptionResult = await this.changeOrSubscribeProduct();
      if (subscriptionResult) {
        Toast.open(subscriptionResult.error.message);
      }
    } catch (e) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Das Produkt konnte nicht gebucht werden.',
        position: 'is-top',
        type: 'is-danger',
      });
    }
    this.loading = false;
  }
}
