


































































































import { Component, Inject, Vue } from 'vue-property-decorator';
import { VisitDownloader } from '@/helpers/visits-download-helper';
import { FirebaseFunctions, firebaseWrapper as fb } from '@/firebase-wrapper';
import { IVenue, IVisit } from '@einfachgast/shared';
import { httpsCallable } from 'firebase/functions';

@Component({
  name: 'VisitorsDownload',
})
export default class VisitorsDownload extends Vue {
  loading = false;
  selectedVenueId: string = null;
  // buefyfix/workaround to select first entry (all venues)
  // https://github.com/buefy/buefy/issues/1084
  selectedAreaId = '0';
  selectedFrom: Date = new Date();
  selectedTo: Date = null;

  @Inject('visitorsDownloadHelper')
  public downloadHelperConstructor: (visits: IVisit[], _venue: IVenue) => VisitDownloader;

  get venues () {
    return this.$auth.ownedVenues.filter(x => x.isUserVisit === true);
  }

  get selectableAreas () {
    if (!this.selectedVenueId) {
      return [];
    }
    const catchedVenue = this.venues.find(x => x.id === this.selectedVenueId);
    if (!catchedVenue) {
      return [];
    }
    return catchedVenue.areas;
  }

  mounted () {
    this.selectedFrom = new Date(new Date().setHours(0, 0, 0, 0));
    this.selectedVenueId = this.venues[0]?.id;
    const nowDateTo = new Date();
    nowDateTo.setHours(0, 0, 0, 0);
    nowDateTo.setMonth(nowDateTo.getMonth() + 1);
    this.selectedTo = nowDateTo;
  }

  async download (asExcel?: boolean) {
    this.loading = true;
    try {
      // download data as CSV
      const downloadVisitorDataFunc = httpsCallable<Record<string, unknown>, IVisit[]>(fb.functions, FirebaseFunctions.DownloadVisitorData);
      this.selectedFrom.setHours(0, 0, 0);
      this.selectedTo.setHours(23, 59, 59);
      const downloadVisitorData = await downloadVisitorDataFunc({
        venueId: this.selectedVenueId,
        areaId: (this.selectedAreaId !== '0') ? this.selectedAreaId : null,
        from: this.selectedFrom.getTime(),
        to: this.selectedTo.getTime(),
      });
      const selectedVenue = this.venues.find(x => x.id === this.selectedVenueId);
      if (!selectedVenue) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Der Standort wurde nicht gefunden. Versuchen Sie es später noch einmal.',
          position: 'is-top',
          type: 'is-danger',
        });
        return;
      }
      if (downloadVisitorData.data.length === 0) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Leider sind für diesen Zeitraum keine Einträge vorhanden!',
          position: 'is-top',
          type: 'is-info',
        });
        this.loading = false;
        return;
      }

      const downloader = this.downloadHelperConstructor(downloadVisitorData.data, selectedVenue);
      await downloader.download(asExcel ? 'excel' : 'csv');
      this.loading = false;
    } catch (e) {
      this.$buefy.toast.open({
        duration: 5000,
        message: `Fehler beim Herunterladen der Besucherdaten aufgetreten. Bitte versuchen Sie es später noch einmal. ${e}`,
        position: 'is-top',
        type: 'is-danger',
      });
      this.loading = false;
    }
  }
}
