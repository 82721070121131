import { store } from '@/store';
import { IAuthStore } from '@/store/interfaces/i-auth-store';
import { AuthModule } from '@/store/modules/auth';
import _Vue from 'vue'; // <-- notice the changed import
import { getModule } from 'vuex-module-decorators';

// export type PluginFunction<T> = (Vue: typeof _Vue, options?: T) => void;
export function AuthPlugin (Vue: typeof _Vue): void {
  Vue.prototype.$auth = getModule(AuthModule, store);
}

declare module 'vue/types/vue' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Vue {
    $auth: IAuthStore;
  }
}
