import { Transform, Expose, plainToClass } from 'class-transformer';
import { CheckInMode, CheckInModes, IArea, IPendingVisit, IVenue, IVenueStatsWrapper, ISafetyInstructions } from '@einfachgast/shared';
import { Area } from '@/models/area';
import { ArrayNotEmpty, IsEmail, IsInt, IsNotEmpty, IsUrl, MinLength, ValidateIf, ValidateNested } from 'class-validator';
import { ICovidOptions, IVenueStats } from '@/interfaces';
import { VenueStatsWrapper } from './venue-stats-wrapper';
import { getRandomId } from '@/helpers/qr-code-creation-helper';
import firebase from 'firebase/app';
import { CovidOptions } from './covid-options';
import { SafetyInstructions } from '@/models/venues/safety-instructions';
import { ICheckinConfirmationSettings } from '@einfachgast/shared/src/interfaces/venue/i-checkin-confirmation-settings';
import { CheckinConfirmationSettings } from './checkin-confirmation-settings';
import { Timestamp } from 'firebase/firestore';

export class Venue implements IVenue {
  static defaultWebFontColor = '#000000';
  static defaultWebBackgroundColor = '#4A90E2';
  static defaultFontColor = '#000000';
  static defaultBackgroundColor = '#ffffff';
  static defaultBorderColor = '#4A90E2';
  static defaultFooterFontColor = '#ffffff';
  static defaultFooterBackground = '#4A90E2';

  constructor (public statsWrapper: IVenueStatsWrapper = new VenueStatsWrapper()) {}

  @Expose()
  @Transform(value => value || '', { toClassOnly: true })
  public id: string;

  @Expose()
  @Transform(value => value || Venue.defaultWebFontColor, { toClassOnly: true })
  public webFontColor?: string;

  @Expose()
  @Transform(value => value || Venue.defaultWebBackgroundColor, { toClassOnly: true })
  public webBackgroundColor?: string;

  @Expose()
  @Transform(value => value || Venue.defaultFontColor, { toClassOnly: true })
  public fontColor?: string;

  @Expose()
  @Transform(value => value || Venue.defaultBackgroundColor, { toClassOnly: true })
  public backgroundColor?: string;

  @Expose()
  @Transform(value => value || Venue.defaultBorderColor, { toClassOnly: true })
  public borderColor?: string;

  @Expose()
  @Transform(value => value || Venue.defaultFooterFontColor, { toClassOnly: true })
  public footerFontColor?: string;

  @Expose()
  @Transform(value => value || Venue.defaultFooterBackground, { toClassOnly: true })
  public footerBackground?: string;

  @Expose()
  @Transform(value => value || '', { toClassOnly: true })
  public logoUrl?: string;

  @Expose()
  @Transform(value => value || '', { toClassOnly: true })
  public qrCodelogoUrl?: string;

  @Expose()
  @Transform(value => value || '', { toClassOnly: true })
  public name?: string;

  @Expose()
  @Transform(value => value ? plainToClass(CheckinConfirmationSettings, value) : plainToClass(CheckinConfirmationSettings, {}), { toClassOnly: true })
  public checkinConfirmationSettings?: ICheckinConfirmationSettings;

  @Expose()
  @Transform(value => {
    if (typeof value === 'string') {
      // workaround for old string field values
      return {
        de: value,
        en: 'Welcome!\n\nPlease enter your contact information and have a safe and pleasent stay!',
      };
    }
    return value || {
      de: 'Herzlich willkommen!\n\nBitte tragen Sie zur Anmeldung Ihre Kontaktdaten ein, vielen Dank!',
      en: 'Welcome!\n\nPlease enter your contact information and have a safe and pleasent stay!',
    };
  }, { toClassOnly: true })
  public introText?: {[key: string]: string};

  @Expose()
  @Transform(value => value || {
    de: 'Bitte wenden Sie sich an unser Personal, wenn Sie Fragen zum Checkin haben.',
    en: 'Please contact the staff if you have problems to check in.',
  }, { toClassOnly: true })
  public qrCodeText?: {[key: string]: string};

  @Expose()
  @Transform(value => value || {
    de: 'Bitte lassen Sie diese Seite noch geöffnet, um unserem Servicepersonal die Datenerfassung zu bestätigen.',
    en: 'Please keep this page open to indicate your successful registration to our staff.',
  }, { toClassOnly: true })
  public scanAppFinishText?: {[key: string]: string};

  public ownerId: string;

  @Expose()
  @Transform(value => value || false, { toClassOnly: true })
  public isDeleted: boolean;

  @Expose()
  @Transform(value => value || false, { toClassOnly: true })
  public isDeactivated: boolean;

  @Expose()
  public stats: IVenueStats;

  /**
   * This Lets you access The Visits and visitors history of the venue
   */
  get history (): IVenueStatsWrapper {
    return this.statsWrapper.wrap(this.stats);
  }

  @Expose()
  @Transform(value => value || '', { toClassOnly: true })
  @IsNotEmpty({ message: 'Bitte geben Sie einen Namen an', groups: ['basedata'], context: { group: 'address' } })
  public label: string;

  @Expose()
  @Transform(value => value || '', { toClassOnly: true })
  @MinLength(2, { message: (args) => `Mindestens ${args.constraints[0]} Zeichen`, groups: ['basedata'], context: { group: 'address' } })
  public street: string;

  @Expose()
  @Transform(value => value || '', { toClassOnly: true })
  @MinLength(4, { message: (args) => `Mindestens ${args.constraints[0]} Zeichen`, groups: ['basedata'], context: { group: 'address' } })
  public zipCode: string;

  @Expose()
  @Transform(value => value || '', { toClassOnly: true })
  @MinLength(3, { message: (args) => `Mindestens ${args.constraints[0]} Zeichen`, groups: ['basedata'], context: { group: 'address' } })
  public city: string;

  @Expose()
  @Transform(value => value?.map((x: IArea) =>
    plainToClass<Area, IArea>(Area, x))
    || [plainToClass<Area, IArea>(Area, { name: '', id: getRandomId(), isDeactivated: false, label: '', limit: null, isLimitActive: false })],
  { toClassOnly: true })
  @ArrayNotEmpty({ message: 'Bitte legen Sie mindestens einen Bereich an', groups: ['areas'], context: { group: 'areas' } })
  @ValidateNested({ each: true, groups: ['areas'], context: { group: 'areas' } })
  public areas: Area[];

  @Expose()
  @Transform(value => value || [], { toClassOnly: true })
  public pendingVisits: IPendingVisit[];

  @Expose()
  @Transform(value => value || '', { toClassOnly: true })
  @ValidateIf(o => o.facebookLink.length, { groups: ['additionalFeaturesLinks'], context: { group: 'venueTabAdditionalFeatures' } })
  @IsUrl({ protocols: ['http', 'https'] }, { message: 'Sie haben einen ungültigen Link eingegeben', groups: ['additionalFeaturesLinks'], context: { group: 'additionalFeature' } })
  public facebookLink: string;

  @Expose()
  @Transform(value => value || '', { toClassOnly: true })
  @ValidateIf(o => o.googleFeedbackLink.length, { groups: ['additionalFeaturesLinks'] })
  @IsUrl({ protocols: ['http', 'https'] }, { message: 'Sie haben einen ungültigen Link eingegeben', groups: ['additionalFeaturesLinks'], context: { group: 'additionalFeature' } })
  public googleFeedbackLink: string;

  @Expose()
  @Transform(value => value || '', { toClassOnly: true })
  @IsNotEmpty({ message: 'Sie haben einen Dokumentnamen angegeben aber kein Dokument hochgeladen', groups: ['additionalFeatures'], context: { group: 'additionalFeature' } })
  @ValidateIf(o => o.menuName.length, { groups: ['additionalFeatures'] })
  public menuUrl: string;

  @Expose()
  @Transform(value => value || '', { toClassOnly: true })
  @IsNotEmpty({ message: 'Sie haben einen Dokument hochgeladen aber aber kein Dokumentnamen angegeben', groups: ['additionalFeatures'], context: { group: 'additionalFeature' } })
  @ValidateIf(o => o.menuUrl.length, { groups: ['additionalFeatures'] })
  public menuName: string;

  @Expose()
  @Transform(value => value || null, { toClassOnly: true })
  public createdAt: Timestamp;

  @Expose()
  @Transform(value => value || 60, { toClassOnly: true })
  public duration: number;

  @Expose()
  @Transform(value => value || null, { toClassOnly: true })
  public maxStayDuration?: number;

  @Expose()
  @Transform(value => value || CheckInModes.CheckInCheckOut, { toClassOnly: true })
  @ValidateIf((o) => o.checkinMode === CheckInModes.CheckInCheckOut)
  public checkinMode: CheckInMode;

  @Expose()
  @Transform(value => value || null, { toClassOnly: true })
  public checkoutAllGuestsAt?: number;

  @Expose()
  @Transform(value => value || null, { toClassOnly: true })
  public checkoutAllGuestsAtUTC?: number;

  @Expose()
  @Transform(() => { const nowDate = new Date(); return nowDate.getTimezoneOffset() / 60; }, { toClassOnly: true })
  public utcOffset?: number;

  @Expose()
  @Transform((value: boolean, obj: IVenue) => value || (obj.limit !== null && obj.limit !== undefined), { toClassOnly: true })
  public guestLimitEnabled: boolean;

  @Expose()
  @Transform(value => value || null, { toClassOnly: true })
  @ValidateIf(o => o.guestLimitEnabled && o.checkinMode === CheckInModes.CheckInCheckOut, { groups: ['options'] })
  @IsInt()
  @IsNotEmpty({ message: 'Bitte geben Sie die maximale gleichzeitige Anzahl an Gästen für diesen Bereich an.', groups: ['options'], context: { group: 'options' } })
  public limit: number;

  @Expose()
  @Transform(value => value === false ? false : true, { toClassOnly: true })
  public isUserVisit: boolean;

  @Expose()
  @Transform(value => value || 1, { toClassOnly: true })
  public deleteUserVisits: number;

  @Expose()
  @Transform(value => value || '', { toClassOnly: true })
  @ValidateIf(o => o.isUserVisit === true, { groups: ['isUserVisit'], context: { group: 'isUserVisit' } })
  @IsEmail({}, { message: 'Bitte geben Sie eine gültige E-Mail-Adresse ein', groups: ['isUserVisit'], context: { group: 'datasecurity' } })
  @IsNotEmpty({ message: 'Bitte geben Sie die E-Mail des Datenschutzbeauftragten an.', groups: ['isUserVisit'], context: { group: 'datasecurity' } })
  public emailOfDataProtectionOfficer: string;

  @Expose()
  @Transform(value => value
    ? plainToClass<CovidOptions, ICovidOptions>(CovidOptions, value as ICovidOptions)
    : plainToClass<CovidOptions, ICovidOptions>(CovidOptions, {} as ICovidOptions),
  { toClassOnly: true })
  public covidOptions: ICovidOptions;

  @Expose()
  @Transform(value => value ? plainToClass<SafetyInstructions, ISafetyInstructions>(SafetyInstructions, value) : null, { toClassOnly: true })
  public safetyInstructions?: ISafetyInstructions;

  getArea (areaId: string) {
    return this.areas.find(area => area.id === areaId);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validateArea (areaId: string) {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isAreaActive (areaId: string) {
    return true;
  }

  beforeInsert () {
    // Make sure that only one area exists when creating.
    // And set the venue.name as default area.name
    if (this.areas.length === 1) {
      this.areas[0].name = this.label;
    }
  }
}
