































import { Component, Vue } from 'vue-property-decorator';
import Chart from 'chart.js';
import { DashboardChartHelper } from '@/helpers/dashboard-chart-helper';
// eslint-disable-next-line import/no-unassigned-import
import 'chartjs-plugin-colorschemes';

@Component({
  name: 'DashboardLineChart',
})
export default class DashboardLineChart extends Vue {
  groupedBy: 'day' | 'month' = 'day';
  groups = [{
    value: 'day',
    label: 'Tag',
  }, {
    value: 'month',
    label: 'Monat',
  }];

  chart: Chart = null;
  dates: Date[] = [];
  filteredData: Array<{label: string; dataset: {[key: string]: number}}> = [];
  dashboardChartHelper = new DashboardChartHelper();

  get venues () {
    return this.$auth.ownedVenues.filter(x => x.isDeactivated === false && x.isDeleted === false);
  }

  get datePickerType () {
    return (this.groupedBy === 'month') ? 'month' : null;
  }

  mounted () {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 30);
    this.dates.push(startDate, endDate);
    this.filterData();
    this.$watch(() => this.venues, () => {
      this.filterData(true);
    }, { deep: true });
  }

  changeGroupedBy () {
    if (this.groupedBy === 'month') {
      this.dates = [];
      const endDate = new Date();
      const startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 1);
      this.dates.push(startDate, endDate);
    }
    this.filterData();
  }

  filterData (withoutAnimation?: boolean) {
    // here filter alle Visitors by start and endDate
    this.filteredData = [];
    if (this.venues.length === 0) {
      return;
    }
    for (const venue of this.venues) {
      this.filteredData.push({
        label: venue.label,
        dataset: this.dashboardChartHelper
          .getFilteredVisitorByStartAndEndGrouped(venue, this.dates[0], this.dates[1], this.groupedBy),
      });
    }
    if (withoutAnimation && this.chart) {
      this.chart.data.labels = Object.keys(this.filteredData[0].dataset);
      this.chart.data.datasets = this.filteredData.map(x => ({
        label: x.label,
        data: Object.values(x.dataset),
      }));
      this.chart.update({ duration: 0 });
    } else {
      this.initVisitorsLineChart();
    }
  }

  initVisitorsLineChart () {
    const lineChartEl = (document.getElementById('visitorLineChart') as HTMLCanvasElement);
    if (!lineChartEl) {
      return;
    }
    const ctx = lineChartEl.getContext('2d');
    if (this.chart) {
      this.chart.destroy();
    }
    this.chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: Object.keys(this.filteredData[0].dataset),
        datasets: this.filteredData.map(x => ({
          label: x.label,
          data: Object.values(x.dataset),
        })),
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
            },
          }],
        },
        plugins: {
          colorschemes: {
            scheme: 'brewer.Paired12',
          },
        },
      },
    });
  }
}
