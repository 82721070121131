


























import { CheckinConfirmationSettingType } from '@einfachgast/shared';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'VenuesOptionsConfirmationModeItem',
})
export default class VenuesOptionsConfirmationModeItem extends Vue {
  @Prop()
  value: CheckinConfirmationSettingType;

  @Prop()
  type: CheckinConfirmationSettingType;

  @Prop()
  name: string;

  @Prop()
  label: string;

  get isActive () {
    return this.value === this.type;
  }

  setIsActive () {
    this.$emit('input', this.type);
  }
}
