




















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IVenue, CheckInModes, CheckInMode } from '@einfachgast/shared';

@Component({
  name: 'VenuesOptionsModusItem',
})
export default class VenuesOptionsModusItem extends Vue {
  @Prop()
  venue: IVenue;

  @Prop()
  modus: CheckInMode;

  @Prop()
  label: string;

  get venueCheckInModes () {
    return CheckInModes;
  }

  get isActive () {
    return this.venue.checkinMode === this.modus;
  }

  get modes () {
    return [CheckInModes.CheckInCheckOut, CheckInModes.Standard];
  }

  setCheckinMode () {
    this.venue.checkinMode = this.modus;
  }
}
