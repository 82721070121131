import { CustomFields } from '@/features/custom-fields/boot';
import { DiContainerFeatureFactory } from './di-container-feature-factory';
import { VueRouter } from 'vue-router/types/router';
import { AuthModule } from '@/store/modules/auth';
import VueI18n from 'vue-i18n';
import { SafetyInstructions } from '@/features/safety-instructions/boot';

export class FeatureInstall {
  public static install (router: VueRouter, authStore: AuthModule, i18n: VueI18n) {
    const diFactory = new DiContainerFeatureFactory(
      [
        // INSTALL FEATURES - not remove - helper for plop
        CustomFields,
        SafetyInstructions,
      ],
      router, authStore, i18n,
    );
    // start features
    diFactory.start();
    return diFactory;
  }
}
