import { IsEmail, IsNotEmpty, MinLength } from 'class-validator';
import { DataRequestStatus, IDataRequest } from '@einfachgast/shared';
import { DateIsAfter } from '@/decorators/date-is-after';
import { Expose } from 'class-transformer';
import { TransformDate } from '@/decorators/transform-date-decorator';

export class DataRequest implements IDataRequest {
  public id: string;
  public ownerId: string;
  status: DataRequestStatus;

  @TransformDate()
  public createdAt: Date;

  @Expose()
  @IsNotEmpty({ message: 'Bitte geben Sie eine E-Mail-Adresse an.' })
  @IsEmail(undefined, { message: 'Bitte geben Sie eine gültige E-Mail-Adresse an.' })
  public healthDepartmentEmail = '';

  @Expose()
  @IsNotEmpty({ message: 'Bitte wählen Sie ein Startdatum aus.' })
  @TransformDate()
  public fromDate: Date;

  @Expose()
  @IsNotEmpty({ message: 'Bitte wählen Sie ein Enddatum aus.' })
  @DateIsAfter('fromDate', { message: 'Enddatum muss größer Startdatum sein.' })
  @TransformDate()
  public toDate: Date;

  @Expose()
  @MinLength(2, { message: 'Bitte geben Sie einen Standort an.' })
  public venueId: string;

  @MinLength(5, { message: 'Bitte geben Sie einen Ansprechpartner an.' })
  public healthDepartmentContact: string;

  @MinLength(8, { message: 'Bitte geben Sie eine Telefonnummer des Anrpechpartners an.' })
  public healthDepartmentPhone: string;

  @MinLength(5, { message: 'Bitte geben Sie das Gesundheitsamt an.' })
  public healthDepartmentName: string;

  public annotation?: string;
}
