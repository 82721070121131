














import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';

@Component({
  name: 'VenueInputTooltipWrapper',
})
export default class VenueInputTooltipWrapper extends Vue {
    @Prop({ default: false })
    disabled: boolean;

    @Prop()
    tooltipText: string;

    @Prop({ default: 'is-danger' })
    tooltipType: string;
}
