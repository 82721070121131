






















































































import { Component, Prop } from 'vue-property-decorator';
import LoginHeaderToggle from '@/components/login/login-header-toggle.vue';
import LoggedOutWrapper from '@/components/logged-out-wrapper.vue';
import { mixins } from 'vue-class-component';
import { FormFunctions } from '@/mixins/form-functions';
import { isEmpty } from 'class-validator';
import { RouteNames } from '@/router';
import { FirebaseFunctions, firebaseWrapper as fb } from '@/firebase-wrapper';
import { httpsCallable } from 'firebase/functions';
import { FirebaseError } from 'firebase/app';


@Component({
  name: 'SignInComplete',
  components: {
    LoggedOutWrapper,
    LoginHeaderToggle,
  },
})
export default class SignInComplete extends mixins(FormFunctions) {
  error = '';
  loading = true;
  done = false;
  passwordConfirmation = '';
  password = '';

  @Prop()
  token: string;

  get isPasswordConfirmationMatching () {
    return this.password === this.passwordConfirmation;
  }

  async beforeCreate () {
    if (this.$auth.user) {
      await this.$router.push({ path: '/' });
      return;
    }
  }

  async mounted () {
    try {
      await httpsCallable(fb.functions, FirebaseFunctions.VerifySignInToken)({ token: this.token, password: this.password });
    } catch (e) {
      this.error = (e as FirebaseError).message;
    }
    this.loading = false;
  }

  updateMissmatchMessage () {
    this.clearErrors('passwordConfirmation');
    if (!this.passwordConfirmation) {
      return;
    }
    if (!this.isPasswordConfirmationMatching) {
      this.addError(this.$t('passwordMissmatchError').toString(), 'passwordConfirmation');
    } else {
      this.clearErrors('passwordConfirmation');
    }
  }

  validatePasswords () {
    if (isEmpty(this.password)) {
      this.addError(this.$t('emptyPasswordError').toString(),'password');
    }

    if (!isEmpty(this.password) && this.password.length < 6) {
      this.addError(this.$t('tooShortPasswordMessage').toString(), 'password');
    }

    if (isEmpty(this.passwordConfirmation)) {
      this.addError(this.$t('passwordConfirmationEmptyMessage').toString(), 'passwordConfirmation');
    }

    return !this.hasErrors;
  }

  pushToLogin () {
    void this.$router.replace({ name: RouteNames.Login });
  }

  async setPassword () {
    this.clearErrors('password');
    this.clearErrors('passwordConfirmation');

    if (!this.validatePasswords()) {
      this.$buefy.toast.open({
        message: this.$t('invalidPasswordMessage').toString(),
        type: 'is-danger',
      });
      return;
    }
    this.loading = true;
    try {
      await httpsCallable(fb.functions, FirebaseFunctions.CompleteSignIn)({ token: this.token, password: this.password });
      this.done = true;
      setTimeout(() => {
        this.pushToLogin();
      }, 7000);
    } catch (e) {
      this.$buefy.toast.open({
        duration: 5000,
        message: (e as Error).message,
        position: 'is-top',
        type: 'is-danger',
      });
    }
    this.loading = false;
  }
}
