




































































import { Component, Prop } from 'vue-property-decorator';
import { FormFunctions } from '@/mixins/form-functions';
import { mixins } from 'vue-class-component';
import { IVenue } from '@einfachgast/shared';
import VenueInputTooltipWrapper from '@/components/venues/venue-input-tooltip-wrapper.vue';
import { isFileSizeExceeded, MAX_FILE_SIZE_IN_MB } from '@/helpers/file-helper';
import VenuesAdditionalFeaturesLinks from '@/components/venues/venues-additional-features-links.vue';

@Component({
  name: 'VenuesAdditionalFeatures',
  components: {
    VenueInputTooltipWrapper,
    VenuesAdditionalFeaturesLinks,
  },
})
export default class VenuesAdditionalFeatures extends mixins(FormFunctions) {
  menuFile: File = null;

  @Prop()
  cssClasses: string;

  @Prop()
  venue: IVenue;

  @Prop({ default: true })
  showHeadline: boolean;

  mounted () {
    // on save the venue (& upload the file) the menuUrl will change
    // and than u have to reset the droppedFile
    // otherwise the name not display correctly
    this.$watch(() => this.venue.menuUrl, () => {
      this.menuFile = null;
    });
  }

  get TooltipText () {
    return 'In Ihrem aktuellen Plan sind keine Zusatzfunktionen enthalten. Vorhandene Einstellungen und Dokumente werden wiederhergestellt, sobald Sie in einen entsprechenden Plan wechseln.';
  }

  get fileNameMenu () {
    if (!this.menuFile) {
      return this.venue.menuUrl?.substring(this.venue.menuUrl?.lastIndexOf('/') + 1);
    }
    return this.menuFile.name;
  }

  get hasMenuInPackage () {
    return this.$stripe.packageScope.hasMenu;
  }

  async onDropFile (droppedFile: File) {
    this.clearErrors('menuUrl');
    if (isFileSizeExceeded(droppedFile)) {
      this.$buefy.toast.open({
        duration: 5000,
        message: `Maximale Dateigröße von ${MAX_FILE_SIZE_IN_MB}MB überschritten.`,
        position: 'is-top',
        type: 'is-danger',
      });
      this.menuFile = null;
    } else {
      this.$emit('onDropMenuFile', droppedFile);
      try {
        await this.$venues.uploadFile({
          venue: this.venue,
          origVenue: this.venue,
          oldLogoUrl: '',
          image: droppedFile,
          isQrCodeImage: false,
        });
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Fehler beim Dateiupload.',
          position: 'is-top',
          type: 'is-danger',
        });
      }
      this.venue.menuUrl = droppedFile.name;
    }
  }

  removeFile () {
    this.menuFile = null;
    this.venue.menuUrl = '';
    this.venue.menuName = '';
    this.$emit('onDropMenuFile', null);
  }

  clearMenuErrors () {
    this.clearErrors('menuName');
    this.clearErrors('menuUrl');
  }
}
