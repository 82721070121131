













































import Component, { mixins } from 'vue-class-component';
import { IChildUser, ChildUserStatus } from '@einfachgast/shared';
import { FormFunctions } from '@/mixins/form-functions';
import { FirebaseError } from 'firebase/app';
import { ChildUser } from '@/models/child-user';
import { plainToClass } from 'class-transformer';
import { FirebaseFunctions, firebaseWrapper as fb } from '@/firebase-wrapper';
import { httpsCallable } from 'firebase/functions';

@Component({
  name: 'ChildUserCreateForm',
})
export default class ChildUserCreateForm extends mixins(FormFunctions) {
  loading = false;

  childUser: IChildUser = plainToClass<IChildUser, IChildUser>(
    ChildUser,
    {
      email: '',
      parentUserId: '',
      status: ChildUserStatus.Pending,
    },
  )

  async invite () {
    if (!await this.validate(this.childUser)){
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Bitte prüfen Sie Ihre Eingaben',
        position: 'is-top',
        type: 'is-danger',
      });
      return;
    }
    this.loading = true;
    try {
      await httpsCallable(fb.functions, FirebaseFunctions.CreateChildUser)(this.childUser);
      this.$emit('close');
    } catch (e: unknown) {
      if ((e as FirebaseError).code === 'already-exists') {
        (e as FirebaseError).message = 'Es existiert bereits ein Benutzer mit dieser Email-Adresse.';
      }
      this.$buefy.toast.open({
        duration: 5000,
        message: (e as Error).message,
        position: 'is-top',
        type: 'is-danger',
      });
    }
    this.loading = false;
  }

}
