








































import Component from 'vue-class-component';
import Vue from 'vue';
import { ChildUserStatus, IChildUser } from '@einfachgast/shared';
import { DBCollections, FirebaseFunctions, firebaseWrapper as fb } from '@/firebase-wrapper';
import { Prop } from 'vue-property-decorator';
import { httpsCallable } from 'firebase/functions';
import { deleteDoc, doc } from 'firebase/firestore';

@Component({
  name: 'ChildUsersListItem',
})
export default class ChildUsersListItem extends Vue {
  @Prop()
  user!: IChildUser;

  get isPending () {
    return this.user.status === ChildUserStatus.Pending;
  }

  get statusString () {
    if (this.user.status === ChildUserStatus.Active) {
      return 'Aktiv';
    }
    return 'Deaktiviert';
  }

  get isActive () {
    return this.user.status === ChildUserStatus.Active;
  }

  get isDisabled () {
    return this.user.status === ChildUserStatus.Disabled;
  }

  get toggleTooltipLabel () {
    if (this.isActive) {
      return 'Benutzer deaktivieren';
    }
    return 'Benutzer aktivieren';
  }

  get toggleTooltipType () {
    if (this.isActive) {
      return 'is-primary';
    }
    return 'is-success';
  }

  deleteUser () {
    this.$buefy.dialog.confirm({
      message: `Möchten Sie den Benutzer "${this.user.email}" wirklich löschen?`,
      cancelText: 'abbrechen',
      onConfirm: async () => {
        try {
          await deleteDoc(doc(fb.db, `${DBCollections.ChildUsers}/${this.user.id}`));
        } catch (e) {
          this.$buefy.toast.open({
            duration: 5000,
            message: (e as Error).message,
            position: 'is-top',
            type: 'is-danger',
          });
        }
      },
    });
  }

  async disableUser () {
    try {
      await httpsCallable(fb.functions, FirebaseFunctions.DisableChildUser)({ childUserId: this.user.id });
    } catch (e) {
      this.$buefy.toast.open({
        duration: 5000,
        message: (e as Error).message,
        position: 'is-top',
        type: 'is-danger',
      });
    }
  }

  async enableUser () {
    try {
      await httpsCallable(fb.functions, FirebaseFunctions.EnableChildUser)({ childUserId: this.user.id });
    } catch (e) {
      this.$buefy.toast.open({
        duration: 5000,
        message: (e as Error).message,
        position: 'is-top',
        type: 'is-danger',
      });
    }
  }

  async toggleUserStatus (active: boolean) {
    try {
      if (active) {
        await this.enableUser();
      } else {
        await this.disableUser();
      }
    } catch (e) {
      this.$buefy.toast.open({
        duration: 5000,
        message: (e as Error).message,
        position: 'is-top',
        type: 'is-danger',
      });
    }
  }
}
