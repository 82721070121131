/* eslint-disable no-duplicate-imports */
// eslint-disable-next-line import/no-unassigned-import
import 'reflect-metadata';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { store } from '@/store';
import User from 'firebase';
import { AuthModule } from './store/modules/auth';
import { AuthPlugin } from './plugins/auth-plugin';
import { getModule } from 'vuex-module-decorators';
import { firebaseWrapper } from './firebase-wrapper';
import FormDivider from '@/components/form-divider.vue';
import { StripePlugin } from './plugins/stripe-plugin';
import * as Sentry from '@sentry/vue';
import VueI18n from 'vue-i18n';

// eslint-disable-next-line import/no-unassigned-import
import '@mdi/font/css/materialdesignicons.css';
// eslint-disable-next-line import/no-unassigned-import
import '@/assets/styles.scss';

import Menu from 'buefy';
import MenuList from 'buefy';
import MenuItem from 'buefy';
import Message from 'buefy';
import Sidebar from 'buefy';
import Field from 'buefy';
import Input from 'buefy';
import Select from 'buefy';
import Button from 'buefy';
import Loading from 'buefy';
import Skeleton from 'buefy';
import Tabs from 'buefy';
import TabItem from 'buefy';
import Upload from 'buefy';
import Icon from 'buefy';
import Steps from 'buefy';
import StepItem from 'buefy';
import CheckBox from 'buefy';
import TableColumn from 'buefy';
import Table from 'buefy';
import Toast from 'buefy';
import { Integrations } from '@sentry/tracing';
import { VueEnvHelper } from './helpers/vue-envvar-helper';
import { VenuesPlugin } from './plugins/venues-plugin';
import { InteractionPlugin } from './plugins/interaction-plugin';
import * as deTranslation from './translation/de';
import * as enTranslation from './translation/en';
import { VueProvideCompatibleDiContainerFactory } from '@/dependency-injection/vue-provide-compatible-di-container-factory';
import { FeatureInstall } from '@/dependency-injection/feature-install';
import { StripeModule } from './store/modules/stripe';
import { onAuthStateChanged } from 'firebase/auth';

const stripe = getModule(StripeModule, store);
class AppBootstrap {
  constructor() {
    this.loadVueApp();
  }


  private loadVueApp() {
    // Plugins
    Vue.use(AuthPlugin);
    Vue.use(StripePlugin);
    Vue.use(VenuesPlugin);
    Vue.use(InteractionPlugin);
    Vue.use(Menu);
    Vue.use(MenuList);
    Vue.use(MenuItem);
    Vue.use(Message);
    Vue.use(Sidebar);
    Vue.use(Field);
    Vue.use(Input);
    Vue.use(Select);
    Vue.use(Button);
    Vue.use(Loading);
    Vue.use(Skeleton);
    Vue.use(Tabs);
    Vue.use(TabItem);
    Vue.use(Upload);
    Vue.use(Icon);
    Vue.use(Steps);
    Vue.use(StepItem);
    Vue.use(CheckBox);
    Vue.use(TableColumn);
    Vue.use(Table);
    Vue.use(Toast);
    Vue.use(VueI18n);
    // Components
    Vue.component('FormDivider', FormDivider);

    Vue.config.productionTip = false;

    const envHelper = new VueEnvHelper();
    const sentryDsn = envHelper.get('SENTRY_DSN');
    if (sentryDsn) {
      Sentry.init({
        Vue,
        dsn: envHelper.get('SENTRY_DSN'),
        autoSessionTracking: true,
        environment: (envHelper.get('ENVIRONMENT') || process.env.NODE_ENV).toLowerCase(),
        integrations: [
          new Integrations.BrowserTracing(),
        ],
        release: envHelper.get('GIT_SHA'),
        tracesSampleRate: 1.0,
        logErrors: true,
      });
    }

    const authStore = getModule(AuthModule, store);

    const i18n = new VueI18n({
      locale: 'de',
      messages: {
        de: deTranslation.default,
        en: enTranslation.default,
      },
    });

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    void (() => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      void onAuthStateChanged(firebaseWrapper.auth, () => {
        try {
          void authStore.initApp().then(async () => {
            Sentry.setUser({ id: authStore.user?.uid });
            if (!authStore.permissions.isChildUser) {
              await stripe.getPackageScope();
            }

            // install features
            const diFactory = FeatureInstall.install(router, authStore, i18n);

            new Vue({
              i18n,
              router,
              provide: new VueProvideCompatibleDiContainerFactory({}, diFactory.container).create(),
              render: h => h(App),
            }).$mount('#app');
          });
        } catch (e) {
          console.log(e);
          alert(e);
        }
      });
    })();
  }
}
// eslint-disable-next-line no-new
new AppBootstrap();
