










import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IVenue } from '@einfachgast/shared';
import { ValidationError } from 'class-validator';

@Component({
  name: 'VenueEditSubSteps',
})
export default class VenueEditSubSteps extends Vue {
  @Prop()
  venue: IVenue;

  @Prop()
  activeTab: string;

  @Prop({ default: (): ValidationError[] => [] })
  errors: ValidationError[];

  changeTab (tabName: string) {
    void this.$router.push({ name: this.$route.name, params: { activeTab: tabName }});
  }
}
