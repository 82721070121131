

























import Component from 'vue-class-component';
import Vue from 'vue';
import { Product } from '@/models/stripe/product';
import ProductsView from '@/components/stripe/products-view.vue';
import Subscription from '@/models/stripe/subscription';
import { IVenueOwner } from '@einfachgast/shared';
import { RouteNames } from '@/router';

@Component({
  name: 'Abonnement',
  components: {
    ProductsView,
  },
})
export default class Abonnement extends Vue {
    loading = false;
    products: Product[] = [];
    subscriptionInfos: Subscription = new Subscription();

    // can only display this button, when customer is not in trial
    get canShowInvoicePreviewBtn () {
      return this.$stripe.packageScope && !this.$stripe.packageScope.isTrial;
    }

    get myDataTarget () {
      return { name: RouteNames.MyData };
    }

    get venueOwner () {
      return this.$auth.venueOwner || { } as IVenueOwner;
    }

    async mounted () {
      await this.loadStripeData();
      this.showHint();
    }

    async getStripeSession () {
      this.loading = true;
      try {
        const stripeSessionUrl = await this.$stripe.startStripeSession({ returnUrl: window.location.href.split('?')[0] });
        window.location.assign(stripeSessionUrl);
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Fehler beim Anzeigen Ihrer Bezahldaten',
          position: 'is-top',
          type: 'is-danger',
        });
      }
      this.loading = false;
    }

    showHint () {
      if (this.$route.query.success) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Vielen Dank für Ihre Buchung und vielen Dank, dass Sie unseren Service nutzen!',
          position: 'is-top',
          type: 'is-success',
        });
      }
      if (this.$route.query.cancel) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Es wurde kein Paket gebucht, der Vorgang wurde abgebrochen. Sollten Sie Unterstützung benötigen, hilft Ihnen unser <a href= "https://einfachgast.de/support" >Support</a> gerne weiter.',
          position: 'is-top',
          type: 'is-danger',
        });
      }
    }

    async loadStripeData () {
      this.loading = true;
      try {
        this.products = await this.$stripe.getAvailableProducts();
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Fehler beim Laden der verfügbaren Produkte',
          position: 'is-top',
          type: 'is-danger',
        });
      }
      this.loading = false;
    }
}
