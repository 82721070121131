import { ISafetyInstructionItem } from '@einfachgast/shared';
import { Expose, Transform } from 'class-transformer';

export class SafetyInstructionItem implements ISafetyInstructionItem {
  @Expose()
  @Transform(value => value || { de: '', en: '' }, { toClassOnly: true })
  public noteText: {[key: string]: string};

  @Expose()
  @Transform(value => value || [], { toClassOnly: true })
  public iconUrls: string[];

  @Expose()
  @Transform(value => value || 0, { toClassOnly: true })
  public position: number;
}
