import { Roles } from '@/models/venues/roles';
import { Features } from '@einfachgast/shared';
export class PermissionResolver {
  private _roles: Roles[] = [];
  private _features: Features[] = [];
  private _parentUserId: string | null = null;

  public setRolesByTokenClaims (tokenClaims: {[key: string]: any}) {
    this._roles = [];
    
    if (tokenClaims?.isAdmin) {
      this._roles.push(Roles.isAdmin);
    }

    if (tokenClaims?.isVenueOwner) {
      this._roles.push(Roles.VenueOwner);
    }

    if (tokenClaims?.isChildUser) {
      this._roles.push(Roles.ChildUser);
    }
    this._parentUserId = tokenClaims?.parentUserId;
  }

  public has (role: Roles) {
    return this._roles.indexOf(role) !== -1;
  }

  public hasFeature (featureName: Features) {
    return this._features.indexOf(featureName) !== -1;
  }

  public get isChildUser () {
    return this.has(Roles.ChildUser);
  }

  public get parentUserId () {
    return this._parentUserId;
  }
}
