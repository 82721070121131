



import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'VenueTableRowPrecent',
})
export default class VenueTableRowPrecent extends Vue {
  @Prop()
  percent: number;

  get signedpercent () {
    if (this.percent > 0) {
      return `+${this.percent}`;
    } else if (this.percent < 0) {
      return `${this.percent}`;
    }
    return this.percent.toString();
  }

  get colorType () {
    if (this.percent > 0) {
      return 'has-text-success';
    } else if (this.percent < 0) {
      return 'has-text-danger';
    }
    return 'has-text-grey-light';
  }
}
