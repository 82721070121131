import { IVenue, IVisit } from '@einfachgast/shared';
import humanizeDuration from 'humanize-duration';
import * as XLSX from 'xlsx';
import { parseAsync } from 'json2csv';
import { DataExportScope } from '@/enums/data-export-scope';
import { injectable } from 'inversify';

type NarrowVisit = Pick<IVisit, 'email' | 'firstname' | 'lastname' | 'zipcode' | 'city' | 'street' | 'phone'>;

export interface IAdvancedFieldInfo<T> {
  label: string;
  value: string | ((item: T, scope: DataExportScope, venue?: IVenue) => any);
  venueId?: string;
  includeInCsvExport?: boolean;
}

declare type transformedVisit = { [key: string]: any };

const forbiddenCharacters = ['=', '+', '-', '@'];

const sanitizeString = (stringToSanitize: string): string => {
  if (!stringToSanitize) {
    return '';
  }
  if (typeof stringToSanitize !== 'string') {
    return stringToSanitize;
  }
  const isInjected = forbiddenCharacters.includes(stringToSanitize.charAt(0));
  if (!isInjected) {
    return stringToSanitize;
  }
  return sanitizeString(stringToSanitize.slice(1));
};

type RawFirebaseTimestamp = { _seconds: number; _nanoseconds: number };

const formatDate = (rawTimestamp: RawFirebaseTimestamp, scope: DataExportScope) => {
  if (!rawTimestamp) {
    return;
  }
  const newDate = new Date(rawTimestamp._seconds * 1000);

  if (scope === DataExportScope.Excel) {
    return newDate;
  }

  return newDate.toLocaleString();
};

@injectable()
export class VisitDownloader {
  constructor (
    private _visits: IVisit[],
    private _venue: IVenue,
    public injectedFields: IAdvancedFieldInfo<IVisit>[]) {}

  get data () {
    return this._visits;
  }

  private staticKeys: IAdvancedFieldInfo<IVisit>[] = [
    {
      value: 'email',
      label: 'Email',
    },
    {
      value: 'firstname',
      label: 'Vorname',
    },
    {
      value: 'lastname',
      label: 'Nachname',
    },
    {
      value: 'zipcode',
      label: 'PLZ',
    },
    {
      value: 'city',
      label: 'Stadt',
    },
    {
      value: 'street',
      label: 'Straße',
    },
    {
      value: 'phone',
      label: 'Telefon',
    },
    {
      label: 'Bereich',
      value: (row, scope, venue) => venue.areas.find(x => x.id === row.areaId)?.name,
    },
    {
      label: 'Begleiter',
      value: (row, scope, venue) => row.companions.map(companion => {
        // Default is name phone
        if (!venue.companionFields || venue.companionFields.length <= 0) {
          return sanitizeString(`${companion.name}|${companion.phone}`);
        }
        // handle custom companion form
        return venue.companionFields
          .filter(x => x.includeInCsvExport)
          .map(field => sanitizeString(`${companion[field.name]}`))
          .join(' | ');
      }).join(' || '),
    },
    {
      label: 'Start',
      value: (row, scope) => formatDate(row.start as unknown as RawFirebaseTimestamp, scope),
    },
    {
      label: 'Ende',
      value: (row, scope) => formatDate(row.end as unknown as RawFirebaseTimestamp, scope),
    },
    {
      label: 'Dauer',
      value: (row): string => {
        if (!row.end) {
          return null;
        } else {
          const diffDurationTime = (row.end as unknown as RawFirebaseTimestamp)._seconds - (row.start as unknown as RawFirebaseTimestamp)._seconds;
          return humanizeDuration(diffDurationTime * 1000, { language: 'de' });
        }
      },
    },
  ];

  public async download(type: string) {
    switch (type) {
      case 'excel':
        return this.downloadAsExcel();
      case 'csv':
        return this.downloadAsCsv();
    }
    throw new Error('Unsupported download type');
  }

  /**
   * @Todo prevent excel injections
   * @param visitData IVisit[] the visits
   * @param filename string the desired filename
   */
  public async downloadAsCsv () {
    if (!this._venue) {
      throw new Error('No venue for download');
    }
    const csvData = await parseAsync<transformedVisit>(this.getTransformedData(DataExportScope.Csv));
    this.triggerCsvDownload(csvData);
  }

  public downloadAsExcel () {
    if (!this._venue) {
      throw new Error('No venue for download');
    }
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(this.getTransformedData(DataExportScope.Excel), { cellDates: true, dateNF: 'DD.MM.YYYY HH:mm:ss' });

    XLSX.utils.book_append_sheet(wb, ws);

    XLSX.writeFile(wb, `${this.escapeFilename(this._venue.name)}.xlsx`, { cellDates: true });
  }

  private transformRow (visit: IVisit, scope = DataExportScope.Excel) {
    const result: { [key: string]: string | Date } = {};
    let exportFields = this.staticKeys;
    if (this.injectedFields) {
      exportFields = exportFields.concat(this.injectedFields.filter((i) => i.venueId === this._venue.id));
    }
    exportFields.forEach(fieldDefinition => {
      if (typeof fieldDefinition.value === 'function') {
        result[fieldDefinition.label] = fieldDefinition.value(visit, scope, this._venue);
      } else {
        result[fieldDefinition.label] = visit[fieldDefinition.value as keyof NarrowVisit];
      }
    });
    return result;
  }

  private escapeFilename(filename: string) : string {
    return filename.replace(/[/\\:*?"<>]/g, '-');
  }

  private getTransformedData (scope: DataExportScope) {
    return this.data.map(x => this.transformRow(x, scope));
  }

  private triggerCsvDownload (csvData: string) {
    const blob = new Blob(
      [csvData],
      {
        type: 'data:text/csv;charset=utf-8',
      },
    );
    const linkElement = document.createElement('a');
    const url = URL.createObjectURL(blob);
    linkElement.setAttribute('href', url);
    linkElement.setAttribute('download', `${this.escapeFilename(this._venue.name)}.csv`);
    const clickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: false,
    });

    linkElement.dispatchEvent(clickEvent);
  }

  /**
   * customField inputs are stored as a nested object. this functions unwraps and escapes them
   * @param visit the bare visit
   * @returns an Object that has all customFields from the visits on its top level
   */
  flattenCustomFieldData (visit: IVisit): { [key: string]: string } {
    const result: { [key: string]: string } = {};
    if (!visit.customFields) {
      return result;
    }

    Object.keys(visit.customFields).forEach(key => {
      result[key] = sanitizeString(visit.customFields[key]);
    });

    return result;
  }
}
