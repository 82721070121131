




















































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IVenue } from '@einfachgast/shared';
import { Venue } from '@/models/venues/venue';
import { classToClass, plainToClass } from 'class-transformer';
import { IQrCode } from '@/interfaces/i-qr-code';
import VenueAdditionalFeaturesStep from '@/components/venues/venue-steps/venue-additional-features-step.vue';
import VenueAreasStep from '@/components/venues/venue-steps/venue-areas-step.vue';
import VenueQrCodeStep from '@/components/venues/venue-steps/venue-qr-code-step.vue';
import VenueWebFormStep from '@/components/venues/venue-steps/venue-web-form-step.vue';
import VenuesOptionsStep from '@/components/venues/venue-steps/venue-options-step.vue';
import { RouteNames } from '@/router';
import { isEqual } from 'lodash';
import { Route } from 'vue-router';
import { validate, ValidationError } from 'class-validator';

Component.registerHooks([
  'beforeRouteLeave',
]);

@Component({
  name: 'VenueEdit',
  components: {
    VenueAdditionalFeaturesStep,
    VenueAreasStep,
    VenueQrCodeStep,
    VenueWebFormStep,
    VenuesOptionsStep,
  },
})
export default class VenueEdit extends Vue {
  loading = false;
  qrCode: IQrCode = null;
  droppedFile: File = null;
  droppedFileQrCode: File = null;
  droppedMenuFile: File = null;
  venue: IVenue = classToClass(new Venue());
  deepVenueCopy: IVenue = null;
  activeStep: string = RouteNames.VenueEditTabAddress;
  errors: ValidationError[] = [];
  isDirty = false;
  unwatchVenueWatch: () => void = null;

  @Prop()
  venueId: string;

  get childRoutes () {
    return this.$router.options.routes.find(x => x.name === RouteNames.Management).children.find(routeItem => routeItem.name === this.$route.matched[1].name)?.children.sort((a, b) => {
      return (a.meta?.order || 0) - (b.meta?.order || 0);
    });
  }

  get webDroppedFile () {
    return (this.droppedFile && this.droppedFile.name !== this.venue.logoUrl)
      ? this.droppedFile
      : null;
  }

  get qrCodeDroppedFile () {
    return (this.droppedFileQrCode && this.droppedFileQrCode.name !== this.venue.qrCodelogoUrl)
      ? this.droppedFileQrCode
      : null;
  }

  get excludedDeepCopy () {
    if (!this.deepVenueCopy) {
      return null;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { pendingVisits, ...excludedDeepCopy} = this.deepVenueCopy || {};
    return excludedDeepCopy;
  }

  // Return true when this.venue contains unsaved changes
  get venueIsDirty () {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { pendingVisits, ...excludedEdit} = this.venue || {};
    // because => when u edit a venue and at this time comes a checkin in this venue
    // the snapshot trigger because of updated pendingVisit and the venue thinks he is updated. :D
    return this.isDirty || !isEqual(this.excludedDeepCopy, JSON.parse(JSON.stringify(excludedEdit)));
  }

  created () {
    this.activeStep = this.$route.name;
    this.loading = true;
    this.loadVenue();
    this.loading = false;
  }

  mounted () {
    // have to set this here, because later (onSave) u must check agaibst original/old-data
    // e.x. to deltet old uploaded file
    this.deepVenueCopy = JSON.parse(JSON.stringify(this.venue));
    this.initVenueWatcher();
  }

  async changeTab (route: RouteNames) {
    await this.$router.push({ name: route });
  }

  resetIsDirty () {
    this.isDirty = false;
    this.deepVenueCopy = JSON.parse(JSON.stringify(this.venue));
  }

  hasError (routePath: string) {
    return JSON.stringify(this.errors).indexOf(`"group":"${routePath}"`) !== -1;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  beforeRouteLeave (to: Route, from: Route, next: Function) {
    if (this.isDirty) {
      this.$buefy.dialog.confirm({
        message: 'Es gibt ungespeicherte Änderungen auf dieser Seite.',
        cancelText: 'Seite verlassen',
        confirmText: 'Auf Seite bleiben',
        type: 'is-success',
        onConfirm: () => next(false),
        onCancel: () => next(),
      });
    } else {
      next();
    }
  }

  initVenueWatcher () {
    this.unwatchVenueWatch = this.$watch(() => this.venue, () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { pendingVisits, ...excludedEdit} = this.venue;
      this.isDirty = !isEqual(this.excludedDeepCopy, JSON.parse(JSON.stringify(excludedEdit)));
    }, { deep: true });
  }

  loadVenue () {
    if (this.venueId && this.venueId !== '') {
      if (this.$auth.ownedVenues.length === 0) {
        return; // fix issue when user redirected after login (relogin) to venue Edit. The venues are not loaded at this time
      }
      const venue = this.$auth.ownedVenues.find(x => x.id === this.venueId);
      this.venue = plainToClass(Venue, { ...venue });
    }
  }

  onDropFile () {
    this.isDirty = true;
  }

  onDropFileQrCode () {
    this.isDirty = true;
  }

  onDropMenuFile () {
    this.isDirty = true;
  }

  onQrCodeCreated (qrCode: IQrCode) {
    this.qrCode = qrCode;
  }

  async saveVenue () {
    this.errors = await validate(this.venue, {
      groups: ['basedata', 'additionalFeaturesLinks', 'additionalFeatures', 'options', 'isUserVisit', 'areas'],
    });

    if (this.errors.length > 0) {
      return false;
    }
    this.loading = true;
    try {
      if (this.unwatchVenueWatch) {
        // not catch changed on save
        this.unwatchVenueWatch();
      }

      this.venue = await this.$venues.upsertVenue({
        venue: this.venue,
        origVenue: this.deepVenueCopy,
        dropFile: this.droppedFile,
        dropFileQrCode: this.droppedFileQrCode,
        dropMenuFile: this.droppedMenuFile,
      });

      const updateVenueIndex = this.$auth.ownedVenues.findIndex(v => v.id === this.venue.id);
      Vue.set(this.$auth.ownedVenues, updateVenueIndex, this.venue);
      this.deepVenueCopy = JSON.parse(JSON.stringify(this.venue));
      this.loading = false;
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Der Standort wurde gespeichert',
        position: 'is-top',
        type: 'is-success',
      });
      this.isDirty = false;
      this.initVenueWatcher();
      return true;
    } catch (e) {
      this.loading = false;
      this.$buefy.toast.open({
        duration: 5000,
        message: e.message,
        position: 'is-top',
        type: 'is-danger',
      });
      return false;
    }
  }

  deleteVenue () {
    this.$buefy.dialog.confirm({
      message: `<b>Möchten Sie den Standort "${this.venue.label}" wirklich löschen?</b><br/><br/>
      Sollten zu diesem Standort noch Gästedaten vorliegen, werden diese zusammen mit den Standortdaten erst nach Ablauf der gesetzlichen Vorgaben von 4 Wochen gelöscht.`,
      cancelText: 'abbrechen',
      onConfirm: async () => {
        this.loading = true;
        try {
          await this.$venues.deleteVenue(this.venue);
          this.$emit('deleteVenue', this.venue);
          this.venue.isDeleted = true;
          await this.$auth.initOwnerVenues();
          this.isDirty = false;
          await this.$router.push({ name: RouteNames.ManagementMain });
          this.$buefy.toast.open({
            duration: 5000,
            message: `${this.venue.label} wurde erfolgreich gelöscht.`,
            position: 'is-top',
            type: 'is-success',
          });
        } catch (e) {
          this.$buefy.toast.open({
            duration: 5000,
            message: e.message,
            position: 'is-top',
            type: 'is-danger',
          });
        }
        this.loading = false;
      },
    });
  }

  async backToOverview () {
    await this.$router.push({ name: RouteNames.ManagementMain });
  }
}
