



































































import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'EinfachGastLogo',
})
export default class EinfachGastLogo extends Vue {
  @Prop({ default: '#4290ce' })
  fill: string

  @Prop()
  plain!: boolean;
}
