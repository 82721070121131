










































































import { Component, Prop } from 'vue-property-decorator';
import { FormFunctions } from '@/mixins/form-functions';
import { mixins } from 'vue-class-component';
import { IVenue } from '@einfachgast/shared';
import VenueInputTooltipWrapper from '@/components/venues/venue-input-tooltip-wrapper.vue';
import { isURL } from 'class-validator';

@Component({
  name: 'VenuesAdditionalFeaturesLinks',
  components: {
    VenueInputTooltipWrapper,
  },
})
export default class VenuesAdditionalFeaturesLinks extends mixins(FormFunctions) {
  @Prop()
  venue: IVenue;

  // because errors and info block use the same template (<template #message>)
  get googleLinkErrorText () {
    if (!this.errors.find(x => x.property === 'googleFeedbackLink')) {
      return null;
    }

    return this.errors.filter(x => x.property === 'googleFeedbackLink')
      .map(x => Object.values(x.constraints))
      .join('<br/>');
  }

  get TooltipText () {
    return 'In Ihrem aktuellen Plan sind keine Zusatzfunktionen enthalten. Vorhandene Einstellungen und Dokumente werden wiederhergestellt, sobald Sie in einen entsprechenden Plan wechseln.';
  }

  get hasFacebookInPackage () {
    return this.$stripe.packageScope.hasFacebook;
  }

  get hasGoogleReviewInPackage () {
    return this.$stripe.packageScope.hasGoogleReview;
  }

  prefixGoogleLinkWithHttps () {
    this.venue.googleFeedbackLink = this.prefixStringWithHttps(this.venue.googleFeedbackLink);
  }

  prefixFacebookLinkWithHttps () {
    this.venue.facebookLink = this.prefixStringWithHttps(this.venue.facebookLink);
  }

  prefixStringWithHttps (incomingUrl: string) {
    if (!incomingUrl || incomingUrl === '') {
      return incomingUrl;
    }
    let httpsUrl: string;
    const r = /^https:\/\//gi;
    if (!r.test(incomingUrl)) {
      const domain = incomingUrl.split('://').pop();
      httpsUrl = ['https', domain].join('://');
    }
    if (!isURL(httpsUrl)) {
      return incomingUrl;
    }
    const url = new URL(httpsUrl);
    return url.href;
  }
}
