














































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import VenuesAdditionalFeatures from '@/components/venues/venues-additional-features.vue';
import { IVenue } from '@einfachgast/shared';
import { ValidationError } from 'class-validator';

@Component({
  name: 'VenueAdditionalFeaturesStep',
  components: {
    VenuesAdditionalFeatures,
  },
})
export default class VenueAdditionalFeaturesStep extends Vue {
  @Prop()
  venue: IVenue;

  @Prop({ default: (): ValidationError[] => [] })
  errors: ValidationError[];

  onDropFile (droppedFile: File) {
    this.$emit('onDropMenuFile', droppedFile);
  }
}
