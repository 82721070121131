






























































import { Component, Prop } from 'vue-property-decorator';
import LoginHeaderToggle from '@/components/login/login-header-toggle.vue';
import LoggedOutWrapper from '@/components/logged-out-wrapper.vue';
import { mixins } from 'vue-class-component';
import { FormFunctions } from '@/mixins/form-functions';
import { LoginData } from '@/models/login-data';
import { ILoginData } from '@/interfaces';
import { isEmpty } from 'class-validator';
import { VueEnvHelper } from '@/helpers/vue-envvar-helper';

@Component({
  name: 'Login',
  components: {
    LoggedOutWrapper,
    LoginHeaderToggle,
  },
})
export default class Login extends mixins(FormFunctions) {
  loading = false;
  loginData: ILoginData = new LoginData();
  private envHelper = new VueEnvHelper();

  @Prop()
  redirectUrl: string;

  @Prop()
  verified: string;

  get emailSetInUrl () {
    return !isEmpty(this.emailFromUrl);
  }

  get emailFromUrl () {
    return this.$router.currentRoute.query['email'] as string;
  }

  async beforeCreate () {
    if (this.$auth.user) {
      await this.$router.push({ path: '/' });
      return;
    }

    this.loading = false;
  }

  created () {
    if (this.emailSetInUrl) {
      this.loginData.email = this.emailFromUrl;
    }
  }

  async login () {
    if (!await this.validate(this.loginData)) {
      return;
    }
    this.loading = true;
    try {
      await this.$auth.login(this.loginData);
      await this.$router.push(this.redirectUrl || '/');
    } catch (e) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Falsches Passwort oder E-Mail',
        position: 'is-top',
        type: 'is-danger',
      });
    }
    this.loading = false;
  }

  get IsProduction () {
    return this.Environment === 'production';
  }

  get Environment () {
    // eslint-disable-next-line no-undef
    return (this.envHelper.get('ENVIRONMENT') || process.env.NODE_ENV).toLowerCase();
  }

  toV2Register () {
    let url = 'https://einfachgast-v2-testing.web.app/register';
    if (this.Environment === 'production') {
      url = 'https://mein.einfachgast.de/register';
    }
    window.location.replace(url);
  }
}
