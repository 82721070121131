



















import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'ManagementMenuBox',
})
export default class ManagementMenuBox extends Vue {
  @Prop()
  title: string;

  @Prop({ default: 'is-default'})
  type: 'is-success' | 'is-default' | 'is-warning' | 'is-danger';

  @Prop({ default: false })
  disabled: boolean;

  @Prop()
  icon: string;

  get cssClass () {
    let cls = `${this.type}`;
    if (this.disabled) {
      cls += ' disabled';
    }
    return cls;
  }
}
