import Vue from 'vue';
import { RouteConfig } from 'vue-router';
import Registration from '../views/Registration.vue';
import Dashboard from '../views/Dashboard.vue';
import Verification from '../views/Verification.vue';
import Login from '../views/Login.vue';
import PasswordReset from '../views/PasswordReset.vue';
import { getModule } from 'vuex-module-decorators';
import { AuthModule } from '@/store/modules/auth';
import { store } from '@/store';
import MyData from '@/views/MyData.vue';
import DataRequestForm from '@/views/DataRequestForm.vue';
import ChangeMail from '@/views/ChangeMail.vue';
import Abonnement from '@/views/Abonnement.vue';
import LiveVisitors from '@/views/LiveVisitors.vue';
import Management from '@/views/Management.vue';
import VenueCreate from '@/components/venues/venues-create.vue';
import MyPlanMenuItemRenderer from '@/components/menu/renderers/my-plan.vue';
import LiveMenuItemRenderer from '@/components/menu/renderers/live.vue';
import ManagementMain from '@/views/ManagementMain.vue';
import VenueEdit from '@/components/venues/venue-edit.vue';
import VisitorsDownload from '@/views/VisitorsDownload.vue';
import StripeRewrite from '@/views/StripeRewrite.vue';
import Settings from '@/views/Settings.vue';
import { VueRouterEx } from '@/router/vue-router-ex';
import { RouteNames } from './route-names';
import { StripeModule } from '@/store/modules/stripe';
import ChildUsers from '@/views/ChildUsers.vue';
import SignInComplete from '@/views/SignInComplete.vue';
import { Roles } from '@/models/venues/roles';


Vue.use(VueRouterEx);
const auth = getModule(AuthModule, store);
const stripe = getModule(StripeModule, store);

const routes: Array<RouteConfig> = [
  {
    path: '/live-view',
    name: RouteNames.LiveView,
    component: LiveVisitors,
    meta: {
      menuItemRenderer: LiveMenuItemRenderer,
      menuSortOrder: 0,
      needsSubscription: true,
      roles: [Roles.VenueOwner, Roles.ChildUser],
    },
  },
  {
    path: '/',
    name: RouteNames.Dashboard,
    component: Dashboard,
    meta: {
      menuTitle: 'Statistik',
      menuIcon: 'chart-line',
      menuSortOrder: 10,
      needsSubscription: true,
      roles: [Roles.VenueOwner],
    },
  },
  {
    path: '/visitors-data-download',
    name: RouteNames.VisitorDataDownload,
    component: VisitorsDownload,
    meta: {
      menuTitle: 'Export',
      menuIcon: 'download',
      menuSortOrder: 20,
      roles: [Roles.VenueOwner],
      canShow: () => {
        return auth.ownedVenues.find(x => x.isUserVisit);
      },
    },
  },
  {
    path: '/stripe-customer-portal',
    name: RouteNames.StripeCustomerPortal,
    component: StripeRewrite,
    props: true,
    meta: {
      hideSidebar: true,
      roles: [Roles.VenueOwner],
    },
  },
  {
    path: '/management',
    name: RouteNames.Management,
    component: Management,
    redirect: 'management/main',
    meta: {
      menuTitle: 'Verwalten',
      menuIcon: 'map-marker-outline',
      menuSortOrder: 30,
      roles: [Roles.VenueOwner],
    },
    children: [
      {
        path: 'main',
        name: RouteNames.ManagementMain,
        component: ManagementMain,
        meta: {
          roles: [Roles.VenueOwner],
        },
      },
      {
        path: 'my-data',
        name: RouteNames.MyData,
        component: MyData,
        meta: {
          menuTitle: 'Meine Daten',
          menuIcon: 'account-outline',
          menuSortOrder: 0,
          roles: [Roles.VenueOwner],
        },
      },
      {
        path: 'abonnement',
        name: RouteNames.Abonnement,
        component: Abonnement,
        meta: {
          menuItemRenderer: MyPlanMenuItemRenderer,
          menuSortOrder: 10,
          roles: [Roles.VenueOwner],
        },
      },
      {
        path: 'settings',
        name: RouteNames.Settings,
        component: Settings,
        meta: {
          menuTitle: 'Einstellungen',
          menuIcon: 'cog',
          menuSortOrder: 20,
          needsSubscription: true,
          roles: [Roles.VenueOwner],
        },
      },
      {
        path: 'child-users',
        name: RouteNames.ChildUsers,
        component: ChildUsers,
        meta: {
          menuTitle: 'Benutzer',
          menuIcon: 'account-multiple-outline',
          menuSortOrder: 50,
          roles: [Roles.VenueOwner],
          needsSubscription: true,
        },
      },
      {
        path: 'editVenue/:venueId',
        name: RouteNames.EditVenue,
        component: VenueEdit,
        props: true,
        meta: {
          needsSubscription: true,
          roles: [Roles.VenueOwner],
        },
        redirect: 'editVenue/:venueId/areas',
        children: [
          {
            path: 'areas',
            name: RouteNames.VenueEditTabAreas,
            component: () => import(/* webpackChunkName: "venue-edit" */'@/components/venues/venue-steps/venue-areas-step.vue'),
            meta: {
              order: 0,
              needsSubscription: true,
              roles: [Roles.VenueOwner],
            },
          },
          {
            path: 'design/:activeTab?',
            name: RouteNames.VenueEditTabDesign,
            component: () => import(/* webpackChunkName: "venue-edit" */'@/components/venues/venue-steps/venue-design-step.vue'),
            props: true,
            meta: {
              order: 10,
              needsSubscription: true,
              roles: [Roles.VenueOwner],
            },
          },
          {
            path: 'additionalFeature',
            name: RouteNames.VenueEditTabAdditionalFeature,
            component: () => import(/* webpackChunkName: "venue-edit" */'@/components/venues/venue-steps/venue-additional-features-step.vue'),
            meta: {
              order: 20,
              needsSubscription: true,
              roles: [Roles.VenueOwner],
            },
          },
          {
            path: 'options/:activeTab?',
            name: RouteNames.VenueEditTabOptions,
            component: () => import(/* webpackChunkName: "venue-edit" */'@/components/venues/venue-steps/venue-options-step.vue'),
            props: true,
            meta: {
              order: 50,
              needsSubscription: true,
              roles: [Roles.VenueOwner],
            },
          },
        ],
      },
    ],
  },
  /*{
    path: '/register',
    name: RouteNames.Registration,
    component: Registration,
    meta: {
      public: true,
      blueBg: true,
    },
  },*/
  {
    path: '/login',
    name: RouteNames.Login,
    component: Login,
    props: true,
    meta: {
      public: true,
      blueBg: true,
      hideSidebar: true,
    },
  },
  {
    path: '/password-reset',
    name: RouteNames.PasswordReset,
    component: PasswordReset,
    props: true,
    meta: {
      public: true,
      blueBg: true,
    },
  },
  {
    path: '/verify',
    name: RouteNames.Verification,
    component: Verification,
    meta: {
      hideSidebar: true,
      hideWizardHint: true,
      blueBg: true,
    },
  },
  {
    path: '/complete-sign-in/:token?',
    name: RouteNames.SignInComplete,
    component: SignInComplete,
    props: true,
    meta: {
      public: true,
      hideSidebar: true,
      hideWizardHint: true,
      blueBg: true,
    },
  },
  {
    path: '/changemail',
    name: RouteNames.ChangeMail,
    component: ChangeMail,
    meta: {
      hideSidebar: true,
      hideWizardHint: true,
      blueBg: true,
    },
  },
  {
    path: '/data-request',
    name: RouteNames.DataRequestForm,
    component: DataRequestForm,
    meta: {
      menuTitle: 'Corona-Fall melden',
      menuIcon: 'police-badge-outline',
      menuSortOrder: 50,
      roles: [Roles.VenueOwner],
      canShow: () => {
        return auth.ownedVenues.find(x => !x.isUserVisit);
      },
    },
  },
  {
    path: '/newVenue',
    name: RouteNames.NewVenue,
    component: VenueCreate,
    props: true,
    redirect: '/newVenue/address',
    children: [
      {
        path: 'address',
        name: RouteNames.VenueCreateTabAddress,
        component: () => import(/* webpackChunkName: "venue-create" */'@/components/venues/venue-steps/venue-data-step.vue'),
        meta: {
          icon: 'home',
          roles: [Roles.VenueOwner],
        },
      },
      {
        path: 'datasecurity',
        name: RouteNames.VenueCreateTabDataSecurity,
        component: () => import(/* webpackChunkName: "venue-create" */'@/components/venues/venue-steps/venue-data-security-step.vue'),
        meta: {
          icon: 'shield-half-full',
          roles: [Roles.VenueOwner],
        },
      },
      {
        path: 'start',
        name: RouteNames.VenueCreateTabStart,
        component: () => import(/* webpackChunkName: "venue-create" */'@/components/venues/venue-steps/venue-start-step.vue'),
        meta: {
          icon: 'rocket-launch',
          roles: [Roles.VenueOwner],
        },
      },
    ],
  },
  {
    path: '*',
    name: RouteNames.NotFound,
    component: () => import(/* webpackChunkName: "not-found" */'@/components/not-found.vue'),
  },
];

const router = new VueRouterEx({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta.needsSubscription && (!stripe.hasSubscription && ![RouteNames.ManagementMain.toString(), RouteNames.MyData.toString()].includes(to.name))) {
    try {
      next({ name: RouteNames.ManagementMain });
    } catch (e) {
      // this fails silently on purpose.
      // @see: https://stackoverflow.com/questions/62223195/vue-router-uncaught-in-promise-error-redirected-from-login-to-via-a
    }
    return;
  }

  // use router push instead of next() => https://stackoverflow.com/questions/62223195/vue-router-uncaught-in-promise-error-redirected-from-login-to-via-a
  if (to.meta.hasPermission && typeof to.meta.hasPermission === 'function' && !to.meta.hasPermission()) {
    void router.push({ name: RouteNames.NotFound });
    return;
  }

  // Enforce login
  if (!to.meta.public && !auth.user) {
    void router.push({ name: RouteNames.Login, params: { redirectUrl: to.fullPath } });
    return;
  }
  // users cant Do shit until email is verified
  if (auth.user && !auth.user.emailVerified && to.name !== RouteNames.Verification && to.name !== RouteNames.ChangeMail) {
    void router.push({ name: RouteNames.Verification });
    return;
  }

  // Child Users may only see Live View and Public Views
  if (!to.meta.public && auth.permissions.isChildUser && to.name !== RouteNames.LiveView) {
    void router.push({ name: RouteNames.LiveView });
    return;
  }

  // Users may Only access routes that are allowed for their role
  if (to.meta.roles && !(to.meta.roles as Array<Roles>).some(x => auth.permissions.has(x))) {
    void router.push({ name: RouteNames.NotFound});
    return;
  }

  // logged in users cant access login and register
  if (auth.user && (to.name === RouteNames.Login || to.name === RouteNames.Registration)) {
    void router.push({ name: RouteNames.Dashboard });
    return;
  }

  document.body.className = to.name?.toLowerCase();
  if (to.meta?.blueBg) {
    document.body.classList.add('blueBg');
  } else {
    document.body.classList.remove('blueBg');
  }
  next();
});

export { routes, RouteNames };
export default router;
