import { interfaces } from 'inversify';

interface IContainerContainer {
  container: interfaces.Container;
  staticResolveMappings: { [key: string]: any };
}

type resolveType = 'literal' | 'all';

// the 'as'-es are a dirty workaround for the absence of string based enums in TS 2.3
// @fixme: when TS 2.4 is out, convert to string enum
const resolveTypeObject = {
  literal: 'literal' as resolveType,
  all: 'all' as resolveType,
};

const markerAll = 'All<';

const markerTypes = [markerAll];

export class VueProvideCompatibleDiContainerFactory {
  /* eslint-disable no-useless-constructor */
  constructor (private staticResolveMappings: { [key: string]: any }, private container: interfaces.Container) { }

  public create (): IContainerContainer {
    const rootContainer = { container: this.container, staticResolveMappings: this.staticResolveMappings };
    return new Proxy<IContainerContainer>(rootContainer, {
      has: this.has.bind(this),
      get: this.get.bind(this),
      getOwnPropertyDescriptor: (target, property) => this.has(target, property)
        ? { configurable: true }
        : undefined,
    });
  }

  public getInject<T> (p: PropertyKey): T {
    const resolveTypeAndKey = this.parsePropertyKey(p);
    if (resolveTypeAndKey.resolveType !== resolveTypeObject.literal) {
      return null;
    }
    try {
      return this.container.get<T>(resolveTypeAndKey.propertyKey);
    } catch {
      // serviceIdentifier not found => but the application should still continue
      return null;
    }
  }

  public getInjectAll<T> (p: PropertyKey): T[] {
    const resolveTypeAndKey = this.parsePropertyKey(`${markerAll}${p.toString()}>`);
    if (resolveTypeAndKey.resolveType !== resolveTypeObject.literal) {
      try {
        return this.container.getAll<T>(resolveTypeAndKey.propertyKey);
      } catch {
        // serviceIdentifier not found => but the application should still continue
        return [];
      }
    }
    return [];
  }

  private has (target: IContainerContainer, p: PropertyKey) {
    const resolveTypeAndKey = this.parsePropertyKey(p);
    return (
      /* eslint-disable no-prototype-builtins */
      target.staticResolveMappings.hasOwnProperty(resolveTypeAndKey.propertyKey)
      || target.container.isBound(resolveTypeAndKey.propertyKey)
    );
  }

  private get (target: IContainerContainer, p: PropertyKey) {
    const resolveTypeAndKey = this.parsePropertyKey(p);
    const shouldTryToGetAll = resolveTypeAndKey.resolveType !== resolveTypeObject.literal;
    return this.getValueFromObjectOrDiContainer(resolveTypeAndKey.propertyKey, target, shouldTryToGetAll);
  }

  /**
   * Turns "All<ISomeThing>" into "ISomeThing" given a "All<" marker.
   *
   * @private
   * @param {string} propertyKeyString - the "dirty" property key string
   * @param {string} marker - the marker to strip from the propertyKeyString
   * @returns - a clean propertyKeyString, stripped of the marker
   * @memberof DiContainerProxyFactory
   */
  private cleanPropertyKeyName (propertyKeyString: string, marker: string) {
    return propertyKeyString.substring(marker.length, propertyKeyString.length - 1);
  }

  private mapMarkerToResolveType (marker: string) {
    switch (marker) {
      case markerAll:
        return resolveTypeObject.all;
      default:
        throw new Error(`Cannot convert unknown marker ${marker} to resolve type`);
    }
  }

  private parsePropertyKey (p: PropertyKey): { resolveType: resolveType; propertyKey: string } {
    const propertyKeyString = p.toString();
    const propertyIsMarkedAs = markerTypes.find((item) => propertyKeyString.indexOf(item) === 0);

    return {
      resolveType: (propertyIsMarkedAs === undefined)
        ? resolveTypeObject.literal
        : this.mapMarkerToResolveType(propertyIsMarkedAs),
      propertyKey: (propertyIsMarkedAs === undefined)
        ? propertyKeyString
        : this.cleanPropertyKeyName(propertyKeyString, propertyIsMarkedAs),
    };
  }

  private getValueFromObjectOrDiContainer (
    propertyKey: string,
    container: IContainerContainer,
    shouldGetAllIfPossible: boolean,
  ) {
    if (container.staticResolveMappings.hasOwnProperty(propertyKey)) {
      return (shouldGetAllIfPossible)
        ? [container.staticResolveMappings[propertyKey]]
        : container.staticResolveMappings[propertyKey];
    }

    if (!container.container.isBound(propertyKey)) {
      return (shouldGetAllIfPossible)
        ? []
        : undefined;
    }

    return (shouldGetAllIfPossible)
      ? container.container.getAll(propertyKey)
      : container.container.get(propertyKey);
  }
}
