






















import Component from 'vue-class-component';
import Vue from 'vue';
import { RouteNames } from '@/router';

@Component({
  name: 'TrialVersionHint',
})
export default class TrialVersionHint extends Vue {
  get IsVisible () {
    return this.IsTrial
      && this.IsRegistrationCompleted
      && this.$auth.venueOwner
      && !this.IsAccountDeleted
      && !this.IsAboPage
      && !this.IsNewVenueRoute;
  }

  get routerTarget () {
    return { name: RouteNames.Abonnement };
  }

  get IsTrial () {
    return this.$stripe.packageScope?.isTrial;
  }

  get IsRegistrationCompleted () {
    return this.$auth.isRegistrationComplete;
  }

  get IsAccountDeleted () {
    return this.$auth.isVenueOwnerDeleted;
  }

  get IsAboPage () {
    return this.$route.name === RouteNames.Abonnement;
  }

  get DaysLeftOnTrial () {
    if (this.DurationLeftOnTrial.days === 0) {
      return 0;
    }
    return this.DurationLeftOnTrial.roundedDays || 0;
  }

  get DurationLeftOnTrial () {
    return this.$stripe.packageScope.trialDurationLeft;
  }

  get IsNewVenueRoute () {
    // check if newVenue is at the beginning of the path. So the route is in create venue
    return this.$route.path.indexOf(`/${RouteNames.NewVenue}`) === 0;
  }
}
