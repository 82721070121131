






















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IVenue } from '@einfachgast/shared';

@Component({
  name: 'VenuesCovidOptions',
})
export default class VenuesCovidOptions extends Vue {
  @Prop()
  venue: IVenue;
}
