














































































import { Component, Prop } from 'vue-property-decorator';
import { IVenue } from '@einfachgast/shared';
import { FormFunctions } from '@/mixins/form-functions';
import { mixins } from 'vue-class-component';

@Component({
  name: 'VenuesOptionsCheckinCheckoutContent',
})
export default class VenuesOptionsCheckinCheckoutContent extends mixins(FormFunctions) {
  canShowCheckoutAllGuestsAt = false;
  guestLimitEnabled = false;
  canShowMaxStayDuration = false;

  @Prop()
  venue: IVenue;

  get activateDeactivateLimitField () {
    return this.guestLimitEnabled ? 'Deaktivieren' : 'Aktivieren';
  }

  get activateDeactivateMaxDurationStayField () {
    return this.canShowMaxStayDuration ? 'Deaktivieren' : 'Aktivieren';
  }

  get activateDeactivateCheckoutAllGuestsAtField () {
    return this.canShowCheckoutAllGuestsAt ? 'Deaktivieren' : 'Aktivieren';
  }

  created () {
    this.canShowCheckoutAllGuestsAt = this.venue.checkoutAllGuestsAt !== null;
    this.canShowMaxStayDuration = this.venue.maxStayDuration !== null;
  }

  setCheckoutAllGuestsAtUTC () {
    const nowDate = new Date();
    const timeHourOffset = nowDate.getTimezoneOffset() / 60;
    this.venue.checkoutAllGuestsAtUTC = this.venue.checkoutAllGuestsAt + timeHourOffset;
  }

  toggleMaxStayDuration () {
    if (!this.canShowMaxStayDuration) {
      this.venue.maxStayDuration = null;
    } else {
      this.venue.maxStayDuration = 45;
    }
  }

  toggleGuestsLimit () {
    if (!this.venue.guestLimitEnabled) {
      this.venue.limit = null;
    }
  }

  toggleCheckoutAllGuestsAt () {
    if (!this.canShowCheckoutAllGuestsAt) {
      this.venue.checkoutAllGuestsAt = null;
      this.venue.checkoutAllGuestsAtUTC = null;
    } else {
      this.venue.checkoutAllGuestsAt = 23;
      this.venue.checkoutAllGuestsAtUTC = 22;
    }
  }
}
