import { IsEmail, MinLength } from 'class-validator';
import { Expose, plainToClass, Transform } from 'class-transformer';
import { VenueOwnerSettings } from './venue-owner-settings';
import { Features, IVenueOwner, IVenueOwnerSettings } from '@einfachgast/shared';
import { TransformDate } from '../decorators/transform-date-decorator';

export class VenueOwner implements IVenueOwner {
  public id: string;

  @MinLength(2, { message: 'Bitte geben Sie mindestens $constraint1 Zeichen ein' })
  public companyName: string;

  @MinLength(2, { message: 'Bitte geben Sie mindestens $constraint1 Zeichen ein' })
  public firstname: string;

  @MinLength(2, { message: 'Bitte geben Sie mindestens $constraint1 Zeichen ein' })
  public lastname: string;

  @MinLength(5, { message: 'Bitte geben Sie mindestens $constraint1 Zeichen ein' })
  public phone: string;

  @MinLength(2, { message: 'Bitte geben Sie mindestens $constraint1 Zeichen ein' })
  public street: string;

  @MinLength(4, { message: 'Bitte geben Sie mindestens $constraint1 Zeichen ein' })
  public zipCode: string;

  @MinLength(2, { message: 'Bitte geben Sie mindestens $constraint1 Zeichen ein' })
  public city: string;

  @Expose()
  @Transform(value => value || 'DE', { toClassOnly: true })
  public country: string;

  @Expose()
  @Transform(value => value || [], { toClassOnly: true })
  public features?: Features[];

  @IsEmail({}, { message: 'Bitte geben Sie eine gültige E-Mail-Adresse ein' })
  public email: string;

  @Expose()
  @Transform(value => value
    ? plainToClass<VenueOwnerSettings, IVenueOwnerSettings>(VenueOwnerSettings, value)
    : plainToClass<VenueOwnerSettings, IVenueOwnerSettings>(VenueOwnerSettings, {} as IVenueOwnerSettings),
    { toClassOnly: true })
  public settings: IVenueOwnerSettings;

  @TransformDate()
  public subscriptionStartDate: Date;

  @TransformDate()
  public deletedAt: Date;

  @TransformDate()
  public createdAt: Date;

  @TransformDate()
  public lastSeenAt: Date;

  @TransformDate()
  public trialEnd: Date;

  public spamCheckResult: string;
  public stripeId: string;
  public stripeLink: string;
  public userId?: string;
  public isDeleted: boolean;
  public remainingVisits?: number;
  public hideEinfachGastLogoInScanApp? = false;
}
