import { registerDecorator, ValidationOptions, ValidationArguments } from 'class-validator';

export function IsInOtherProperty (property: string, validationOptions?: ValidationOptions) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (object: Record<string, any>, propertyName: string) {
    registerDecorator({
      name: 'isInOtherProperty',
      target: object.constructor,
      propertyName: propertyName,
      constraints: [property],
      options: validationOptions,
      validator: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        validate (value: any, args: ValidationArguments) {
          const [otherPropertyName] = args.constraints;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const otherValue = (args.object as any)[otherPropertyName];
          return typeof value === 'string' && Array.isArray(otherValue) && otherValue.indexOf(value) !== -1;
        },
      },
    });
  };
}
