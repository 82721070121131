







































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { DBCollections, firebaseWrapper as fb } from '@/firebase-wrapper';
import { createQrCodeForArea } from '@/helpers/qr-code-creation-helper';
import AdminArea from '@/models/admin-area';
import { QrCode } from '@/models/qr-code';
import { QrCodePrintTypes } from '@/models/qr-code-print-types';
import { IVenue } from '@einfachgast/shared';
import { plainToClass } from 'class-transformer';
import { IQrCode } from '@/interfaces/i-qr-code';
import { query, collection, where, getDocs } from 'firebase/firestore';

@Component({
  name: 'VenueStartSetupTablet',
})
export default class VenueStartSetupTablet extends Vue {
  loading = true;
  adminQrCode: IQrCode = null;

  @Prop()
  venue: IVenue;

  mounted () {
    void this.setupTablet();
  }

  async setupTablet () {
    const querySnapshot = await getDocs(query(collection(fb.db, DBCollections.QrCodes), where('venueId', '==', this.venue.id), where('isAdminCode', '==', true)));

    if (querySnapshot.size > 0) {
      this.adminQrCode = plainToClass(QrCode, { ...querySnapshot.docs[0].data(), id: querySnapshot.docs[0].id });
    } else {
      this.adminQrCode = await createQrCodeForArea(this.venue, new AdminArea(), QrCodePrintTypes.Admin);
    }
    this.loading = false;
  }
}
