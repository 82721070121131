import { IFeature, IFeatureStatic } from '@/interfaces';
import { RouteNames } from '@/router';
import { AuthModule } from '@/store/modules/auth';
import { staticImplements } from '@/decorators/static-implements';
import { VueRouter } from 'vue-router/types/router';
import VueI18n from 'vue-i18n';
import * as deTranslation from '@/features/safety-instructions/translation/de';
import * as enTranslation from '@/features/safety-instructions/translation/en';

@staticImplements<IFeatureStatic>()
export class SafetyInstructions implements IFeature {
  public static canUseFeature (auth: AuthModule): boolean {
    return auth.ownedVenues.some(x => x.displaySafetyHints);
    // return auth.permissions.hasFeature(Features.SafetyInstructions);
  }

  // @tdo: For some reason this is not working. I need to fix it
  public constructor (router: VueRouter, i18n: VueI18n) {
    // add rountes and languages
    const editVenueRouteExists = router.resolve({ name: 'venueEditTabSafetyFeature'});
    if ( editVenueRouteExists.resolved.matched.length === 0) {
      router.addRoute(RouteNames.EditVenue.toString(), {
        path: 'safetyFeature',
        name: RouteNames.VenueEditTabSafetyFeature,
        meta: {
          order: 25,
        },
        component: () => import(/* webpackChunkName: "venue-safety-feature" */'@/features/safety-instructions/components/safety-instructions-step.vue'),
      });
    }

    i18n.mergeLocaleMessage('de', deTranslation.default);
    i18n.mergeLocaleMessage('en', enTranslation.default);
  }
}
