









import Component from 'vue-class-component';
import ManagementMenuBox from '@/components/management/management-menu-box.vue';
import Vue from 'vue';
import { RouteNames } from '@/router';
import { SubscriptionStatus } from '@einfachgast/shared';

@Component({
  name: 'ManagementMenu',
  components: {
    ManagementMenuBox,
  },
})
export default class ManagementMenu extends Vue {
   get planName () {
    if (this.$stripe.packageScope.Status === SubscriptionStatus.Trialing) {
      return 'Testphase';
    }
    return this.$stripe.packageScope.ProductName;
  }

  goToMyData () {
    void this.$router.push({ name: RouteNames.MyData });
  }

  goToMyPlan () {
    void this.$router.push({ name: RouteNames.Abonnement });
  }

  goToSettings () {
    void this.$router.push({ name: RouteNames.Settings });
  }

  createVenue () {
    void this.$router.push({ name: RouteNames.NewVenue });
  }
}
