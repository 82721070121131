



























































































import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { IVenue } from '@einfachgast/shared';
import ManagementMenu from '@/components/management/management-menu.vue';
import ManagementMenuBox from '@/components/management/management-menu-box.vue';
import { RouteNames } from '@/router';
import { IQrCode } from '@/interfaces/i-qr-code';
import VenueStartSetupTablet from '@/components/venues/venue-steps/venue-start/venue-start-setup-tablet.vue';

@Component({
  name: 'VenueListItem',
  components: {
    ManagementMenu,
    ManagementMenuBox,
    VenueStartSetupTablet,
  },
})
export default class VenueListItem extends Vue {
  setupTableModalVisible = false;
  loading = false;
  adminQrCode: IQrCode = null;
  
  @Prop()
  venue: IVenue;

  get venueAddressString () {
    return `${this.venue.street}, ${this.venue.zipCode}, ${this.venue.city}`;
  }

  get isDeleted () {
    return this.venue.isDeleted;
  }

  get isDeactivated () {
    return this.venue.isDeactivated;
  }

  get disabledTooltipText () {
    if (this.venue.isDeactivated) {
      return 'Diese Standorte wurden deaktiviert, da die Anzahl an Standorten in Ihrem aktuellen Plan bereits überschritten ist.';
    }
    return 'Dieser Standort wurde als gelöscht markiert und wird nach Ablauf der gesetzlichen Vorgaben von 4 Wochen endgültig gelöscht.';
  }

  ensureSubscription () {
    if (this.isDeactivated) {
       this.$buefy.toast.open({
        duration: 5000,
        message: this.$t('Diese Funktion steht ohne aktiven Plan nicht zur Verfügung').toString(),
        position: 'is-top',
        type: 'is-danger',
      });
      return false;
    }
    return true;
  }

  goToAreas () {
    if(!this.ensureSubscription()) {
      return;
    }
    void this.$router.push({ name: RouteNames.VenueEditTabAreas, params: { venueId: this.venue.id }});
  }

  goToDesign () {
    if(!this.ensureSubscription()) {
      return;
    }
    void this.$router.push({ name: RouteNames.VenueEditTabDesign, params: { venueId: this.venue.id, activeTab: 'qr' }});
  }

  goToDocsAndLinks () {
    if(!this.ensureSubscription()) {
      return;
    }
    void this.$router.push({ name: RouteNames.VenueEditTabAdditionalFeature , params: { venueId: this.venue.id }});
  }

  goToScurityHints () {
    if(!this.ensureSubscription()) {
      return;
    }
    void this.$router.push({ name: RouteNames.VenueEditTabSafetyFeature , params: { venueId: this.venue.id }});
  }

  goToSettings () {
    if(!this.ensureSubscription()) {
      return;
    }
    void this.$router.push({ name: RouteNames.VenueEditTabOptions , params: { venueId: this.venue.id }});
  }

  deleteVenue () {
    if(!this.ensureSubscription()) {
      return;
    }
    this.$buefy.dialog.confirm({
      message: `<b>Möchten Sie den Standort "${this.venue.label}" wirklich löschen?</b><br/><br/>
      Sollten zu diesem Standort noch Gästedaten vorliegen, werden diese zusammen mit den Standortdaten erst nach Ablauf der gesetzlichen Vorgaben von 4 Wochen gelöscht.`,
      cancelText: 'abbrechen',
      onConfirm: async () => {
        this.loading = true;
        try {
          await this.$venues.deleteVenue(this.venue);
          this.$emit('deleteVenue', this.venue);
          this.venue.isDeleted = true;
          await this.$auth.initOwnerVenues();
          this.$buefy.toast.open({
            duration: 5000,
            message: `${this.venue.label} wurde erfolgreich gelöscht.`,
            position: 'is-top',
            type: 'is-success',
          });
        } catch (e) {
          this.$buefy.toast.open({
            duration: 5000,
            queue: false,
            message: e.message,
            position: 'is-top',
            type: 'is-danger',
          });
        }
        this.loading = false;
      },
    });
  }

  setupTablet () {
    if(!this.ensureSubscription()) {
      return;
    }
    this.setupTableModalVisible = true;
  }
}
