import Vue from 'vue';
import Component from 'vue-class-component';

// You can declare mixins as the same style as components.
@Component
export class RevertUserAccount extends Vue {
  refertAccountloading = false

  revertDeleteAccount () {
    this.$buefy.dialog.confirm({
      message: 'Möchten Sie Ihr Konto wirklich wiederherstellen?',
      cancelText: 'abbrechen',
      onConfirm: async () => {
        this.refertAccountloading = true;
        try {
          await this.$auth.enableVenueOwner();
          await this.$auth.reInitApp();
          location.reload();
          this.$buefy.toast.open({
            duration: 5000,
            message: 'Ihr Account wurde erfolgreich wieder hergestellt.',
            position: 'is-top',
            type: 'is-success',
          });
        } catch (e) {
          this.$buefy.toast.open({
            duration: 5000,
            message: e.message,
            position: 'is-top',
            type: 'is-danger',
          });
        }
        this.refertAccountloading = false;
      },
    });
  }
}
