












































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { IVenue } from '@einfachgast/shared';
import { Venue } from '@/models/venues/venue';
import { classToClass, plainToClass } from 'class-transformer';
import { RouteNames } from '@/router';
import { IQrCode } from '@/interfaces/i-qr-code';
import { Route, RouteConfig } from 'vue-router';
import { validate, ValidationError } from 'class-validator';
import { isEqual, omit } from 'lodash';

type stepperNavigation = {
  navigationProps: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    next: { disabled: boolean; action: Function };
    // eslint-disable-next-line @typescript-eslint/ban-types
    previous: { disabled: boolean; action: Function };
  };
};

Component.registerHooks([
  'beforeRouteLeave',
]);

@Component({
  name: 'VenueCreate',
})
export default class VenueCreate extends Vue {
  loading = false;
  qrCode: IQrCode = null;
  droppedFile: File = null;
  droppedFileQrCode: File = null;
  droppedMenuFile: File = null;
  venue: IVenue = classToClass(new Venue());
  activeStep = RouteNames.VenueCreateTabAddress;
  referenceVenue: IVenue = null;
  errors: ValidationError[] = [];
  stepperRef: stepperNavigation = null;

  get childRoutes () {
    return this.$router.options.routes.find(routeItem => routeItem.name === this.$route.matched[0].name)?.children;
  }

  get previousAction () {
    return this.previousStep?.action;
  }

  get previousDisabled () {
    return this.previousStep?.disabled;
  }

  get nextAction () {
    return this.nextStep?.action;
  }

  get nextDisabled () {
    return this.nextStep?.disabled;
  }

  get previousStep () {
    return this.navigationActions?.previous;
  }

  get nextStep () {
    return this.navigationActions?.next;
  }

  get navigationActions () {
    return this.stepperRef?.navigationProps;
  }

  get webDroppedFile () {
    return (this.droppedFile && this.droppedFile.name !== this.venue.logoUrl)
      ? this.droppedFile
      : null;
  }

  get qrCodeDroppedFile () {
    return (this.droppedFileQrCode && this.droppedFileQrCode.name !== this.venue.qrCodelogoUrl)
      ? this.droppedFileQrCode
      : null;
  }

  async mounted () {
    if (!this.$auth.user || !this.$stripe.packageScope) {
      return; // user is not logged in or stripeData not loaded at this time
    }
    if (this.$auth.ownedVenues.filter(venue => !venue.isDeleted && !venue.isDeactivated).length >= this.$stripe.packageScope.maxVenueCount) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Mit Ihrem aktuellen Plan können keine weiteren Standorte mehr angelegt werden.',
        position: 'is-top',
        type: 'is-danger',
      });
      await this.$router.push({ name: RouteNames.Venues });
    }
    this.stepperRef = (this.$refs?.venueCreateSteps as unknown as stepperNavigation);
    this.removeDirtyState();
  }

  async changeTab (route: RouteNames) {
    await this.$router.push({ name: route });
  }

  removeDirtyState () {
    this.referenceVenue = plainToClass(Venue, {...this.venue });
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  beforeRouteLeave (to: Route, from: Route, next: Function) {
    if (!isEqual(omit(this.referenceVenue, 'pendingVisits'), omit(this.venue, 'pendingVisits'))) {
      this.$buefy.dialog.confirm({
        message: 'Es gibt ungespeicherte Änderungen auf dieser Seite.',
        cancelText: 'Seite verlassen',
        confirmText: 'Auf Seite bleiben',
        type: 'is-success',
        onConfirm: () => next(false),
        onCancel: () => next(),
      });
    } else {
      next();
    }
  }

  onDropFile (droppedFile: File) {
    this.droppedFile = droppedFile;
  }

  onDropFileQrCode (droppedFileQrCode: File) {
    this.droppedFileQrCode = droppedFileQrCode;
  }

  onDropMenuFile (droppedMenuFile: File) {
    this.droppedMenuFile = droppedMenuFile;
  }

  onQrCodeCreated (qrCode: IQrCode) {
    this.qrCode = qrCode;
  }

  getChildIcon (child: RouteConfig) {
    return child?.meta?.icon;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  async nextActionAndSaveVenue (nextAction: { disabled: boolean; action: Function }) {
    if (await this.saveVenue()) {
      nextAction.action();
    }
  }

  async validate () {
    switch (this.activeStep) {
      case RouteNames.VenueCreateTabAddress:
        return await validate(this.venue, {
          groups: ['basedata'],
        });
      case RouteNames.VenueCreateTabDataSecurity: {
        return await validate(this.venue, {
          groups: ['options', 'isUserVisit'],
        });
      }
      default:
        return [];
    }
  }

  async navigateToOverview () {
    this.removeDirtyState();
    await this.$router.push({ name: RouteNames.ManagementMain });
  }

  async saveVenue () {
    this.errors = await this.validate();
    if (this.errors.length > 0) {
      return false;
    }
    this.loading = true;
    try {
      this.venue = await this.$venues.upsertVenue({
        venue: this.venue,
        origVenue: classToClass(new Venue()),
        dropFile: this.droppedFile,
        dropFileQrCode: this.droppedFileQrCode,
        dropMenuFile: this.droppedMenuFile,
      });
      this.loading = false;
      let message = '';
      switch (this.activeStep) {
        case RouteNames.VenueCreateTabAddress:
          message = 'Die Adresse wurde gespeichert.';
          break;
        case RouteNames.VenueCreateTabDataSecurity:
          message = 'Die Datenschutzeinstellungen wurden gespeichert.';
          break;
        default:
          throw new Error('Invalid active step');
      }

      this.$buefy.toast.open({
        duration: 5000,
        message: message,
        position: 'is-top',
        type: 'is-success',
      });
      if (this.activeStep === RouteNames.VenueCreateTabAddress && !this.$auth.ownedVenues.find(x => x.id === this.venue.id)) {
        // after first step save the new venue in owendVenus
        this.$auth.ownedVenues.unshift(this.venue);
      } else {
        // at all other steps have to replace this setted venue because the reference is lost after upsert
        const updateVenueIndex = this.$auth.ownedVenues.findIndex(v => v.id === this.venue.id);
        Vue.set(this.$auth.ownedVenues, updateVenueIndex, this.venue);
      }
      return true;
    } catch (e) {
      this.loading = false;
      this.$buefy.toast.open({
        duration: 5000,
        message: e.message,
        position: 'is-top',
        type: 'is-danger',
      });
      return false;
    }
  }
}
