import { CheckinConfirmationSettingType, ICheckinConfirmationSettings } from '@einfachgast/shared';
import { Transform, Expose } from 'class-transformer';

export class CheckinConfirmationSettings implements ICheckinConfirmationSettings {
  @Expose()
  @Transform(value => value || false, { toClassOnly: true })
  active: boolean;

  @Expose()
  @Transform(value => value || CheckinConfirmationSettingType.SignaturePad, { toClassOnly: true })
  type: CheckinConfirmationSettingType;

  @Expose()
  @Transform(value => value || {
    [CheckinConfirmationSettingType.CheckBox]: {
      de:'Bitte akzeptieren Sie unsere Checkin-Bedingungen',
      en:'Please accept our check-in conditions',
    },
    [CheckinConfirmationSettingType.SignaturePad]: {
      de:'Bitte unterschreiben Sie in diesem Feld',
      en:'Please sign in this field',
    },
  }, { toClassOnly: true })
  label: {[key in CheckinConfirmationSettingType]: {[key: string]: string}};
}