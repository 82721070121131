







import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import firebase from 'firebase/app';
import { DurationFormatter } from './duration-formatter';
import { Timestamp } from 'firebase/firestore';

const MS_IN_SECONDS = 1000;

@Component({
  name: 'LiveVisitorTimer',
})
export default class LiveVisitorTimer extends Vue {
  msElapsed: number = null;
  counterInterval: number = null;
  formatter = new DurationFormatter();

  @Prop()
  visitStartDate: Timestamp;

  get runningTime () {
    return this.formatter.format(this.msElapsed);
  }

  get checkinTime () {
    if (!this.visitStartDate) {
      return new Date();
    }
    return this.visitStartDate.toDate();
  }

  mounted () {
    this.msElapsed = this.getElapsedTimeInMs();
    this.counterInterval = window.setInterval(() => {
      this.msElapsed = this.getElapsedTimeInMs();
    }, MS_IN_SECONDS);
  }

  destroyed () {
    clearInterval(this.counterInterval);
  }

  getElapsedTimeInMs () {
    const cTime = this.checkinTime?.getTime();
    if (!cTime) {
      return 0;
    }
    return new Date().getTime() - cTime;
  }

  get formattedVisitStartDate () {
    return `CheckIn: ${this.visitStartDate.toDate().toLocaleTimeString([], {
      year: 'numeric', month: 'numeric', day: '2-digit', hour: '2-digit', minute: '2-digit',
    })} Uhr`;
  }
}
