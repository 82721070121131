











import Component from 'vue-class-component';
import Vue from 'vue';
import { IChildUser } from '@einfachgast/shared';
import { plainToClass } from 'class-transformer';
import { ChildUser } from '@/models/child-user';
import { DBCollections, firebaseWrapper as fb } from '@/firebase-wrapper';
import ChildUsersListItem from './child-users-list-item.vue';
import { collection, onSnapshot, query, where } from 'firebase/firestore';

@Component({
  name: 'ChildUsersList',
  components: {
    ChildUsersListItem,
  },
})
export default class ChildUsersList extends Vue {
  loading = true;
  childUsers: IChildUser[] = [];
  unsubscribeSnapshot: () => void = null;

  mounted () {
    this.loadChildUsers();
  }

  loadChildUsers () {
    this.loading = true;
    if (!this.$auth.user) {
      return; // user is not logged in
    }
    const q = query(collection(fb.db, DBCollections.ChildUsers), where('parentUserId', '==', this.$auth.user.uid));
    this.unsubscribeSnapshot = onSnapshot(q, (querySnapshot) => {
        this.childUsers = [];
        querySnapshot.docs.forEach(x => {
          this.childUsers.push(plainToClass(ChildUser, { ...x.data(), id: x.id }));
        });
        this.loading = false;
      });
  }

  beforeDestroy () {
    if (this.unsubscribeSnapshot) {
      this.unsubscribeSnapshot();
    }
  }
}
