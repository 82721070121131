import firebase from 'firebase/app';
import { Timestamp } from 'firebase/firestore';

export default class Subscription {
    description = ''
    name = ''
    id = ''
    productId = ''
    priceId = ''
    status = ''
    current_period_end: Timestamp
    current_period_start: Timestamp

    public get subscriptionDaysLeft () {
      return Math.floor((Date.now() - this.current_period_end.toDate().getTime()) / (1000 * 60 * 60 * 24));
    }
}
