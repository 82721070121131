




















































import { Component, Prop } from 'vue-property-decorator';
import { FormFunctions } from '@/mixins/form-functions';
import { mixins } from 'vue-class-component';
import { IVenue } from '@einfachgast/shared';

@Component({
  name: 'VenuesData',
})
export default class VenuesData extends mixins(FormFunctions) {
  @Prop()
  cssClasses: string;

  @Prop()
  venue: IVenue;

  @Prop({ default: true })
  showHeadline: boolean;
}
