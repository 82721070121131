











































import { Component, Vue } from 'vue-property-decorator';
import LoggedOutWrapper from '@/components/logged-out-wrapper.vue';
import { RouteNames } from '@/router';
import { CountdownTimer } from '@/components/countdown-timer.vue';
import { sendEmailVerification } from 'firebase/auth';
import { FirebaseError } from 'firebase/app';

@Component({
  name: 'Verification',
  components: {
    CountdownTimer,
    LoggedOutWrapper,
  },
})
export default class Verification extends Vue {
  loading = false;
  changeMail = false;
  interval: number = null;
  resendEmailButtonDisabled = false;

  get displayEmail () {
    return this.currentUser?.email;
  }

  get showLoading () {
    return this.currentUser?.emailVerified
      || this.loading;
  }

  get currentUser () {
    return this.$auth.user;
  }

  mounted () {
    window.addEventListener('focus', () => {
      if (this.$auth.user && this.$auth.user.emailVerified) {
        window.location.reload();
      }
    });
    window.addEventListener('blur', () => {
      if (this.$auth.user && this.$auth.user.emailVerified) {
        window.location.reload();
      }
    });
  }

  async beforeCreate () {
    if (this.$auth.user && this.$auth.user.emailVerified) {
      await this.$router.push({ name: RouteNames.Dashboard });
    }
  }

  async resendLink () {
    this.loading = true;
    this.disableResendEmailButton();
    try {
      const parsedUrl = new URL(window.location.href);
      await sendEmailVerification(this.currentUser, { url: `${parsedUrl.protocol}//${parsedUrl.host}` });
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Die E-Mail wurde erfolgreich verschickt. Bitte prüfen Sie Ihren Posteingang und ggf. den Spam-Ordner.',
        position: 'is-top',
        type: 'is-success',
      });
    } catch (error) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Es konnte keine weitere Aktivierungsemail verschickt werden. Bitte versuchen Sie es in wenigen Minuten erneut.',
        position: 'is-top',
        type: 'is-danger',
      });
    }
    this.loading = false;
  }

  private disableResendEmailButton () {
    (this.$refs.timer as CountdownTimer).start();
    this.resendEmailButtonDisabled = true;
  }

  newRegistration () {
    this.$buefy.dialog.confirm({
      message: `Damit wird das Benutzerkonto mit der E-Mail-Adresse <b>${this.displayEmail}</b> gelöscht. Sind Sie sicher?`,
      cancelText: 'Abbrechen',
      confirmText: 'Ja, löschen und neu registrieren',
      onConfirm: () => this.deleteAndNewRegistration(),
    });
  }

  async deleteAndNewRegistration () {
    try {
      await this.$auth.user.delete();
      this.$auth.setUser(null);
      await this.$router.push({ name: RouteNames.Registration });
    } catch (e) {
      if ((e as FirebaseError).code === 'auth/requires-recent-login') {
        await this.$auth.logout();
        return;
      }
      this.$buefy.toast.open({
        duration: 5000,
        message: (e as FirebaseError).message,
        position: 'is-top',
        type: 'is-danger',
      });
    }
  }

  async changeEmail () {
    await this.$router.push({ name: RouteNames.ChangeMail });
  }
}
