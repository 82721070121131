import { DateHelper } from '@/helpers/date-helper';
import objectPath from 'object-path';
import { IVenueStatsWrapper } from '@einfachgast/shared';
import { IDateHelper, IVenueStats } from '@/interfaces';

/**
 * This is barely more than a wrapper for objectPath that offers some convienience functions
 * to access deeply nested objects
 *
 * used setFoo instead of set foo to ensure testability
 */
export class VenueStatsWrapper implements IVenueStatsWrapper {
  // default data that has to be present in order to make that objectPath thing work
  data: IVenueStats = {
    visits: {
      lastVisitDate: null,
      history: {},
    },
    visitors: {
      history: {},
    },
  }

  constructor (private stats?: IVenueStats, private dateHelper: IDateHelper = new DateHelper()) {
    this.wrap(stats);
  }

  get todaysDatePath () {
    return [`${this.dateHelper.year}`, `${this.dateHelper.month}`, `${this.dateHelper.dayOfMonth}`];
  }

  get todaysVisitsPath () {
    return ['visits', 'history', ...this.todaysDatePath];
  }

  get todaysVisitorsPath () {
    return ['visitors', 'history', ...this.todaysDatePath];
  }

  /**
   * Returns data bound to object path for more convinient access
   * @return {objectPath.ObjectPathBound<IVenueStats>}
   */
  get objectPath (): objectPath.ObjectPathBound<IVenueStats> {
    return objectPath(this.data);
  }

  /**
   * retrieve todays visit count
   * @return {number} the amount of visits that has beet stored today
   */
  get todaysVisits () {
    return this.objectPath.get(this.todaysVisitsPath, 0);
  }

  /**
   * Retrieve the amount of visits that has been stored for the current week
   * @return { number } the visit count
   */
  get thisWeeksVisits () {
    let visitCount = 0;
    this.dateHelper.thisWeeksDays.forEach(x => {
      const daysVisitCount = this.getVisitsHistoryOfDate([x.getUTCFullYear(), x.getUTCMonth() + 1, x.getUTCDate()].join('.'), 0);
      visitCount += daysVisitCount;
    });
    return visitCount;
  }

  /**
   * retrieve the current monts visits
   * @return { number } the visits count
   */
  get thisMonthsVisits () {
    return this.getMonthsVisitCount();
  }

  /**
   * Retrieve this years visist
   * @return { number } the visit count
   */
  get thisYearsVisits () {
    return this.getYearsVisitsCount(this.dateHelper.year);
  }

  /**
   * Retrieve all time visits
   * @return { number } all visits of all time
   */
  get allVisits () {
    const allYears = this.data.visits.history;
    return Object.keys(allYears)
      .map(year => this.getYearsVisitsCount(parseInt(year)))
      .reduce((a, b) => a + b, 0);
  }

  /**
   * Retrieve all time visitors
   * @return { number } all visitorss of all time
   */
  get allVisitors () {
    const allYears = this.data.visits.history;
    return Object.keys(allYears)
      .map(year => this.getYearsVisitorCount(parseInt(year)))
      .reduce((a, b) => a + b, 0);
  }

  /**
   * retvrieve Date of last visit
   * @returns { Date } last visits Date
  */
  get lastVisitDate () {
    return this.objectPath.get(['visits', 'lastVisitDate']);
  }

  /**
   * Retrieve count of visits that has been stored for today
   * @return { number } the visitor count
   */
  get todaysVisitors () {
    return this.objectPath.get(this.todaysVisitorsPath, 0);
  }

  /**
   * Retrieve this weeks visitors
   * @return { number } number
   */
  get thisWeeksVisitors () {
    let visitCount = 0;
    this.dateHelper.thisWeeksDays.forEach(x => {
      const daysVisitorCount = this.getVisitorsHistoryOfDate([
        x.getUTCFullYear(),
        x.getUTCMonth() + 1,
        x.getUTCDate(),
      ].join('.'), 0);
      visitCount += daysVisitorCount;
    });
    return visitCount;
  }

  /**
   * Retrieve visitors count for this month
   * @return { number } the visitors count
   */
  get thisMonthsVisitors () {
    return this.getMonthsVisitorsCount();
  }

  /**
   * retrieve visitors of the current Year
   * @return { number } the visitors count
   */
  get thisYearsVisitors () {
    return this.getYearsVisitorCount(this.dateHelper.year);
  }

  getObjectsByPath (path: string[]) {
    const flatObj = this.flattenObj(this.data);
    const catchedObjsKeys = Object.keys(flatObj).filter(key => key.indexOf(path.join('.')) !== -1);
    const result: { [key: string]: number } = {};
    for (const key in flatObj) {
      if (catchedObjsKeys.find((x) => x === key)) {
        const newKey = key.replace(`${path.join('.')}.`, '');
        result[newKey] = flatObj[key];
      }
    }
    return result;
  }

  /**
   * Get Visit Count for given moth
   * @param month the month
   * @param year the year
   * @return { number } the visit count
   */
  getMonthsVisitCount (month: number = this.dateHelper.month, year: number = this.dateHelper.year) {
    const monthPath = ['visits', 'history', `${year}`, `${month}`];
    const days = this.getObjectsByPath(monthPath);
    return Object.values(days).reduce((a, b) => a + b, 0);
  }

  /**
   * Get Visitors Count for given moth
   * @param month the month
   * @param year the year
   * @return the amount of visitors in the given month
   */
  getMonthsVisitorsCount (month: number = this.dateHelper.month, year: number = this.dateHelper.year) {
    const monthPath = ['visitors', 'history', `${year}`, `${month}`];
    const days = this.getObjectsByPath(monthPath);
    return Object.values(days).reduce((a, b) => a + b, 0);
  }

  /**
   * Retrieve aggregated vistors count for given year
   * @param year the year you want to get the visitors of
   */
  getYearsVisitorCount (year: number) {
    const months = this.objectPath.get(['visitors', 'history', `${year}`]);
    return Object.keys(months || {})
      .map(monthNumber => this.getMonthsVisitorsCount(parseInt(monthNumber), year))
      .reduce((a, b) => a + b, 0);
  }

  /**
   * Retrieve aggregated visits count for given year
   * @param year the year you want to get the visitors of
   */
  getYearsVisitsCount (year: number) {
    const months = this.objectPath.get(['visits', 'history', `${year}`]);
    return Object.keys(months)
      .map(monthNumber => this.getMonthsVisitCount(parseInt(monthNumber), year))
      .reduce((a, b) => a + b, 0);
  }

  /**
   * Get History entry by date String
   * @param date string the date in format Y.m.d.H.i.s i.e 2020.12.24.13.12.54
   * @param defaultValue the value that shall be returned when nothing is set yet
   * @example this.getHistoryOfDate('2020.12.24, 0) // returns the value from this.data.visitors.history.2020.12.24.
   * Null when not set
   */
  getVisitorsHistoryOfDate (date: string, defaultValue = 0) {
    return Object.values(this.getObjectsByPath(this.getVisitorsHistoryPath(date))).reduce((a, b) => a + b, defaultValue);
  }

  /**
   * Get Path to history entry for visitors by dateString
   * @param date the date in formatY.m.d.H.i.s i.e 2020.12.24.13.12.54
   * @return { string[] } the complete path i.E visitors.history.2020.12.24.13.12.54
   */
  getVisitorsHistoryPath (date: string) {
    return `visitors.history.${date}`.split('.');
  }

  /**
   * Retrieve visits history for given day.
   * @param date the date in format Y.m.d.H.i.s i.e 2020.12.24.13.12.54
   * @param defaultValue
   */
  getVisitsHistoryOfDate (date: string, defaultValue = 0) {
    return Object.values(this.getObjectsByPath(this.getVisitsHistoryPath(date))).reduce((a, b) => a + b, defaultValue);
  }

  /**
   * Get Path to visits history item by dateString
   * @param date the date in format Y.m.d.H.i.s i.e 2020.12.24.13.12.54
   * @return { string[] } the complete path i.E visits.history.2020.12.24.13.12.54
   */
  getVisitsHistoryPath (date: string) {
    return `visits.history.${date}`.split('.');
  }

  /**
   * Stores stats Object in class variable for convienient access
   * @param stats The Stats we want to wrap
   */
  wrap (stats?: IVenueStats) {
    this.data = stats || this.data;
    return this;
  }

  /**
   * Extracts data
   * @return { IVenueStats } the data
   */
  unwrap () {
    return this.data;
  }

  flattenObj (obj: { [key: string]: any }, parent: string = undefined, res: { [key: string]: any } = {}) {
    if (!obj) {
      return {};
    }
    for (const key in obj) {
      const propName = parent ? parent + '.' + key : key;
      if (typeof obj[key] === 'object') {
        this.flattenObj(obj[key], propName, res);
      } else {
        res[propName] = obj[key];
      }
    }
    return res;
  }
}
