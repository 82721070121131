import VueRouter, { RouteConfig, RouterOptions } from 'vue-router';

/**
 * Something changed between TypeScript 3.9 and 4.3. In L#15 we define VueRouterEx.matcher
 * which in TS 4.3 overwrites the matcher property in the base class. This used to work
 * just fine in TS 3.9 (heh, this rhymes!).
 * This unknown change (a quick research didn't yield any breaking changes) is the reason
 * for the @ts-ignore rules whenever this.matcher is used in this file.
 *
 * @see https://github.com/vuejs/vue-router/issues/2844
 */
// type Matcher = {
//   match: (raw: RawLocation, current?: Route, redirectedFrom?: Location) => Route;
//   addRoute: (parentNameOrRoute: string | RouteConfig, route?: RouteConfig) => void;
//   addRoutes: (routes: Array<RouteConfig>) => void;
//   getRoutes: () => Array<RouteRecord>;
// };

// https://github.com/vuejs/vue-router/issues/2280
export class VueRouterEx extends VueRouter {
  // matcher: Matcher;

  public routes: RouteConfig[] = [];

  constructor (options?: RouterOptions) {
    super(options);
    
    const findRouteRecursive = (routeName: string, routes: RouteConfig[]) => {
      let result = this.routes.find(x => x.name === routeName);
      if (result) {
        return result;
      }
      routes.filter(x => x.children && x.children.length > 0)
      .forEach(x => {
        const matchedChildRoute = x.children.find(child => child.name === routeName);
        if (matchedChildRoute) {
          result = matchedChildRoute;
          return;
        }
      });
      return result;
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (this.matcher) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { addRoutes, addRoute } = this.matcher;
      const { routes } = options;

      this.routes = routes;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.matcher.addRoutes = (routesParam: Array<RouteConfig>) => {
        this.routes.push(...routesParam);
        addRoutes(routesParam);
      };

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.matcher.addRoute = (parentOrRoutes: string | RouteConfig, route?: RouteConfig) => {
        if (typeof parentOrRoutes === 'string') {
          const parentRoute = findRouteRecursive(parentOrRoutes, this.routes);
          if ( !parentRoute ) {
            return;
          }
          parentRoute.children.push(route);
        } else {
          this.routes.push(route);
        }
        addRoute(parentOrRoutes, route);
      };
    }
  }
}
