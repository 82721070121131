



































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import VenuesArea from '@/components/venues/venues-area.vue';
import { IVenue, IArea } from '@einfachgast/shared';
import { ValidationError } from 'class-validator';

@Component({
  name: 'VenueAreasStep',
  components: {
    VenuesArea,
  },
})
export default class VenueAreasStep extends Vue {
  @Prop()
  venue: IVenue;

  @Prop({ default: (): ValidationError[] => [] })
  errors: ValidationError[];

  areaSaved (area: IArea) {
    this.$emit('areaSaved', area);
  }
}
