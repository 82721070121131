// mixins.js
import { validate, ValidationError } from 'class-validator';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

// You can declare mixins as the same style as components.
@Component
export class FormFunctions extends Vue {
  @Prop({ default: (): ValidationError[] => [] })
  errors: ValidationError[];

  internalErrors: ValidationError[] = [];

  get allErrors () {
    return this.internalErrors.concat(this.errors);
  }

  get hasErrors () {
    return this.allErrors
      && this.allErrors.length > 0;
  }

  async validate (modelToValidate: unknown, groups?: string[]) {
    this.internalErrors = await validate(modelToValidate as Record<string, unknown>, { groups });
    return this.internalErrors.length <= 0;
  }

  /**
   * Retrieve field internalErrors state by name
   * @param {string} fieldName the name of the field
   * @return {string} the bulma name for the current state
   */
  getFieldColor (fieldName: string): string {
    const fieldErrors = this.getFieldErrorsAsString(fieldName);
    if (fieldErrors && fieldErrors) {
      return 'is-danger';
    }
    return '';
  }

  /**
   * Finds, and Merges all error Messages Regarding the given Fieldname
   * @param {string} fieldName the name of the field
   * @return {string} the messages
   */
  getFieldErrorsAsString (fieldName: string): string {
    const fieldErrors = this.allErrors.filter(error => error.property === fieldName);
    if (!fieldErrors.length) {
      return;
    }
    // Make sure error messages always have dot at the end
    return `${fieldErrors.map(e => Object.values(e.constraints || {}).map(x => x.replace(/[,.!]+$/, '')).join('. '))}.`;
  }

  /**
   * You may register this function with the @blur event handler to prefix
   * the input value with `https://` for secure urls
   */
  prefixValueWithHttps (event: Event) {
    const target = (event.target as HTMLInputElement);
    let val = target.value;
    const r = /^https:\/\//gi;
    if (!r.test(val)) {
      const domain = val.split('://').pop();
      val = ['https', domain].join('://');
    }
    const url = new URL(val);
    target.value = url.href;
  }

  /**
   * Remove all entries from internalErrors array, that are matching the given field name
   * @param {string} fieldName the name of the field we want to clear internalErrors of
   */
  clearErrors (fieldName: string) {
    const internalIndex = this.internalErrors.findIndex(x => x.property === fieldName);
    if (internalIndex !== -1) {
      this.internalErrors.splice(internalIndex, 1);
    }

    const index = this.errors.findIndex(x => x.property === fieldName);
    if (index === -1) {
      return;
    }
    this.errors.splice(index, 1);
  }

  addError (message: string, target: string) {
    const error = new ValidationError();
    error.property = target;
    error.constraints = { custom: message };
    this.internalErrors.push(error);
  }
}
