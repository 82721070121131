































import { Component } from 'vue-property-decorator';
import ChildUserCreateForm from '@/components/child-users/child-user-create-form.vue';
import { mixins } from 'vue-class-component';
import { FormFunctions } from '@/mixins/form-functions';
import { RevertUserAccount } from '@/mixins/revert-user-account';
import ChildUsersList from '@/components/child-users/child-users-list.vue';

@Component({
  name: 'ChildUsers',
  components: {
    ChildUserCreateForm,
    ChildUsersList,
  },
})
export default class ChildUsers extends mixins(FormFunctions, RevertUserAccount) {
  showModal = false;

  addUser () {
    this.showModal = true;
  }
}
