import Component from 'vue-class-component';
import Vue from 'vue';
import { RouteConfig } from 'vue-router';
import { Prop } from 'vue-property-decorator';
import Subscription from '@/models/stripe/subscription';

@Component({
  name: 'MenuRendererAbstract',
})
export default class MenuRendererAbstract extends Vue {
  @Prop()
  isMobile: boolean;

  @Prop()
  route: RouteConfig;

  @Prop()
  subscription: Subscription;

  isActive (route: RouteConfig) {
    return this.$route.path === route.path;
  }
}
