
































import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { RouteConfig } from 'vue-router';
import { extractMenuItems } from '@/helpers/menu-helper';
import { RouteNames } from '@/router';

@Component({
  name: 'MenuItem',
})
export default class MenuItem extends Vue {
  @Prop()
  route: RouteConfig;

  get isActive () {
    return this.$route.matched.some(({ name }) => name === this.route.name);
  }

  get managementRouteName () {
    return RouteNames.ManagementMain;
  }

  get hasChildRoutes () {
    return this.childRoutes.length > 0;
  }

  get childRoutes () {
    if (!this.route.children || this.route.children.length <= 0) {
      return [];
    }
    return extractMenuItems(this.route.children);
  }

  get isDisabled () {
    if (!this.route.meta?.needsSubscription) {
      return false;
    }
    return !this.$stripe.hasSubscription;
  }
}
