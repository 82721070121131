export default {
  step: 'Step',
  myData: 'My Data',
  registration: 'REGISTRATION',
  howItWorks: 'How it works',
  scanQrCode: 'Scan the QR code',
  enterContactDetails: 'Enter contact details',
  ready: 'Ready',
  browser: 'Browser',
  encryptedDataTransmissionInfo: 'Encrypted data transmission &amp; storage<br/>DSGVO-compliant, server in Germany',
  qrCodeIsNoIntendedForCustomers: 'This QR code is <strong>not</strong> intended for your customers.<br/>When a customer does not have a QR code capable device, an employee may check-in the customer with the help of this QR code.',
  deLang: 'Deutsch',
  enLang: 'English',
  esLang: 'Español',
  ruLang: 'русский',
  frLang: 'Français',
  trLang: 'Türk',
  venueEditTabAddress: 'Address',
  'VenueEditTabDesign.Settings': 'Mode',
  'VenueEditTabDesign.Privacy': 'Datasecurity',
  'VenueEditTabDesign.Address': 'Address',
  'additionalConfirmation': 'Additional Confirmation',
  'additionalConfirmation.enabledLabel': 'Should the visitor additionally confirm the check-in?',
  'additionalConfirmation.settings': 'Settings',
  'additionalConfirmation.disable': 'enabled',
  'additionalConfirmation.enable': 'disabeld',
  'additionalConfirmation.whatMethodUseConfirmCheckIn': 'What method should the visitor use to confirm check-in?',
  signaturePad: 'Signature Pad',
  checkbox: 'Checkbox',
  'additionalConfirmation.checkboxLcheckinConfirmationSettingsabel': 'Label for Checkbox',
  'additionalConfirmation.signaturePadLabel': 'Label for Signature Pad',
  venueEditTabDesign: 'Design',
  venueEditTabAdditionalFeatures: 'Documents & Links',
  venueEditTabQrCode: 'QR-Code',
  venueEditTabOnlineForm: 'Online-Form',
  venueEditTabOptions: 'Options',
  venueEditTabAreas: 'Areas',
  venueEditTabDataSecurity: 'Datasecurity',
  venueCreateTabAddress: 'Address',
  venueCreateTabStart: 'Start',
  venueCreateTabDataSecurity: 'Datasecurity',
};
