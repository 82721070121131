








































































import Vue from 'vue';
import { isEmpty } from 'lodash';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'TranslateableInput',
})
export default class TranslateableInput extends Vue {
  selectedLang: string = null;

  @Prop()
  message: string;

  @Prop()
  type: string;

  @Prop({ default: 5 })
  maxRows: number;

  @Prop({ default: 300 })
  maxLength: number;

  @Prop()
  value: {[key: string]: string};

  @Prop()
  placeholder: string;

  @Prop({ default: false})
  required: boolean;

  get availableLanguages () {
    return this.$auth.venueOwner
      ?.settings
      ?.activeLanguages
      ?.map(langKey => ({
        key: langKey,
        label: this.$t(`${langKey}Lang`).toString() + ((this.defaultLanguage === langKey)
          ? ' (Standard)'
          : ''),
      })) || [];
  }

  get defaultLanguage () {
    return this.$auth.venueOwner?.settings?.defaultLanguage;
  }

  created () {
    this.selectedLang = this.defaultLanguage;
  }

  hasError (langKey: string) {
    return isEmpty(this.value[langKey]) && this.type === 'is-danger' && this.required;
  }
}
