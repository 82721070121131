import { Roles } from '@/models/venues/roles';
import { store } from '@/store';
import { AuthModule } from '@/store/modules/auth';
import { RouteConfig } from 'vue-router';
import { getModule } from 'vuex-module-decorators';
const authStore = getModule(AuthModule, store);

const extractMenuItems = (rawRoutes: RouteConfig[] = []) => {
  return rawRoutes
    .filter(x => x.meta && (!!x.meta.menuTitle || !!x.meta.menuItemRenderer))
    .filter(x => !x.meta.roles || (x.meta.roles as Array<Roles>).some(r => authStore.permissions.has(r)))
    .filter(x => (x.meta.hasPermission && typeof x.meta.hasPermission === 'function')
      ? x.meta.hasPermission()
      : true,
    )
    .filter(x => {
      if (x.meta.canShow) {
        return x.meta.canShow();
      }
      return true;
    })
    .sort((a, b) => a.meta?.menuSortOrder - b.meta?.menuSortOrder);
};

export { extractMenuItems };