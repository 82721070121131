









import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'StripeRewrite',
})
export default class StripeRewrite extends Vue {
  loading = true;

  async mounted () {
    try {
      const stripeSessionUrl = await this.$stripe.startStripeSession({ returnUrl: window.location.origin });
      window.location.assign(stripeSessionUrl);
    } catch (e) {
      await this.$router.push({ path: '*' });
    }
  }
}
