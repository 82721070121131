










import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { FirebaseFunctions, firebaseWrapper as fb } from '@/firebase-wrapper';
import { httpsCallable } from 'firebase/functions';

@Component({
  name: 'LiveVisitorAreaItemCheckoutBtn',
})
export default class LiveVisitorAreaItemCheckoutBtn extends Vue {
  checkoutLoading = false;

  @Prop()
  id: string;

  @Prop()
  name: string;

  async checkoutVisitor () {
    // checkout
    // here execute function to checkout a pendingVisit
    try {
      this.checkoutLoading = true;
      const checkout = httpsCallable(fb.functions, FirebaseFunctions.CheckoutAdmin);
      await checkout([this.id]);
      this.checkoutLoading = false;
    } catch {
      this.checkoutLoading = false;
      this.$buefy.toast.open({
        duration: 5000,
        message: `Fehler beim auschecken von: ${this.name}!`,
        position: 'is-top',
        type: 'is-danger',
      });
    }
  }
}
