import { Action, config, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import firebase from 'firebase/app';
import { IVenue, IPendingVisit, IVenueOwner } from '@einfachgast/shared';
import { ILoginData, IUpdateUnverifiedEmailRequest } from '@/interfaces';
import { VenueOwner } from '@/models/venue-owner';
import { DBCollections, FirebaseFunctions, firebaseWrapper as fb } from '@/firebase-wrapper';
import { Venue } from '@/models/venues/venue';
import { isEmail, isEmpty } from 'class-validator';
import { PermissionResolver } from '@/helpers/permission-resolver';
import { IAuthStore } from '../interfaces/i-auth-store';
import { plainToClass } from 'class-transformer';
import { Roles } from '@/models/venues/roles';
import { httpsCallable } from 'firebase/functions';
import { EmailAuthProvider, reauthenticateWithCredential, sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword, User } from 'firebase/auth';
import { collection, doc, getDoc, getDocs, onSnapshot, query, QueryConstraint, where } from 'firebase/firestore';

config.rawError = true;

@Module({ namespaced: true, name: 'auth' })
export class AuthModule extends VuexModule implements IAuthStore {
  private _user: User = null;
  private _permissions = new PermissionResolver();
  private _isVenueOwner: boolean = null;
  private _isAdmin: boolean = null;
  private _isVenueOwnerDeleted: boolean = null;
  private _ownedVenues: IVenue[] = [];
  private _trialExpiryDate = new Date();
  private _isBillingEnabled = false;
  private _venueOwner: IVenueOwner = null;
  private _venueSnapshot: () => void = null;
  private _pendingVisitsSnapshot: () => void = null;
  private _isReady = false;

  get isReady () {
    return this._isReady;
  }

  @Mutation
  setIsReady (state: boolean) {
    this._isReady = state;
  }

  @Mutation
  resetPermissions () {
    this._permissions = new PermissionResolver();
  }

  get user () {
    return this._user;
  }

  get permissions () {
    return this._permissions;
  }

  @Mutation
  setUser (user: User) {
    this._user = user;
  }

  get venueOwner () {
    return this._venueOwner;
  }

  @Mutation
  setVenueOwner (venueOwner: IVenueOwner) {
    this._venueOwner = venueOwner;
  }

  get isVenueOwner () {
    return this._isVenueOwner;
  }

  get isAdmin () {
    return this._isAdmin;
  }

  get isVenueOwnerDeleted () {
    return this._isVenueOwnerDeleted;
  }

  @Mutation
  setIsVenueOwner (isOwner: boolean) {
    this._isVenueOwner = isOwner;
  }

  @Mutation
  setIsAdmin (isAdmin: boolean) {
    this._isAdmin = isAdmin;
  }

  @Mutation
  setIsVenueOwnerDeleted (isOwnerDeleted: boolean) {
    this._isVenueOwnerDeleted = isOwnerDeleted;
  }

  get venueSnapshot () {
    return this._venueSnapshot;
  }

  get pendingVisitsSnapshot () {
    return this._pendingVisitsSnapshot;
  }

  @Mutation
  setVenueSnapshotUnsubscribe (venueSnapshot: () => void) {
    this._venueSnapshot = venueSnapshot;
  }

  @Mutation
  setPendingVisitsSnapshoUnsubscribe (pendingVisistsSnapshot: () => void) {
    this._pendingVisitsSnapshot = pendingVisistsSnapshot;
  }

  get trialExpiryDate () {
    return this._trialExpiryDate;
  }

  @Mutation
  setTrialExpiryDate (date: Date) {
    this._trialExpiryDate = date;
  }

  get isBillingEnabled () {
    return this._isBillingEnabled;
  }

  @Mutation
  setIsBillingEnabled (enabled: boolean) {
    this._isBillingEnabled = enabled;
  }

  @Mutation
  updateVenueInOwnedVenues (venue: IVenue) {
    const updateVenueIndex = this._ownedVenues.findIndex(v => v.id === venue.id);
    if (updateVenueIndex !== -1) {
      const originalPendingVisists = this._ownedVenues[updateVenueIndex].pendingVisits;
      venue.pendingVisits = originalPendingVisists;
      this._ownedVenues.splice(updateVenueIndex, 1, venue);
    }
  }

  get ownedVenues () {
    return this._ownedVenues;
  }

  @Mutation
  setOwnedVenues (venues: IVenue[]) {
    this._ownedVenues = venues;
  }

  get isRegistrationComplete () {
    return !!this.user
      && this.user.emailVerified;
  }

  @Action
  async initApp () {
    const user = fb.auth.currentUser;
    this.context.commit('setUser', user);
    await this.context.dispatch('parseClaims', { user, forceRefresh: true });
    if (this.permissions.has(Roles.ChildUser)) {
      this.context.commit('setVenueOwner', null);
    } else {
      await this.context.dispatch('initVenueOwner', user);
    }
    await this.initOwnerVenues();
    this.context.commit('setIsReady', true);
  }

  @Action
  async reInitApp () {
    await this.parseClaims({ user: fb.auth.currentUser, forceRefresh: true });
    this.context.commit('setIsReady', true);
  }

  @Action
  async initVenueOwner () {
    if (!this.user) {
      this.context.commit('setVenueOwner', null);
      return;
    }
    try {
      const docref = doc(collection(fb.db, DBCollections.VenueOwners), this._user.uid);
      const result = await getDoc(docref);
      const owner = plainToClass<VenueOwner, IVenueOwner>(VenueOwner, result.data() as IVenueOwner);
      this.context.commit('setVenueOwner', owner);
    } catch {
      throw Error('Fetching user profile went wrong!');
    }
  }

  @Action
  async initOwnerVenues () {
    if (!this.user) {
      return;
    }
    try {
      const ownerId = this.permissions.has(Roles.ChildUser) ? this.permissions.parentUserId : this.user.uid;
      const result = await getDocs(query(collection(fb.db, DBCollections.Venues), where('ownerId', '==', ownerId)));
      const venues: IVenue[] = [];
      for (const venueDoc of result.docs) {
        const venueData = venueDoc.data() as IVenue;
        venueData.id = venueDoc.id;
        venues.push(plainToClass(Venue, venueData));
      }
      const pendingVisitsCollection = collection(fb.db, DBCollections.PendingVisits);
      const pendingVisitOwnerConstraint: QueryConstraint = where('ownerId', '==', ownerId);
      // init pendingVisists to venue
      for (const venue of venues) {
        const venuesPendingVisits = await getDocs(query(pendingVisitsCollection, pendingVisitOwnerConstraint, where('venueId', '==', venue.id)));
        venue.pendingVisits = venuesPendingVisits.docs.map(x => {
          return { id: x.id, ...x.data() };
        }) as IPendingVisit[];
      }

      this.unsubscribeVenueSnapshot();
      // add venueSnapshot to update visits and all venue things on-the-fly
      const q = query(collection(fb.db, DBCollections.Venues), where('ownerId', '==', ownerId));
      const snapUnsubscribe = onSnapshot(q, (snap) => {
        for (const venue of snap.docChanges()) {
          const venueData = venue.doc.data() as IVenue;
          venueData.id = venue.doc.id;
          const venueInstance = plainToClass<Venue, IVenue>(Venue, venueData);
          this.updateVenueInOwnedVenues(venueInstance);
        }
      });

      this.setVenueSnapshotUnsubscribe(snapUnsubscribe);

      const pendingVisistQuery = query(pendingVisitsCollection, pendingVisitOwnerConstraint);
      const pendingVisistsSnapShotUnsubscribe = onSnapshot(pendingVisistQuery, (snap) => {

        for (const pendingVisitChange of snap.docChanges()) {
          const pendingVisit = pendingVisitChange.doc.data() as IPendingVisit;
          pendingVisit.id = pendingVisitChange.doc.id;
          const catchedVenue = this.ownedVenues
            .find(x => x.id === pendingVisit.venueId);
          const catchedPendingVisitsIndex = catchedVenue?.pendingVisits
            .findIndex(x => x.visitId === pendingVisit.visitId);
          if (pendingVisitChange.newIndex === -1) {
            // remove pending visit
            catchedVenue?.pendingVisits.splice(catchedPendingVisitsIndex, 1);
          } else if (catchedPendingVisitsIndex >= 0) {
            // already exists in pendingVisits
            catchedVenue?.pendingVisits.splice(catchedPendingVisitsIndex, 1, pendingVisit);
          } else {
            // a new pendingVisit
            catchedVenue?.pendingVisits.unshift(pendingVisit);
          }
        }
      });
      this.setPendingVisitsSnapshoUnsubscribe(pendingVisistsSnapShotUnsubscribe);

      this.context.commit('setOwnedVenues', venues);
    } catch (e) {
      console.log(e);
      throw Error('Fetching user venues went wrong!ss');
    }
  }

  @Action
  unsubscribeVenueSnapshot () {
    this._venueSnapshot && this._venueSnapshot();
    this._pendingVisitsSnapshot && this._pendingVisitsSnapshot();
  }

  // actions
  @Action
  async login (loginData: ILoginData) {
    const { user } = await signInWithEmailAndPassword(fb.auth, loginData.email, loginData.password);
    await this.context.dispatch('parseClaims', { user, forceRefresh: true });
    // store user object
    this.context.commit('setUser', user);
  }

  @Action
  async parseClaims (payload?: { user: User; forceRefresh?: boolean }) {
    this.context.commit('resetPermissions');
    // Check claims
    const token = await payload?.user?.getIdTokenResult(payload?.forceRefresh);
    this.context.commit('setIsVenueOwner', token?.claims.isVenueOwner);
    this._permissions.setRolesByTokenClaims(token?.claims);
    this.context.commit('setIsVenueOwnerDeleted', token?.claims.isDeleted);
  }

  @Action
  async signup (registrationData: ILoginData) {
    // sign user up
    const userRegistration = httpsCallable(fb.functions, FirebaseFunctions.RegisterVenueOwner);
    await userRegistration(registrationData);

    // log user in
    await signInWithEmailAndPassword(fb.auth, registrationData.email, registrationData.password);
    const user = fb.auth.currentUser;
    await this.context.dispatch('parseClaims', { user });

    // send verification mail to user
    const parsedUrl = new URL(window.location.href);
    await sendEmailVerification(user, { url: `${parsedUrl.protocol}//${parsedUrl.host}` });

    this.context.commit('setUser', user);
  }

  @Action
  async logout () {
    await fb.auth.signOut();
    await this.context.dispatch('parseClaims', null);
    this.context.commit('setOwnedVenues', []);
  }

  @Action
  async updateUnverifiedUsersMailaddress (updateEmailRequest: IUpdateUnverifiedEmailRequest) {
    if (!this.user) {
      throw new Error('Sie haben nicht die nötigen Rechte.');
    }

    if (this.user.emailVerified) {
      throw new Error('Bereits verifizierte E-Mail-Adressen können nicht geändert werden.');
    }

    if (!isEmail(updateEmailRequest.email)) {
      throw new Error('Ungültige E-Mail-Adresse.');
    }

    if (isEmpty(updateEmailRequest.password)) {
      throw new Error('Zum aktualisieren der E-Mail wird das Passwort benötigt.');
    }

    // I need to re-Login the user with his old email to make sure the entered cretdentials are correct before updating the email
    await this.login({ password: updateEmailRequest.password, email: this.user.email });

    // Updating the email address
    const updateFunction = httpsCallable(fb.functions, FirebaseFunctions.UpdateUnverifiedEmail);
    await updateFunction(updateEmailRequest.email);

    // updating email automatically logs out the user. So i need to re-login again...
    await this.login(updateEmailRequest);

    // send verification mail to user
    const parsedUrl = new URL(window.location.href);
    return sendEmailVerification(this.user, { url: `${parsedUrl.protocol}//${parsedUrl.host}` });
  }

  @Action
  async updateVenueOwner (data: IVenueOwner) {
    const updateUserAccount = httpsCallable(fb.functions, FirebaseFunctions.UpdateUserAccount);
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const {
      id,
      remainingVisits,
      stripeId,
      stripeLink,
      subscriptionStartDate,
      spamCheckResult,
      trialEnd,
      createdAt,
      deletedAt,
      lastSeenAt,
      isDeleted,
      ...venueOwnerData
    } = data;
    /* eslint-enable @typescript-eslint/no-unused-vars */

    await updateUserAccount(venueOwnerData);
    const docref = doc(fb.usersCollection, this._user.uid);
    const result = await getDoc(docref);
    const owner = plainToClass(VenueOwner, result.data() as IVenueOwner);
    this.context.commit('setVenueOwner', owner);
  }

  @Action
  async disableVenueOwner () {
    const disableUserAccount = httpsCallable(fb.functions, FirebaseFunctions.DisableUserAccount);
    await disableUserAccount(this._user.uid);
  }

  @Action
  async enableVenueOwner () {
    const enableUserAccount = httpsCallable(fb.functions, FirebaseFunctions.EnableUserAccount);
    await enableUserAccount(this._user.uid);
  }

  @Action
  async sendResetPasswordMail (email: string) {
    return await sendPasswordResetEmail(fb.auth, email);
  }

  @Action
  async reauthenticateUser (currentPassword: string) {
    // related to https://medium.com/@ericmorgan1/change-user-email-password-in-firebase-and-react-native-d0abc8d21618
    const user =  fb.auth.currentUser;
    const cred = EmailAuthProvider.credential(user.email, currentPassword);
    return await reauthenticateWithCredential(user, cred);
  }
}
