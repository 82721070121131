



















import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { FormFunctions } from '@/mixins/form-functions';
import { IVenue } from '@einfachgast/shared';
import VenueEdit from '@/components/venues/venue-edit.vue';
import VenueListItem from '@/components/venues/venue-list-item.vue';
import { RouteNames } from '@/router';

@Component({
  name: 'Venues',
  components: {
    VenueEdit,
    VenueListItem,
  },
})
export default class Venues extends mixins(FormFunctions) {
  venues: IVenue[] = [];

  get isOwnerDeleted () {
    return this.$auth.isVenueOwnerDeleted;
  }

  get activeVenuesSorted () {
    return this.venues
      .filter(x => x.isDeleted === false && x.isDeactivated === false)
      .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);
  }

  get deletedVenuesSorted () {
    return this.venues
      .filter(x => x.isDeleted === true || x.isDeactivated === true)
      .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);
  }

  get canAddNewVenue () {
    if (this.isOwnerDeleted || this.$stripe.packageScope?.maxVenueCount === 0) {
      return false;
    }
    if (this.$stripe.packageScope?.maxVenueCount === -1) {
      return true;
    }

    return this.availableVenues.length < this.$stripe.packageScope?.maxVenueCount;
  }

  get availableVenues () {
    return this.venues.filter(venue => !venue.isDeleted && !venue.isDeactivated);
  }

  created () {
    this.venues = this.$auth.ownedVenues.slice(0);
  }

  async addNewVenue () {
    await this.$router.push({ name: RouteNames.NewVenue });
  }
}
